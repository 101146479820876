import React from 'react';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Tab as TabMUI,
  // eslint-disable-next-line no-restricted-imports
  Tabs as TabsMUI,
  useTheme,
} from '@mui/material';

import { useHasMobileView } from 'hooks/useHasMobileView';

import { CustomString } from 'config/translations';

import { getVerticalStackSx, VerticalPosition } from './VerticalStack';

interface TabItem {
  label: CustomString;
  content: JSX.Element;
}

interface OnPressFilterItem {
  label: CustomString;
  onPress: () => void;
}

interface LinkedFilterItem {
  label: CustomString;
  onPress: (linkedValue: string) => void;
  linkedValue: string;
  fallback: boolean;
}

type TableTabsProps = (
  | {
      variant: 'content';
      data: TabItem[];
      tabIndex: number;
      setTabIndex: (tabIndex: number) => void;
      currentValue?: undefined;
    }
  | {
      variant: 'linkedFilter';
      data: LinkedFilterItem[];
      tabIndex: number;
      setTabIndex: (tabIndex: number) => void;
      currentValue: string;
    }
  | {
      variant: 'onPressFilter';
      data: OnPressFilterItem[];
      tabIndex: number;
      setTabIndex: (tabIndex: number) => void;
      currentValue?: undefined;
    }
) &
  VerticalPosition;

function TableTabs({
  data,
  variant,
  tabIndex,
  setTabIndex,
  currentValue,
  verticalPosition,
}: TableTabsProps) {
  const initialisedBooleanRef = React.useRef(false);

  const { hasMobileView } = useHasMobileView();

  const theme = useTheme();

  React.useEffect(() => {
    if (data.length > 0 && !initialisedBooleanRef.current) {
      initialisedBooleanRef.current = true;
      setTabIndex(tabIndex);

      if (variant === 'linkedFilter' && !!data[tabIndex].linkedValue) {
        data[tabIndex].onPress(data[tabIndex].linkedValue);
      }

      if (variant === 'onPressFilter') {
        data[tabIndex].onPress();
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (tabIndex > data.length - 1) {
      setTabIndex(0);

      if (variant === 'linkedFilter') {
        data[tabIndex].onPress(data[tabIndex].linkedValue);
      }

      if (variant === 'onPressFilter') {
        data[tabIndex].onPress();
      }
    }
  }, [data.length]);

  React.useEffect(() => {
    if (variant === 'linkedFilter') {
      let newTabIndex = -1;
      data.forEach(({ linkedValue }, index) => {
        if (linkedValue === currentValue) {
          newTabIndex = index;
        }
      });

      if (newTabIndex !== -1) {
        setTabIndex(newTabIndex);
        return;
      }

      data.forEach(({ fallback }, index) => {
        if (fallback) {
          newTabIndex = index;
        }
      });

      if (newTabIndex !== -1) {
        setTabIndex(newTabIndex);
        return;
      }
    }
  }, [currentValue]);

  return (
    <Box
      sx={
        variant === 'linkedFilter' || variant === 'onPressFilter'
          ? {
              maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
              padding: 2,
              paddingTop: 1,
              paddingBottom: 0,
              backgroundColor: 'background.paper',
              ...getVerticalStackSx({ theme, verticalPosition }),
            }
          : undefined
      }
    >
      <TabsMUI
        variant="scrollable"
        value={tabIndex}
        onChange={(_, newValue: number) => {
          setTabIndex(newValue);

          if (variant === 'onPressFilter') {
            data[newValue].onPress();
            return;
          }

          if (variant === 'linkedFilter') {
            data[newValue].onPress(data[newValue].linkedValue);
            return;
          }
        }}
      >
        {data.map(({ label }, index) => (
          <TabMUI key={index} label={label} />
        ))}
      </TabsMUI>
      {variant === 'content' && data[tabIndex]?.content && (
        <Box sx={{ paddingTop: 1 }}>{data[tabIndex].content}</Box>
      )}
    </Box>
  );
}

export { TableTabs };
