import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import validator from 'validator';

import { ButtonProps, Menu, SVG, Text } from 'components';
import { useToast } from 'core/toast/hooks';
import { useParams } from 'hooks/useParams';
import { useRoute } from 'hooks/useRoute';
import { useTranslations } from 'hooks/useTranslations';

import { CustomRoutes } from 'config/route';
import { CustomString } from 'config/translations';

import { Button, ButtonActions } from './Button';

type PageProps<Name extends keyof CustomRoutes> = {
  name: Name;
  overwriteTitle?: CustomString;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[];
  footer?: CustomRoutes[Name]['showMenuAndHeader'] extends true ? ButtonProps[] : undefined;
} & ButtonActions;

function Page<Name extends keyof CustomRoutes>({
  name,
  overwriteTitle,
  loading,
  children = <></>,
  footer,
  actions,
}: PageProps<Name>) {
  const { showMenuAndHeader, centerContent } = useRoute(name);
  const { currentToast, removeToast } = useToast();
  const buttonTranslations = useTranslations('button');
  const generalTranslations = useTranslations('general');

  const theme = useTheme();
  const { id } = useParams(name) ?? {};
  const hasValidOrUndefinedId = id === undefined || validator.isUUID(id ?? '') || id === 'new';

  return (
    <>
      {!!showMenuAndHeader && (
        <Menu
          name={name}
          overwriteTitle={overwriteTitle}
          loading={loading}
          fullScreen={!hasValidOrUndefinedId}
          footer={hasValidOrUndefinedId ? footer : undefined}
          actions={hasValidOrUndefinedId ? actions : undefined}
        >
          <>
            {hasValidOrUndefinedId && children}
            {!hasValidOrUndefinedId && (
              <Box
                sx={{
                  flexDirection: 'column',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SVG.ErrorOutline color="primary.main" size="large" />
                <Text.ExtraLarge>{generalTranslations.pageNotFound}</Text.ExtraLarge>
              </Box>
            )}
          </>
        </Menu>
      )}
      {!showMenuAndHeader && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            height: centerContent ? '100%' : undefined,
            display: 'flex',
            justifyContent: 'center',
            alignItems: centerContent ? undefined : 'flex-start',
          }}
        >
          {actions && (
            <Box sx={{ position: 'absolute', right: theme.spacing(3), top: theme.spacing(3) }}>
              <Button size="medium" label={buttonTranslations.actions} actions={actions} />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!!currentToast && (
              <Snackbar
                open={!!currentToast}
                autoHideDuration={currentToast?.duraction ?? 5000}
                onClose={() => {
                  removeToast(currentToast?.hash ?? '');
                }}
              >
                <Alert
                  onClose={() => {
                    removeToast(currentToast?.hash ?? '');
                  }}
                  severity={currentToast?.severity ?? 'success'}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {currentToast?.title ?? ''}
                </Alert>
              </Snackbar>
            )}

            {hasValidOrUndefinedId && children}
            {!hasValidOrUndefinedId && (
              <Box
                sx={{
                  flexDirection: 'column',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SVG.ErrorOutline color="primary.main" size="large" />
                <Text.ExtraLarge>{generalTranslations.pageNotFound}</Text.ExtraLarge>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export { Page, type PageProps };
