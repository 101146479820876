interface ParseCSVReturn<Fields extends string> {
  data: Record<Fields, string>[];
  error: Error | undefined;
}

interface ParseCSVProps<Fields extends string> {
  fields: Fields[];
  file: File;
}

async function parseCSV<Fields extends string>({
  fields,
  file,
}: ParseCSVProps<Fields>): Promise<ParseCSVReturn<Fields>> {
  const data: Record<Fields, string>[] = [];
  let content = '';

  try {
    content = await file.text();
  } catch (error) {
    return Promise.resolve({
      data: [],
      error: error instanceof Error ? error : new Error('error instanceof Error === false'),
    });
  }

  const convertedContent = content.replaceAll('\r', '').split('\n');
  const localFields = convertedContent[0].split(';');

  let hasCorrectFields = true;
  fields.forEach((field) => {
    if (!localFields.includes(field)) {
      hasCorrectFields = false;
    }
  });

  if (!hasCorrectFields) {
    return Promise.resolve({
      data: [],
      error: new Error(
        `Found incorrect fields, got: ${localFields.join(
          ', ',
        )} and requires at least: ${fields.join(', ')}`,
      ),
    });
  }

  for (let i = 1; i < convertedContent.length; i++) {
    const values = convertedContent[i].split(';');

    const entry: Record<Fields, string> = {} as Record<Fields, string>;
    fields.forEach((field, index) => {
      entry[field] = values[index];
    });

    data.push(entry);
  }

  return Promise.resolve({
    data,
    error: undefined,
  });
}

export { parseCSV };
