import React from 'react';

import { DualListEditor, DualListEditorProps, Page } from 'components';
import { useTranslations } from 'hooks/useTranslations';

import { useMetaRules } from 'pages/settings';
// eslint-disable-next-line no-restricted-imports
import { Fuel, Transportation } from 'useQuery/useReservations';

function MetaRulesPage() {
  const translations = useTranslations('metaRulesPage');

  const [baseValues, setBaseValues] = React.useState<DualListEditorProps['values']>([]);
  const [values, setValues] = React.useState<DualListEditorProps['values']>([]);
  const [hasEmptyFields, setHasEmptyFields] = React.useState(false);

  const {
    data: metaRulesData,
    update,
    isFetching: isFetchingMetaRules,
    isUpdating: isUpdatingMetaRules,
  } = useMetaRules();

  React.useEffect(() => {
    if (!metaRulesData) {
      return;
    }

    setBaseValues(
      metaRulesData.map(
        ({ id, description, target, transportation_type, fuel_type, price_per_kilometer }) => ({
          id,
          data: [
            description,
            target,
            transportation_type,
            fuel_type,
            (price_per_kilometer ?? 0) / 100,
          ],
        }),
      ),
    );
    setValues(
      metaRulesData.map(
        ({ id, description, target, transportation_type, fuel_type, price_per_kilometer }) => ({
          id,
          data: [
            description,
            target,
            transportation_type,
            fuel_type,
            (price_per_kilometer ?? 0) / 100,
          ],
        }),
      ),
    );
  }, [metaRulesData]);

  React.useEffect(() => {
    let newHasEmptyFields = false;
    values.forEach(({ data: [a, b] }) => {
      if (!a || !b) {
        newHasEmptyFields = true;
      }
    });

    setHasEmptyFields(newHasEmptyFields);
  }, [values]);

  return (
    <Page
      name="metaRules"
      footer={[
        {
          label: translations.restore,
          variant: 'outlined',
          onClick: () => {
            setValues(JSON.parse(JSON.stringify(baseValues)));
          },
          disabled: JSON.stringify(baseValues) === JSON.stringify(values),
        },
        {
          label: translations.update,
          onClick: () => {
            update({
              metaRules: values.map(({ id, data }) => ({
                id,
                description: data[0],
                target: data[1],
                transportation_type: (data[2] as keyof typeof Transportation) ?? undefined,
                fuel_type: (data[3] as keyof typeof Fuel) ?? undefined,
                price_per_kilometer: data[4] ? Number(data[4] * 100) : undefined,
              })),
            });
          },
          disabled: JSON.stringify(baseValues) === JSON.stringify(values) || hasEmptyFields,
          loading: isUpdatingMetaRules,
        },
      ]}
      loading={isFetchingMetaRules}
    >
      <DualListEditor
        title={translations.metaRules}
        columnNames={[
          translations.description,
          translations.target,
          translations.transportationType,
          translations.fuelType,
          translations.pricePerKilometer,
        ]}
        values={values}
        onChange={setValues}
      />
    </Page>
  );
}

export { MetaRulesPage };
