// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface User {
  full_name: string;
  email: string;
  organisation?: string;
  id: string;
}

interface Addon {
  type: string;
  label: string;
  class: string;
  price_low: number;
  price_high: number;
}

interface Product {
  type: string;
  label: string;
  class: string;
  price_low: number;
  price_high: number;
  addons: Addon[];
}

interface BusinessCard {
  id: string;
  status: string;
  reference: string;
  contract_start: string;
  contract_end?: string;
  engraved_card_id?: string;
  class: string;
  product: Product;
  user: User;
}

type BusinessCardStatus =
  | 'REQUESTED'
  | 'PENDING'
  | 'ACCEPTED'
  | 'ACTIVE'
  | 'EXPIRED'
  | 'ENDED'
  | 'CANCELLED';

interface UseBusinessCardsProps {
  query?: string;
  page: number;
  limit?: number;
}

function useBusinessCards({ page, limit, query }: UseBusinessCardsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<BusinessCard>[];
    meta: Meta;
  }

  interface Params {
    query?: string;
    page: number;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/admin/business-card',
      token,
      params: {
        query,
        page,
        limit: hasMobileView && limit === undefined ? 15 : limit,
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['businessCards', { page, hasMobileView, limit, query }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useBulkBusinessCardsUpload() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface BulkBusinessCardsUploadProps {
    file: File;
  }

  interface Body {
    file: File;
  }

  async function bulkBusinessCardsUpload({ file }: BulkBusinessCardsUploadProps) {
    const { data } = await API.post<void, Body>({
      path: '/admin/business-card/import',
      token,
      version: 1,
      body: { file },
      fileObject: file,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, BulkBusinessCardsUploadProps>(
    (data: BulkBusinessCardsUploadProps) => bulkBusinessCardsUpload(data),
  );
}

export { type BusinessCardStatus, useBulkBusinessCardsUpload, useBusinessCards };
