import React from 'react';
import dayjs from 'dayjs';

import { ButtonProps, Dialog, InformationBlock, Page, Text } from 'components';
import { formatPrice } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'hooks/useParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';
import { Voucher } from 'useQuery/types';

function VoucherPage() {
  const [validFrom, setValidFrom] = React.useState<string>(dayjs().toISOString());
  const [validTo, setValidTo] = React.useState<string>('');
  const [codePhrase, setCodePhrase] = React.useState<string>('');
  const [useLimit, setUseLimit] = React.useState<number>(NaN);
  const [type, setType] = React.useState<Voucher['type']>();
  const [timesRedeemed, setTimesRedeemed] = React.useState<Voucher['times_redeemed']>(0);
  const [value, setValue] = React.useState<Voucher['value']>(NaN);

  const { addToast } = useToast();

  const { id } = useParams('voucher');
  const translations = useTranslations('voucherPage');
  const { navigate } = useNavigate();

  const [open, setOpen] = React.useState(false);

  const { mutateAsync: mutateAsyncAddVoucher, isLoading: isLoadingAddVoucher } =
    UseMutation.addVoucher();

  const { mutateAsync: mutateAsyncEditVoucher, isLoading: isLoadingEditVoucher } =
    UseMutation.editVoucher();

  const { data: voucherData } = UseQuery.voucher({ id });

  React.useEffect(() => {
    const result = voucherData?.result;

    if (!result) {
      return;
    }

    const { valid_from, valid_to, code_phrase, use_limit, type, value, times_redeemed } = result;

    setValidFrom(dayjs(valid_from).isValid() ? dayjs(valid_from).toISOString() : '');
    setValidTo(dayjs(valid_to).isValid() ? dayjs(valid_to).toISOString() : '');
    setCodePhrase(code_phrase);
    setUseLimit(use_limit ?? 0);
    setType(type);
    setValue(value);
    setTimesRedeemed(times_redeemed);
  }, [voucherData]);

  async function addVoucher() {
    if (!type) {
      return;
    }

    try {
      await mutateAsyncAddVoucher({ validFrom, validTo, codePhrase, useLimit, type, value });

      navigate({ name: 'vouchers', id: undefined, searchParams: { page: 1 }, metaKey: false });
      addToast({ title: translations.successfullyAdded, severity: 'success' });

      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  }

  async function editVoucher() {
    if (!type) {
      return;
    }

    try {
      await mutateAsyncEditVoucher({ id, validFrom, validTo, codePhrase, useLimit, type, value });

      navigate({ name: 'vouchers', id: undefined, searchParams: { page: 1 }, metaKey: false });
      addToast({ title: translations.successfullyEdited, severity: 'success' });

      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  }

  const hasNewValidTo =
    (dayjs(voucherData?.result.valid_to).isValid() &&
      dayjs(voucherData?.result.valid_to).toISOString() !== validTo) ||
    (!dayjs(voucherData?.result.valid_to).isValid() && validTo);

  const hasNewType = voucherData?.result.type !== type && type !== undefined;

  const footer: ButtonProps[] = [
    {
      label: id === 'new' ? translations.cancel : translations.restore,
      variant: 'outlined',
      onClick: () => {
        if (id === 'new') {
          navigate({ name: 'vouchers', id: undefined, searchParams: { page: 1 }, metaKey: false });
        } else {
          setValidTo(
            dayjs(voucherData?.result.valid_to).isValid()
              ? dayjs(voucherData?.result.valid_to).toISOString()
              : '',
          );
          setType(voucherData?.result.type ?? 'PERCENTAGE');
        }
      },
      disabled: isLoadingAddVoucher || (!hasNewValidTo && !hasNewType),
    },
    {
      label: id === 'new' ? translations.save : translations.update,
      onClick: () => {
        if (type === 'DISCOUNT' && id === 'new') {
          setOpen(true);
        } else {
          id === 'new' ? addVoucher() : editVoucher();
        }
      },
      loading: isLoadingAddVoucher || isLoadingEditVoucher,
      disabled:
        (id === 'new' &&
          (!validFrom || !codePhrase || !useLimit || !value || !type || isLoadingAddVoucher)) ||
        (id !== 'new' && isLoadingEditVoucher) ||
        (!hasNewValidTo && !hasNewType),
    },
  ];

  return (
    <Page name="voucher" loading={!codePhrase && id !== 'new'} footer={footer}>
      <>
        <Dialog
          title={translations.dialogTitle}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpen(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                id === 'new' ? await addVoucher() : await editVoucher();
              },
              loading: isLoadingAddVoucher || isLoadingEditVoucher,
            },
          ]}
          open={open}
          onChange={setOpen}
        >
          <Text>
            {replaceText({
              text: translations.dialogMessage,
              replaceValues: [formatPrice({ price: value, symbol: true })],
            })}
          </Text>
        </Dialog>
        <InformationBlock<Voucher['type']>
          items={[
            {
              label: translations.codePhrase,
              data: {
                value: codePhrase,
                onChange: setCodePhrase,
                placeholder: '',
                onEnter: () => {},
                disabled: id !== 'new',
                required: true,
              },
              type: 'editText',
            },
            {
              label: translations.maximumRides,
              data: {
                value: useLimit,
                onChange: setUseLimit,
                placeholder: 0,
                onEnter: () => {},
                disabled: id !== 'new',
                required: true,
              },
              type: 'editNumber',
            },
            {
              label:
                type === 'DISCOUNT'
                  ? translations.discountCents
                  : type === 'PERCENTAGE'
                  ? translations.percentage
                  : translations.value,
              data: {
                value: value,
                onChange: setValue,
                placeholder: 0,
                onEnter: () => {},
                disabled: id !== 'new' || type === undefined,
                required: true,
              },
              type: 'editNumber',
            },
            {
              label: translations.validFrom,
              data: {
                value: validFrom,
                onChange: setValidFrom,
                onEnter: () => {},

                disabled: id !== 'new',
                required: true,
              },
              type: 'editDateTime',
            },

            {
              label: translations.validTo,
              data: {
                value: validTo,
                onChange: setValidTo,
                onEnter: () => {},
                required: false,
              },
              type: 'editDateTime',
            },
            {
              label: translations.type,
              data: {
                mode: 'single',
                label: translations.type,
                value: type,
                options: [
                  { id: 'PERCENTAGE', label: translations.percentage },
                  { id: 'DISCOUNT', label: translations.discount },
                ],
                onChange: setType,
                disabled: id !== 'new',
              },
              type: 'select_1',
            },
            {
              label: translations.timesRedeemed,
              data: { value: timesRedeemed.toString() },
              type: 'text',
            },
          ]}
        />
      </>
    </Page>
  );
}

export { VoucherPage };
