import React from 'react';

import { Page, Table, TableSearchBar, VerticalStack } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

function OrganisationsPage() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams('organisations');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({ page: 1, q: value });
        return;
      }

      setSearchParams({ page, q: value });
    },
    path: '/organisations',
  });

  const [page, setPage] = React.useState(1);
  const { data: organisationsData, isFetching } = UseQuery.organisations({
    page,
    query: delayedSearchQuery,
  });

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  const translations = useTranslations('organisationsPage');

  const { navigate } = useNavigate();

  return (
    <Page
      name="organisations"
      actions={[
        {
          svgName: 'add',
          title: translations.addOrganisation,
          mode: 'closeOnClick',
          onClick: () => {
            navigate({ name: 'organisation', id: 'new', searchParams: { tab: 0 }, metaKey: false });
          },
        },
      ]}
    >
      <>
        <VerticalStack>
          <TableSearchBar
            placeholder={translations.organisations}
            label={translations.searchOrganisations}
            value={searchQuery}
            onChange={(value: string) => {
              setSearchQuery(value);
            }}
            fullWidth={true}
          />
          <Table
            columns={{
              name: { name: translations.name, type: 'text', flex: 1 },
              contactEmailAddress: {
                name: translations.contactEmailAddress,
                type: 'text',
                flex: 1,
              },
              organisationId: { name: translations.id, type: 'text', flex: 1 },
            }}
            rows={(organisationsData?.result ?? []).map(({ id, name, contact }) => ({
              id,
              name,
              contactEmailAddress: contact?.email ?? '-',
              organisationId: id,
            }))}
            onClickRow={({ id, metaKey }) => {
              navigate({
                name: 'organisation',
                id: id.toString(),
                searchParams: { tab: 0 },
                metaKey,
              });
            }}
            pagination={{
              count: getNumberOfPages({ meta: organisationsData?.meta }),
              page,
              onChange: (page) => {
                setPage(page);
                setSearchParams({ page, q: searchQuery });
              },
            }}
            loading={isFetching}
          />
        </VerticalStack>
      </>
    </Page>
  );
}

export { OrganisationsPage };
