import { AxiosHeaders, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs, { Dayjs } from 'dayjs';
import { Meta } from 'types/shared';

import 'dayjs/locale/nl';

function capitalizeFirstLetter(data: string): string {
  return data.charAt(0).toUpperCase() + data.slice(1);
}

function convertRemToPixel(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

interface GetNumberOfPagesProps {
  meta: Meta | undefined;
}

function getNumberOfPages({ meta }: GetNumberOfPagesProps): number {
  if (!meta) {
    return 0;
  }

  return Math.ceil(meta.count / meta.limit);
}

interface FormatPriceProps {
  price: number | undefined;
  currency?: string;
  symbol?: boolean;
}

function formatPrice({ price, currency, symbol = false }: FormatPriceProps) {
  let currencySymbol: string | undefined = '';

  if (symbol) {
    currencySymbol = getSymbolFromCurrency(currency ?? 'EUR');
  }

  if (price === undefined || isNaN(price)) {
    return `${currencySymbol ?? ''} -.--`;
  }

  return `${currencySymbol ?? ''} ${(price / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

interface FormatDateProps {
  date: Date | string | number | Dayjs;
  variant:
    | 'short'
    | 'dayMonthYear'
    | 'long'
    | 'backend'
    | 'backendShort'
    | 'console'
    | 'dayMonthYearExtra';
}

function formatDate({ date, variant }: FormatDateProps): string {
  if (!date) {
    return '';
  }

  if (variant === 'short') {
    const now = dayjs();
    const currentDate = dayjs(date);

    if (now.isSame(currentDate, 'day')) {
      return currentDate.format('[Today], HH:mm');
    }

    return currentDate.format('D MMM, HH:mm');
  }

  if (variant === 'dayMonthYear') {
    return dayjs(date).format('DD-MM-YYYY');
  }

  if (variant === 'dayMonthYearExtra') {
    return dayjs(date).format('DD-MM-YYYY (dddd)');
  }

  if (variant === 'long') {
    return dayjs(date).format('DD-MM-YYYY HH:mm');
  }

  if (variant === 'backend') {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZZ');
  }

  if (variant === 'backendShort') {
    return dayjs(date).format('YYYY-MM-DD');
  }

  if (variant === 'console') {
    return dayjs(date).format('HH:mm:ss.SSS');
  }

  return '';
}

interface FormatStringProps {
  text: string;
  mode: 'label';
}

function formatString({ text, mode }: FormatStringProps) {
  if (mode === 'label') {
    const convertedText = text.toLocaleLowerCase().replaceAll('_', '');

    return capitalizeFirstLetter(convertedText);
  }

  return '';
}

interface NamedBlob {
  blob: Blob;
  fileName: string;
}

interface CreateNamedBlobProps {
  blob: Blob;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

function createNamedBlob({ blob, headers }: CreateNamedBlobProps): NamedBlob {
  return {
    blob,
    fileName:
      ((headers as AxiosHeaders).get('Content-Disposition') as string)?.match(
        /filename="([^"]+)\.[^.]+"/,
      )?.[1] ?? '',
  };
}

type DownloadBlobProps =
  | {
      blob: Blob;
      user: string;
      date: string | Date;
      fileName?: undefined;
    }
  | {
      blob: Blob;
      user?: undefined;
      date?: undefined;
      fileName: string;
    };

function downloadBlob({ blob, user, date, fileName }: DownloadBlobProps) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;

  const { type } = blob;

  interface GetFileNameProps {
    preset: string;
    type: string;
  }

  function getFileName({ preset, type }: GetFileNameProps) {
    let result = '';

    if (fileName) {
      result = fileName;
    } else {
      result = `${preset}_${user?.replace(/ /g, '_')}_${date ?? Date().toString()}`;
    }
    return `${result}.${type}`;
  }

  if (type.startsWith('image/')) {
    link.setAttribute('download', getFileName({ preset: 'image', type: type.substring(6) }));
  }

  if (type.startsWith('application/')) {
    link.setAttribute('download', getFileName({ preset: 'receipt', type: type.substring(12) }));
  }

  if (type.startsWith('text/csv')) {
    link.setAttribute('download', getFileName({ preset: 'csv', type: 'csv' }));
  }

  document.body.appendChild(link);
  link.click();
}

const textFieldWidth = { xs: '100%', sm: '100%', md: 275, lg: 400, xl: 400 };

export {
  capitalizeFirstLetter,
  convertRemToPixel,
  createNamedBlob,
  downloadBlob,
  formatDate,
  formatPrice,
  formatString,
  getNumberOfPages,
  type NamedBlob,
  textFieldWidth,
};
