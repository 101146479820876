import {
  // eslint-disable-next-line no-restricted-imports
  useMutation,
  // eslint-disable-next-line no-restricted-imports
  useQuery,
  useQueryClient,
} from 'react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { Meta, Null } from 'types/shared';
import validator from 'validator';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface Voucher {
  id: string;
  type: 'PERCENTAGE' | 'DISCOUNT';
  code_phrase: string;
  value: number;
  valid_from: string;
  valid_to?: string;
  use_limit?: number;
  times_redeemed: number;
}

interface UseVouchersProps {
  page: number;
  limit?: number;
}

function useVouchers({ page, limit }: UseVouchersProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Voucher>[];
    meta: Meta;
  }

  function fetchData() {
    interface Params {
      page: number;
      limit: number | undefined;
    }

    return API.get<Response, Params, false>({
      path: '/admin/voucher-template',
      token,
      params: { page, limit: hasMobileView && limit === undefined ? 15 : limit },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['vouchers', { page, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

interface UseVoucherProps {
  id: string;
}

function useVoucher({ id }: UseVoucherProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Response {
    result: Voucher;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/admin/voucher-template/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['voucher', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token && validator.isUUID(id),
    retry: 0,
    select: ({ data }) => data,
  });
}

function useAddVoucher() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface AddVoucherProps {
    type: Voucher['type'];
    codePhrase: Voucher['code_phrase'];
    value: Voucher['value'];
    validFrom: Voucher['valid_from'];
    validTo?: Voucher['valid_to'];
    useLimit?: Voucher['use_limit'];
  }

  async function addVoucher({
    type,
    codePhrase,
    value,
    validFrom,
    validTo,
    useLimit,
  }: AddVoucherProps) {
    const { data } = await API.post<void, Omit<Voucher, 'id' | 'times_redeemed'>>({
      path: `/admin/voucher-template`,
      token,
      version: 1,
      body: {
        type,
        code_phrase: codePhrase,
        value,
        valid_from: validFrom,
        valid_to: dayjs(validTo).isValid() ? validTo : undefined,
        use_limit: useLimit,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, AddVoucherProps>((data: AddVoucherProps) => addVoucher(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['vouchers']);
    },
  });
}

function useEditVoucher() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  interface EditVoucherProps {
    id: Voucher['id'];
    type: Voucher['type'];
    codePhrase: Voucher['code_phrase'];
    value: Voucher['value'];
    validFrom: Voucher['valid_from'];
    validTo?: Voucher['valid_to'];
    useLimit?: Voucher['use_limit'];
  }

  async function editVoucher({
    id,
    type,
    codePhrase,
    value,
    validFrom,
    validTo,
    useLimit,
  }: EditVoucherProps) {
    const { data } = await API.put<void, Omit<Voucher, 'id' | 'times_redeemed'>>({
      path: `/admin/voucher-template/${id}`,
      token,
      version: 1,
      body: {
        type,
        code_phrase: codePhrase,
        value,
        valid_from: validFrom,
        valid_to: dayjs(validTo).isValid() ? validTo : undefined,
        use_limit: useLimit,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, EditVoucherProps>(
    (data: EditVoucherProps) => editVoucher(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['vouchers']);
        await queryClient.invalidateQueries(['voucher', { id }]);
      },
    },
  );
}

function useDeleteVoucher() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface DeleteVoucherProps {
    id: Voucher['id'];
  }

  async function deleteVoucher({ id }: DeleteVoucherProps) {
    const { data } = await API.delete<void>({
      path: `/admin/voucher-template/${id}`,
      token,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, DeleteVoucherProps>(
    (data: DeleteVoucherProps) => deleteVoucher(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['vouchers']);
        await queryClient.invalidateQueries(['voucher', { id }]);
      },
    },
  );
}

export { useAddVoucher, useDeleteVoucher, useEditVoucher, useVoucher, useVouchers, type Voucher };
