import React from 'react';

import { Page, TableTabs } from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import {
  BusinessStatistics,
  OrganisationStatistics,
  ProviderStatistics,
  ReceiptStatistics,
  ReservationStatistics,
  UserStatistics,
} from './statistics';

function StatisticsPage() {
  const translations = useTranslations('statisticsPage');

  const { searchParams, setSearchParams } = useSearchParams('statistics');

  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (searchParams.tab) {
      setTabIndex(searchParams.tab);
    }
  }, [searchParams]);

  return (
    <Page name="statistics">
      <TableTabs
        variant="content"
        data={[
          { label: translations.business, content: <BusinessStatistics /> },
          { label: translations.organisations, content: <OrganisationStatistics /> },
          { label: translations.reservations, content: <ReservationStatistics /> },
          { label: translations.users, content: <UserStatistics /> },
          { label: translations.providers, content: <ProviderStatistics /> },
          { label: translations.receipts, content: <ReceiptStatistics /> },
        ]}
        tabIndex={tabIndex}
        setTabIndex={(index) => {
          setSearchParams({ tab: index });
          setTabIndex(index);
        }}
      />
    </Page>
  );
}

export { StatisticsPage };
