import { useContext } from 'react';

import { MenuItemsContext } from './context';

function useMenuItems() {
  const { menuItems, setMenuItems } = useContext(MenuItemsContext);

  return { menuItems, setMenuItems };
}

export { useMenuItems };
