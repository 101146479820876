import React from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import dayjs from 'dayjs';

import { Container, DoughnutChart, Select } from 'components';
import { formatDate } from 'core/shared';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseQuery } from 'useQuery';

export function ReceiptStatistics() {
  const { data: organisationsData } = UseQuery.organisations({ page: 1, limit: 10000 });

  const translations = useTranslations('statisticsPage');
  const [organisationId, setOrganisationId] = React.useState<string>('all');
  const [periodInDays, setPeriodInDays] = React.useState<'30' | '90'>('90');

  const [period, setPeriod] = React.useState<[string, string]>(['', '']);

  React.useEffect(() => {
    setPeriod([
      formatDate({
        date: dayjs().subtract(parseInt(periodInDays, 10), 'days'),
        variant: 'backendShort',
      }),
      formatDate({ date: dayjs(), variant: 'backendShort' }),
    ]);
  }, [periodInDays]);

  const { data: receiptStatisticsData } = UseQuery.receiptStatistics({
    organisationId: organisationId === 'all' ? undefined : organisationId,
    startPeriod: period[0],
    endPeriod: period[1],
  });

  return (
    <>
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Grid xs={12} md={6} xl={3}>
          <Select
            value={organisationId}
            label={translations.organisation}
            mode="single"
            options={[
              ...(organisationsData?.result ?? []).map(({ id, name }) => ({
                id,
                label: name as CustomString,
              })),
              { id: 'all', label: translations.all },
            ]}
            onChange={setOrganisationId}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <Select
            value={periodInDays}
            mode="single"
            options={[
              {
                id: '30',
                label: replaceText({ text: translations.pastDays, replaceValues: ['30'] }),
              },
              {
                id: '90',
                label: replaceText({ text: translations.pastDays, replaceValues: ['90'] }),
              },
            ]}
            onChange={setPeriodInDays}
            label={translations.period}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Container
          title={translations.organisation}
          width="small"
          loading={receiptStatisticsData === undefined}
        >
          <DoughnutChart
            data={[
              { label: translations.pending, value: receiptStatisticsData?.result.PENDING ?? 0 },
              { label: translations.skipped, value: receiptStatisticsData?.result.SKIPPED ?? 0 },
              {
                label: translations.completed,
                value: receiptStatisticsData?.result.COMPLETED ?? 0,
              },
              {
                label: translations.publicTransport,
                value: receiptStatisticsData?.result.PUBLIC_TRANSPORT ?? 0,
              },
            ]}
            colors={['primary.main', 'info.main', 'success.main', 'error.main']}
          />
        </Container>
      </Grid>
    </>
  );
}
