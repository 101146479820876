import React from 'react';

import { Page, RowTypes, Table, TableSearchBar, TableTabs, VerticalStack } from 'components';
import { downloadBlob, formatDate, getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getLabelColor, getStatus } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';
import { Transaction } from 'useQuery/types';

function TransactionsPage() {
  const [searchQuery, setSearchQuery] = React.useState('');

  const [tabIndex, setTabIndex] = React.useState(0);
  const { searchParams, setSearchParams } = useSearchParams('transactions');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({ page: 1, q: value });
        return;
      }

      setSearchParams({ page, q: value });
    },
    path: '/transactions',
  });

  const [page, setPage] = React.useState(1);
  const { data: transactionsData, isFetching } = UseQuery.transactions({
    page,
    query: delayedSearchQuery,
  });
  const { mutateAsync: mutateAsyncDownloadInvoice } = UseMutation.downloadInvoice();

  const { mutateAsync: mutateAsyncResetTransaction } = UseMutation.resetTransaction();

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  const [loadingTransactionId, setLoadingTransactionId] = React.useState<
    Record<'download' | 'reset', string>
  >({
    download: '',
    reset: '',
  });

  const translations = useTranslations('transactionsPage');

  const { navigate } = useNavigate();

  interface GetInvoiceDataProps {
    status: Transaction['status'];
    skipped_invoice: Transaction['skipped_invoice'];
    product_description: Transaction['product_description'];
    public_transportation: Transaction['public_transportation'];
    id: string;
  }

  function getReceiptData({
    status,
    product_description,
    id,
  }: GetInvoiceDataProps): RowTypes['buttonOrSVGOrEmpty'] {
    if (status === 'PUBLIC_TRANSPORT' || status === 'SKIPPED') {
      return { svgName: 'checkCircle', tooltip: translations.noReceiptAvailable };
    }

    if (status === 'COMPLETED' && product_description === 'Travel Card') {
      return {
        onClick: () => {
          setLoadingTransactionId((prevState) => ({ ...prevState, download: id }));
          mutateAsyncDownloadInvoice({ id })
            .then((blob) => {
              downloadBlob({ blob, fileName: id });
              setLoadingTransactionId((prevState) => ({ ...prevState, download: '' }));
            })
            .catch(() => {
              setLoadingTransactionId((prevState) => ({ ...prevState, download: '' }));
            });
        },
        label: translations.download,
        loading: id === loadingTransactionId.download,
        disabled: !!loadingTransactionId.download,
      };
    }

    if (status === 'PENDING') {
      return { svgName: 'cloudOff', tooltip: translations.pending };
    }

    return undefined;
  }

  return (
    <Page name="transactions">
      <>
        <VerticalStack>
          <TableTabs
            variant="linkedFilter"
            data={[
              { label: translations.all, onPress: setSearchQuery, linkedValue: '', fallback: true },
              {
                label: translations.aethon,
                onPress: setSearchQuery,
                linkedValue: 'Aethon',
                fallback: false,
              },
              {
                label: translations.budbee,
                onPress: setSearchQuery,
                linkedValue: 'Budbee',
                fallback: false,
              },
              {
                label: translations.sungevity,
                onPress: setSearchQuery,
                linkedValue: 'Sungevity',
                fallback: false,
              },
              {
                label: translations.youngCapital,
                onPress: setSearchQuery,
                linkedValue: 'Young Capital',
                fallback: false,
              },
            ]}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            currentValue={searchQuery}
          />
          <TableSearchBar
            placeholder={translations.transactions}
            label={translations.searchTransactions}
            value={searchQuery}
            onChange={(value: string) => {
              setSearchQuery(value);
            }}
            fullWidth={true}
          />
          <Table
            columns={{
              name: { name: translations.name, type: 'text', width: 150 },
              transactionDate: { name: translations.transactionDate, type: 'text', flex: 1 },
              product: { name: translations.product, type: 'text', width: 150 },
              description: { name: translations.description, type: 'text', flex: 1 },
              priceIncVat: { name: translations.priceIncVat, type: 'price', width: 150 },
              status: { name: translations.status, type: 'label', width: 250 },
              note: { name: translations.note, type: 'text', flex: 1 },
              receipt: { name: translations.receipt, type: 'buttonOrSVGOrEmpty', width: 125 },
              organisation: { name: translations.organisation, type: 'text', width: 150 },
              reset: {
                name: translations.reset,
                type: 'buttonOrSVGOrEmpty',
                width: 100,
              },
            }}
            rows={(transactionsData?.result ?? []).map(
              ({
                id,
                entity,
                start,
                end,
                product_description,
                description,
                price,
                note,
                public_meta,
                status,
                skipped_invoice,
                public_transportation,
                organisation_name,
              }) => ({
                id,
                name: entity,
                transactionDate: end
                  ? `${formatDate({ date: start, variant: 'long' })} - ${formatDate({
                      date: end,
                      variant: 'long',
                    })}`
                  : formatDate({ date: start, variant: 'long' }),
                product: product_description,
                description: public_meta?.description ?? description ?? '',
                priceIncVat: price,
                note,
                status: {
                  label: getStatus({ transaction: status }),
                  color: getLabelColor({ transaction: status }),
                },
                receipt: getReceiptData({
                  status,
                  skipped_invoice,
                  product_description,
                  public_transportation,
                  id,
                }),
                organisation: organisation_name ?? '',
                reset:
                  status === 'COMPLETED' || status === 'SKIPPED'
                    ? {
                        onClick: () => {
                          setLoadingTransactionId((prevState) => ({ ...prevState, reset: id }));
                          mutateAsyncResetTransaction({ id })
                            .then(() => {
                              setLoadingTransactionId((prevState) => ({ ...prevState, reset: '' }));
                            })
                            .catch(() => {
                              setLoadingTransactionId((prevState) => ({ ...prevState, reset: '' }));
                            });
                        },
                        label: translations.reset,
                        loading: id === loadingTransactionId.reset,
                        disabled: !!loadingTransactionId.reset,
                      }
                    : undefined,
              }),
            )}
            onClickRow={({ id, metaKey }) => {
              navigate({
                name: 'transaction',
                id: id.toString(),
                searchParams: undefined,
                metaKey,
              });
            }}
            pagination={{
              count: getNumberOfPages({ meta: transactionsData?.meta }),
              page,
              onChange: (page) => {
                setPage(page);
                setSearchParams({ page, q: searchQuery });
              },
            }}
            loading={isFetching}
          />
        </VerticalStack>
      </>
    </Page>
  );
}

export { TransactionsPage };
