import React from 'react';
import { Box, Unstable_Grid2 as Grid, useTheme } from '@mui/material';

import { Button, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation } from 'useQuery';
import { Commission } from 'useQuery/types';

interface OrganisationCommissionsProps {
  id: string;
  commissions: Commission[] | null;
}

function OrganisationCommissions({ id, commissions }: OrganisationCommissionsProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const translations = useTranslations('organisationPage');
  const { hasMobileView } = useHasMobileView();
  const [commissionData, setCommissionData] = React.useState<Commission[]>([]);
  const {
    mutateAsync: mutateAsyncUpdateOrganisationCommissions,
    isLoading: loadingUpdateOrganisationComissions,
  } = UseMutation.updateOrganisationCommissions();

  React.useEffect(() => {
    setCommissionData(commissions ?? []);
  }, [commissions]);

  async function updateCommissions() {
    try {
      await mutateAsyncUpdateOrganisationCommissions({ id, commissions: commissionData });
      addToast({ title: translations.commissionsUpdatedSuccessfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  return (
    <>
      <Box sx={{ height: theme.spacing(2) }} />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
          border: 1,
          borderColor: 'divider',
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasMobileView ? 'row' : 'column',
            justifyContent: hasMobileView ? 'space-between' : undefined,
          }}
        >
          <Text.Bold>{translations.commissions}</Text.Bold>
        </Box>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          {commissionData?.map((commission, index) => (
            <Box
              key={index}
              sx={{
                padding: 1,
              }}
            >
              <TextField
                type="number"
                key={commission.vehicle_provider_id}
                label={`${commission.label} - %`}
                value={commission.commission_percent}
                onChange={(value) => {
                  commissionData[index] = {
                    commission_percent: Math.max(Math.min(value, 10), 0),
                    label: commission.label,
                    vehicle_provider_id: commission.vehicle_provider_id,
                  };

                  setCommissionData([...commissionData]);
                }}
              />
            </Box>
          ))}
        </Grid>

        <Grid
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <Grid key={1} sx={{ paddingLeft: 1 }}>
            <Button
              variant={'contained'}
              label={translations.save}
              onClick={updateCommissions}
              disabled={loadingUpdateOrganisationComissions}
              loading={loadingUpdateOrganisationComissions}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export { OrganisationCommissions };
