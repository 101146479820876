// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { formatDate } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { TravelCardStatus } from 'useQuery/types';

interface Driver {
  accessible_vehicle_subtypes: unknown;
  accessible_vehicle_types: ('CAR' | 'MOPED')[];
  allow_payment_instrument: boolean;
  communities: {
    id: string;
    name: string;
    fleets: string[];
  }[];
  created_on: string;
  date_of_birth: string;
  document_number: string;
  licence: {
    valid_to: string | null;
    issued_on: string | null;
    categories: { name: string; issued_on: string; valid_to: string }[];
  };
  email: string;
  end_date: string | null;
  first_name: string;
  felyx_waiting_list: unknown;
  gender: 'male' | 'female';
  groups: string[];
  id: string;
  last_name: string;
  mfa_status: boolean;
  has_mywheels: boolean;
  organisation: {
    id: string;
    name: string;
  };
  personal_info: {
    email: string;
    has_payment_information: boolean;
    phone_number: string;
    address: string;
    zipcode: string;
    city: string;
    verified: boolean;
  };
  provider_data: {
    greenwheels: {
      external_id?: string;
      card_number?: string;
    };
  };
  phone_number: string;
  balance: number;
  privacy_policy_accepted_at: string;
  providers: string[];
  reference: string;
  start_date: string | null;
  status: 'ACTIVATED' | 'DEACTIVATED' | 'INVITED' | 'PENDING';
  terms_conditions_accepted_at: string;
  validate_payment: boolean;
}

interface TravelCards {
  travel_cards: {
    id: string;
    status: TravelCardStatus;
    masked_number: string;
  }[];
}

interface UseDriversProps {
  page: number;
  query?: string;
  limit?: number;
}

function useDrivers({ page, query, limit }: UseDriversProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Driver>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    q: string;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/users',
      token,
      params: { page, q: query ?? '', limit: hasMobileView && limit === undefined ? 15 : limit },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['drivers', { page, query, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && page >= 1,
      retry: 0,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

interface UseDriverProps {
  id: string;
}

function useDriver({ id }: UseDriverProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<Driver & TravelCards>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/users/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['driver', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

function useExportDrivers() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  async function fetchData() {
    const { data } = await API.get<Blob, undefined, true>({
      path: `/admin/user/export`,
      token,
      version: 1,
      params: undefined,
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown>(() => fetchData());
}

function useDataProtection() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface DataProtectionProps {
    id: string;
    email: string;
  }

  interface Body {
    email: string;
  }

  async function dataProtection({ id, email }: DataProtectionProps) {
    const { data } = await API.put<DataProtectionProps, Body>({
      path: `/admin/data-protection/${id}/forget`,
      token,
      version: 1,
      body: {
        email,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<DataProtectionProps, unknown, DataProtectionProps>(
    (data) => dataProtection(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['drivers']);
        await queryClient.invalidateQueries(['driver', { id }]);
      },
    },
  );
}

function useEditDriverOrganistaion() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverOrganisationProps {
    id: string;
    organisationId: string;
  }

  interface Body {
    organisation_id: string;
  }

  async function editDriverOrganisation({ id, organisationId }: EditDriverOrganisationProps) {
    const { data } = await API.patch<EditDriverOrganisationProps, Body>({
      path: `/users/${id}`,
      token,
      version: 1,
      body: {
        organisation_id: organisationId,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriverOrganisationProps, unknown, EditDriverOrganisationProps>(
    (data) => editDriverOrganisation(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['driver', { id }]);
      },
    },
  );
}

function useEditDriver2FA() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriver2FAProps {
    id: string;
    enabled: boolean;
  }

  interface Body {
    enabled: boolean;
  }

  async function editDriver2FA({ id, enabled }: EditDriver2FAProps) {
    const { data } = await API.post<EditDriver2FAProps, Body>({
      path: `/users/${id}/set-2fa`,
      token,
      version: 1,
      body: {
        enabled,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriver2FAProps, unknown, EditDriver2FAProps>((data) =>
    editDriver2FA(data),
  );
}

function useEditDriverDashboardAccess() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverDashboardAccessProps {
    id: string;
    enabled: boolean;
  }

  interface Body {
    enabled: boolean;
  }

  async function editDriverDashboardAccess({ id, enabled }: EditDriverDashboardAccessProps) {
    const { data } = await API.post<EditDriverDashboardAccessProps, Body>({
      path: `/users/${id}/set-dashboard`,
      token,
      version: 1,
      body: {
        enabled,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriverDashboardAccessProps, unknown, EditDriverDashboardAccessProps>(
    (data) => editDriverDashboardAccess(data),
  );
}

function useEditDriverPaymentInstrument() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverPaymentInstrumentProps {
    id: string;
    enabled: boolean;
  }

  interface Body {
    allow_payment_instrument: boolean;
  }

  async function editDriverPaymentInstrument({ id, enabled }: EditDriverPaymentInstrumentProps) {
    const { data } = await API.put<EditDriverPaymentInstrumentProps, Body>({
      path: `/admin/user/${id}/payment_instrument`,
      token,
      version: 1,
      body: {
        allow_payment_instrument: enabled,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriverPaymentInstrumentProps, unknown, EditDriverPaymentInstrumentProps>(
    (data) => editDriverPaymentInstrument(data),
  );
}

function useEditDriverDetails() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverDetailsProps {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    validatePayment: boolean;
  }

  interface Body {
    first_name: string;
    last_name: string;
    phone_number: string;
    validate_payment: boolean;
  }

  async function editDriverDetails({
    id,
    firstName,
    lastName,
    phoneNumber,
    validatePayment,
  }: EditDriverDetailsProps) {
    const { data } = await API.patch<EditDriverDetailsProps, Body>({
      path: `/users/${id}`,
      token,
      version: 1,
      body: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        validate_payment: validatePayment,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriverDetailsProps, unknown, EditDriverDetailsProps>((data) =>
    editDriverDetails(data),
  );
}

function useEditDriverPersonalInformation() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverPersonalInformationProps {
    id: string;
    email: string;
    phoneNumber: string;
  }

  interface Body {
    personal_info: {
      email: string;
      phone_number: string;
    };
  }

  async function editDriverPersonalInformation({
    id,
    email,
    phoneNumber,
  }: EditDriverPersonalInformationProps) {
    const { data } = await API.patch<EditDriverPersonalInformationProps, Body>({
      path: `/users/${id}`,
      token,
      version: 1,
      body: {
        personal_info: {
          email,
          phone_number: phoneNumber,
        },
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<
    EditDriverPersonalInformationProps,
    unknown,
    EditDriverPersonalInformationProps
  >((data) => editDriverPersonalInformation(data), {
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries(['driver', { id }]);
    },
  });
}

function useEditProviderMeta() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditProviderMetaProps {
    id: string;
    greenwheels_driver_id: string;
    greenwheels_card_number: string;
  }

  interface Body {
    greenwheels_driver_id: string;
    greenwheels_card_number: string;
  }

  async function editProviderMeta({
    id,
    greenwheels_driver_id,
    greenwheels_card_number,
  }: EditProviderMetaProps) {
    const { data } = await API.put<EditProviderMetaProps, Body>({
      path: `/admin/user/${id}/meta`,
      token,
      version: 1,
      body: {
        greenwheels_driver_id,
        greenwheels_card_number,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditProviderMetaProps, unknown, EditProviderMetaProps>(
    (data) => editProviderMeta(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['driver', { id }]);
      },
    },
  );
}

function useAddDriversLicense() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface AddDriversLicenseProps {
    id: string;
    documentNumber: string;
    validTo: string;
    dateOfBirth: string;
    categories: {
      validTo: string;
      issuedOn: string;
      name: string;
    }[];
  }

  interface Body {
    document_number: string;
    document_valid_to: string;
    date_of_birth: string;
    categories: {
      valid_to: string;
      issued_on: string;
      name: string;
    }[];
  }

  async function addDriversLicense({
    id,
    documentNumber,
    dateOfBirth,
    validTo,
    categories,
  }: AddDriversLicenseProps) {
    const { data } = await API.put<AddDriversLicenseProps, Body>({
      path: `/users/${id}/license`,
      token,
      version: 1,
      body: {
        document_number: documentNumber,
        document_valid_to: formatDate({ date: validTo, variant: 'backendShort' }),
        date_of_birth: formatDate({ date: dateOfBirth, variant: 'backendShort' }),
        categories: categories.map(({ validTo, issuedOn, name }) => ({
          valid_to: formatDate({ date: validTo, variant: 'backendShort' }),
          issued_on: formatDate({ date: issuedOn, variant: 'backendShort' }),
          name,
        })),
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<AddDriversLicenseProps, unknown, AddDriversLicenseProps>(
    (data) => addDriversLicense(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['driver', { id }]);
      },
    },
  );
}

function useEditDriverVerified() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditDriverVerifiedProps {
    id: string;
    verified: boolean;
  }

  interface Body {
    verified: boolean;
  }

  async function editDriverVerified({ id, verified }: EditDriverVerifiedProps) {
    const { data } = await API.post<EditDriverVerifiedProps, Body>({
      path: `/users/${id}/verify`,
      token,
      version: 1,
      body: {
        verified,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<EditDriverVerifiedProps, unknown, EditDriverVerifiedProps>(
    (data) => editDriverVerified(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['driver', { id }]);
      },
    },
  );
}

function useSendDriverInvite() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface SendDriverInviteProps {
    driverId: string;
    organistaionId: string;
  }

  async function sendDriverInvites({ driverId, organistaionId }: SendDriverInviteProps) {
    const { data } = await API.post<SendDriverInviteProps, undefined>({
      path: `/organisations/${organistaionId}/users/${driverId}/send-invite`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<SendDriverInviteProps, unknown, SendDriverInviteProps>((data) =>
    sendDriverInvites(data),
  );
}

function useRemitDriverInvoices() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface RemitDriverInvoicesProps {
    driverId: string;
  }

  async function remitDriverInvoices({ driverId }: RemitDriverInvoicesProps) {
    const { data } = await API.put<RemitDriverInvoicesProps, undefined>({
      path: `/admin/user/${driverId}/remit-invoices`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<RemitDriverInvoicesProps, unknown, RemitDriverInvoicesProps>((data) =>
    remitDriverInvoices(data),
  );
}

function useAddDriverBalance() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface AddDriverBalanceProps {
    driverId: string;
    balance: number;
  }

  interface Body {
    balance: number;
  }

  async function addDriverBalance({ driverId, balance }: AddDriverBalanceProps) {
    const { data } = await API.put<AddDriverBalanceProps, Body>({
      path: `/admin/user/${driverId}/balance`,
      token,
      version: 1,
      body: { balance },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<AddDriverBalanceProps, unknown, AddDriverBalanceProps>((data) =>
    addDriverBalance(data),
  );
}

function useSyncGreenwheels() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface SyncGreenwheelsProp {
    cookie: string;
  }

  interface Body {
    cookie: string;
  }

  async function syncGreenwheels({ cookie }: SyncGreenwheelsProp) {
    const { data } = await API.post<Blob, Body>({
      path: `/admin/sync-customers`,
      token,
      expectFile: true,
      version: 1,
      body: { cookie },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown, SyncGreenwheelsProp>((data) => syncGreenwheels(data));
}

export {
  type Driver,
  useAddDriverBalance,
  useAddDriversLicense,
  useDataProtection,
  useDriver,
  useDrivers,
  useEditDriver2FA,
  useEditDriverDashboardAccess,
  useEditDriverDetails,
  useEditDriverOrganistaion,
  useEditDriverPaymentInstrument,
  useEditDriverPersonalInformation,
  useEditDriverVerified,
  useEditProviderMeta,
  useExportDrivers,
  useRemitDriverInvoices,
  useSendDriverInvite,
  useSyncGreenwheels,
};
