import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Bar } from 'react-chartjs-2';
import { Box, useTheme } from '@mui/material';
import { TooltipItem } from 'chart.js';
import { ChartType } from 'chart.js/dist/types';
import { Color } from 'types/shared';

import { getColorFromTheme, getColorRangeFromTheme } from 'core/color';
import { useDarkMode } from 'core/darkMode/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { CustomColor } from 'config/customColors';

type StackedBarChartProps =
  | {
      labels: string[];
      data: {
        label: string;
        values: number[];
      }[];
      backgroundColors?: undefined;
      colors?: undefined;
    }
  | {
      labels: string[];
      data: {
        label: string;
        values: number[];
      }[];
      colors: (Color | CustomColor)[];
    };

function tooltipFooter(tooltipItems: TooltipItem<ChartType>[]) {
  let sum = 0;

  tooltipItems.forEach(function (tooltipItem: TooltipItem<ChartType>) {
    sum += tooltipItem.parsed.y;
  });
  return `Sum: ${sum.toFixed(0)}`;
}

function StackedBarChart({ labels, data, colors }: StackedBarChartProps) {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const { hasMobileView } = useHasMobileView();

  const [backgroundColors, setBackgroundColors] = React.useState<string[]>([]);
  const [borderColors, setBorderColors] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (colors !== undefined) {
      if (colors.length !== data.length) {
        console.error('StackedBarChartProps: backgroundColors.length !== data.length');
        console.info('colors', colors);
        console.info('data', data);
      } else {
        setBackgroundColors(
          colors.map((color) => getColorFromTheme({ color, alpha: 0.85, theme })),
        );

        setBorderColors(colors.map((color) => getColorFromTheme({ color, alpha: 1, theme })));
        return;
      }
    }

    setBackgroundColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 0.85,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );

    setBorderColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 1,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );
  }, [data.length, colors]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: `calc(100vw - ${theme.spacing(11)})`,
        padding: 1,
        height: 300,
      }}
    >
      <Bar
        plugins={[
          {
            id: 'legend',
            beforeInit: function (chart) {
              if (!chart.legend) {
                return;
              }
              const originalFit = (chart.legend as unknown as { fit: () => void }).fit;

              // Workaround to make the legend fit above the graph area
              // Override the fit function
              (chart.legend as unknown as { fit: () => void }).fit = function fit() {
                // Call the original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)();
                // Change the height as suggested in other answers
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.height += parseInt(theme.spacing(hasMobileView ? 1 : 2), 10);
              };
            },
          },
        ]}
        options={{
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                footer: tooltipFooter,
              },
            },
            legend: {
              display: data.length > 1,

              labels: {
                font: {
                  family: theme.typography.fontFamily,
                },
                color: getColorFromTheme({
                  color: mode === 'light' ? 'common.black' : 'common.white',
                  theme,
                }),
              },
            },
          },
          responsive: true,
          scales: {
            y: {
              stacked: true,
              grid: {
                drawTicks: true,
                display: true,
              },
              ticks: {
                display: true,
                color: getColorFromTheme({
                  color: mode === 'light' ? 'common.black' : 'common.white',
                  theme,
                }),
              },
              border: {
                width: 0,
              },
            },
            x: {
              stacked: true,
              grid: {
                drawTicks: true,
                display: false,
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                },
                color: getColorFromTheme({
                  color: mode === 'light' ? 'common.black' : 'common.white',
                  theme,
                }),
              },
              border: {
                width: 0,
              },
            },
          },
        }}
        data={{
          labels,
          datasets: data.map(({ label, values }, index) => ({
            label: label,
            data: values,
            backgroundColor: backgroundColors[index],
            borderWidth: 1,
            borderColor: borderColors[index],
          })),
        }}
      />
    </Box>
  );
}

export { StackedBarChart };
