import { getAppEnviroment } from './shared';

function getApiUrl() {
  switch (getAppEnviroment()) {
    case 'PRODUCTION':
      return 'https://api.figo.app';
    case 'DEVELOPMENT':
      return 'http://localhost:6543';
    default:
      return 'https://api-test.figo.app';
  }
}

export { getApiUrl };
