import React from 'react';
import { Box } from '@mui/material';

import {
  Dialog,
  Page,
  RowTypes,
  Table,
  TableSearchBar,
  Text,
  TextField,
  VerticalStack,
} from 'components';
import { formatPrice, getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

function BalanceAccountsPage() {
  const { navigate } = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({
          page: 1,
          q: value,
        });
        return;
      }
    },
    path: '/balance-accounts',
  });

  const translations = useTranslations('balanceAccountsPage');
  const [page, setPage] = React.useState(1);

  const [balance, setBalance] = React.useState<Record<string, number | undefined>>({});
  const [loading, setLoading] = React.useState<string | false>(false);

  const [dialogNameCheck, setDialogNameCheck] = React.useState('');
  const [addAmount, setAddAmount] = React.useState(0);
  const [removeAmount, setRemoveAmount] = React.useState(0);

  const [openAdd, setOpenAdd] = React.useState<{ id: string; name: string } | false>(false);
  const [openRemove, setOpenRemove] = React.useState<{ id: string; name: string } | false>(false);
  const [openWait, setOpenWait] = React.useState<{ name: string } | false>(false);

  const { data: reservationsData, isFetching: isFetchingReservations } = UseQuery.organisations({
    page,
    query: delayedSearchQuery,
  });

  const { mutateAsync: mutateAsyncGetBalance } = UseMutation.getBalance();
  const { mutateAsync: mutateAsyncUpdateBalance, isLoading: isLoadingUpdateBalance } =
    UseMutation.updateBalance();

  const { searchParams, setSearchParams } = useSearchParams('balanceAccounts');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  return (
    <Page name="balanceAccounts">
      <Dialog
        title={replaceText({
          text: translations.dialogAddTitle,
          replaceValues: [openAdd !== false ? openAdd.name : '-'],
        })}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenAdd(false);
            },
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              if (openAdd !== false) {
                try {
                  await mutateAsyncUpdateBalance({
                    id: openAdd.id,
                    amount: Math.round(addAmount * 100),
                    direction: 'to',
                  });

                  setBalance((prevState) => ({ ...prevState, [openAdd.id]: undefined }));
                  setOpenAdd(false);
                  setOpenWait({ name: openAdd.name });
                } catch (error) {
                  // Do nothing
                }
              }
            },
            loading: isLoadingUpdateBalance,
            disabled: addAmount <= 0 || !openAdd || dialogNameCheck !== openAdd.name,
          },
        ]}
        open={openAdd !== false}
        onChange={(open) => {
          if (!open) {
            setOpenAdd(false);
          }
        }}
      >
        <Text>{translations.pleaseFillIn}</Text>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            fullWidth={true}
            label={translations.name}
            variant="outlined"
            onChange={setDialogNameCheck}
            value={dialogNameCheck.toString()}
            type="name"
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            fullWidth={true}
            label={translations.amount}
            variant="outlined"
            onChange={setAddAmount}
            value={addAmount}
            type="number"
          />
        </Box>
      </Dialog>
      <Dialog
        title={replaceText({
          text: translations.dialogRemoveTitle,
          replaceValues: [openRemove !== false ? openRemove.name : '-'],
        })}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenRemove(false);
            },
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              if (openRemove !== false) {
                try {
                  await mutateAsyncUpdateBalance({
                    id: openRemove.id,
                    amount: Math.round(removeAmount * 100),
                    direction: 'from',
                  });

                  setBalance((prevState) => ({ ...prevState, [openRemove.id]: undefined }));
                  setOpenRemove(false);
                  setOpenWait({ name: openRemove.name });
                } catch (error) {
                  // Do nothing
                }
              }
            },
            loading: false,
            disabled: removeAmount <= 0 || !openRemove || dialogNameCheck !== openRemove.name,
          },
        ]}
        open={openRemove !== false}
        onChange={(open) => {
          if (!open) {
            setOpenRemove(false);
          }
        }}
      >
        <Text>{translations.pleaseFillIn}</Text>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            fullWidth={true}
            label={translations.name}
            variant="outlined"
            onChange={setDialogNameCheck}
            value={dialogNameCheck}
            type="name"
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            fullWidth={true}
            label={translations.amount}
            variant="outlined"
            onChange={setRemoveAmount}
            value={removeAmount}
            type="number"
          />
        </Box>
      </Dialog>
      <Dialog
        title={translations.dialogWaitTitle}
        footer={[
          {
            variant: 'contained',
            label: translations.ok,
            onClick: () => {
              setOpenWait(false);
            },
          },
        ]}
        open={openWait !== false}
        onChange={(open) => {
          if (!open) {
            setOpenWait(false);
          }
        }}
      >
        <Text>
          {replaceText({
            text: translations.dialogWaitMessage,
            replaceValues: [openWait !== false ? openWait.name : '-'],
          })}
        </Text>
      </Dialog>
      <VerticalStack>
        <TableSearchBar
          placeholder={translations.balanceAccounts}
          label={translations.searchBalanceAccounts}
          value={searchQuery}
          onChange={(value: string) => {
            setSearchQuery(value);
          }}
          fullWidth={true}
        />
        <Table
          columns={{
            name: { name: translations.name, type: 'text', width: 300 },
            reveal: { name: translations.reveal, type: 'buttonOrSVGOrEmpty', width: 100 },
            balance: { name: translations.balance, type: 'textOrSVG', width: 100 },
            add: { name: translations.funds, type: 'buttonOrSVGOrEmpty', width: 100 },
            remove: { name: translations.funds, type: 'buttonOrSVGOrEmpty', width: 100 },
          }}
          rows={(reservationsData?.result ?? []).map(({ id, name, allow_funds_transfer }) => ({
            id,
            name: name ?? '',
            reveal: {
              variant: balance[id] !== undefined ? 'outlined' : 'contained',
              label: balance[id] !== undefined ? translations.close : translations.reveal,
              onClick: async () => {
                if (balance[id] !== undefined) {
                  setBalance((prevState) => ({ ...prevState, [id]: undefined }));
                  return;
                }

                setLoading(id);
                try {
                  const { result } = await mutateAsyncGetBalance({ id });

                  setBalance((prevState) => ({ ...prevState, [id]: result.available }));
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                }
              },

              disabled: false,
              loading: loading === id,
            } as RowTypes['buttonOrSVGOrEmpty'],
            balance:
              balance[id] !== undefined
                ? formatPrice({ price: balance[id], symbol: true })
                : ({ svgName: 'lock' } as RowTypes['textOrSVG']),
            add:
              balance[id] !== undefined && allow_funds_transfer
                ? {
                    label: translations.add,
                    onClick: () => {
                      setAddAmount(0);
                      setDialogNameCheck('');
                      setOpenAdd({ id, name });
                    },
                  }
                : allow_funds_transfer
                ? ({ svgName: 'lock' } as RowTypes['buttonOrSVGOrEmpty'])
                : undefined,
            remove:
              balance[id] !== undefined && allow_funds_transfer
                ? {
                    label: translations.remove,
                    onClick: () => {
                      setRemoveAmount(0);
                      setDialogNameCheck('');
                      setOpenRemove({ id, name });
                    },
                  }
                : allow_funds_transfer
                ? ({ svgName: 'lock' } as RowTypes['buttonOrSVGOrEmpty'])
                : undefined,
          }))}
          onClickRow={({ id, metaKey }) => {
            navigate({
              name: 'organisation',
              id: id.toString(),
              searchParams: { tab: 0 },
              metaKey,
            });
          }}
          pagination={{
            count: getNumberOfPages({ meta: reservationsData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({
                page,
                q: delayedSearchQuery,
              });
              setPage(page);
            },
          }}
          loading={isFetchingReservations || reservationsData === undefined}
        />
      </VerticalStack>
    </Page>
  );
}

export { BalanceAccountsPage };
