import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Doughnut } from 'react-chartjs-2';
import { Box, useTheme } from '@mui/material';
import { Plugin } from 'chart.js';
import { Color } from 'types/shared';

import { getColorFromTheme, getColorRangeFromTheme } from 'core/color';
import { useDarkMode } from 'core/darkMode/hooks';

import { CustomColor } from 'config/customColors';

type DoughnutChartProps =
  | {
      data: {
        label: string;
        value: number;
      }[];
      colors?: undefined;
    }
  | {
      data: {
        label: string;
        value: number;
      }[];
      colors: (Color | CustomColor)[];
    };

function DoughnutChart({ data, colors }: DoughnutChartProps) {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const [backgroundColors, setBackgroundColors] = React.useState<string[]>([]);
  const [borderColors, setBorderColors] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (colors !== undefined) {
      if (colors.length !== data.length) {
        console.error('DoughnutChart: backgroundColors.length !== data.length');
        console.info('colors', colors);
        console.info('data', data);
      } else {
        setBackgroundColors(
          colors.map((color) => getColorFromTheme({ color, alpha: 0.85, theme })),
        );

        setBorderColors(colors.map((color) => getColorFromTheme({ color, alpha: 1, theme })));
        return;
      }
    }

    setBackgroundColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 0.85,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );
    setBorderColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 1,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );
  }, [data.length, colors]);

  const textValue: Plugin<'doughnut'> = {
    id: 'text',
    afterDatasetDraw: (chart, args) => {
      const { ctx, data } = chart;
      const index = chart.getActiveElements()[0]?.index ?? 0;
      const fillStyle = args.meta.data[index]?.options?.backgroundColor as string;
      const value = data.datasets[0].data[index];

      ctx.save();
      ctx.font = `bold 2.5rem ${theme.typography.fontFamily}`;
      ctx.fillStyle = fillStyle;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      if (typeof value === 'number') {
        ctx.fillText(
          `${value.toString()}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y - 20,
        );
      }
    },
  };

  const textLabel: Plugin<'doughnut'> = {
    id: 'text',
    afterDatasetDraw: (chart) => {
      const { ctx, data, legend } = chart;
      const index = chart.getActiveElements()[0]?.index ?? 0;
      const fillStyle =
        (legend?.legendItems ?? [])[0].fontColor ??
        getColorFromTheme({ color: 'common.white', alpha: 1, theme });
      const value = (data.labels ?? [])[index];

      ctx.save();
      ctx.font = `bold 1.25rem ${theme.typography.fontFamily}`;
      ctx.fillStyle = fillStyle;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      if (typeof value === 'string') {
        ctx.fillText(
          value,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y + 20,
        );
      }
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 300, display: 'flex', justifyContent: 'center' }}>
      <Doughnut
        updateMode="resize"
        data={{
          labels: data.map(({ label }) => label),
          datasets: [
            {
              data: data.map(({ value }) => value),
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
              hoverBorderWidth: 2,
            },
          ],
        }}
        plugins={[textValue, textLabel]}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          cutout: '75%',
          maintainAspectRatio: true,
          font: { family: theme.typography.fontFamily },
          color: getColorFromTheme({
            color: mode === 'light' ? 'common.black' : 'common.white',
            theme,
          }),
        }}
      />
    </Box>
  );
}

export { DoughnutChart };
