// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

function useBulkInviteUpload() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface BulkInviteUploadProps {
    organisation_id?: string;
    file: File;
  }

  interface Body {
    file: File;
  }

  async function bulkInviteUpload({ organisation_id, file }: BulkInviteUploadProps) {
    const { data } = await API.post<void, Body>({
      path: `/organisations/${organisation_id}/users/import`,
      token,
      version: 1,
      body: { file },
      fileObject: file,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, BulkInviteUploadProps>((data: BulkInviteUploadProps) =>
    bulkInviteUpload(data),
  );
}

export { useBulkInviteUpload };
