import React from 'react';

import { ButtonProps, Dialog, InformationBlock, Page, Text } from 'components';
import { formatPrice } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'hooks/useParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseMutation, UseQuery } from 'useQuery';
import { Product } from 'useQuery/types';

function ProductPage() {
  const [name, setName] = React.useState<string>('');
  const [priceExlVAT, setPriceExlVAT] = React.useState<number>(0);
  const [VAT, setVAT] = React.useState<string>();

  const { addToast } = useToast();

  const { id } = useParams('product');
  const translations = useTranslations('productPage');
  const { navigate } = useNavigate();

  const { data: VATData } = UseQuery.VAT();
  const { data: productData } = UseQuery.product({ id });

  const { mutateAsync: mutateAsyncAddProduct, isLoading: isLoadingAddProduct } =
    UseMutation.addProduct();
  const { mutateAsync: mutateAsyncEditProduct, isLoading: isLoadingEditProduct } =
    UseMutation.editProduct();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const result = productData?.result;

    if (!result) {
      return;
    }

    const { name, net_unit_price, vat } = result;

    setName(name);
    setPriceExlVAT(net_unit_price);

    if (vat?.name) {
      setVAT(vat?.id);
    }
  }, [productData]);

  async function addProduct() {
    try {
      await mutateAsyncAddProduct({ name, priceExlVAT: priceExlVAT ?? 0, VAT: VAT ?? '' });
      navigate({ name: 'products', id: undefined, searchParams: { page: 1 }, metaKey: false });
      addToast({ title: translations.successfullyAdded, severity: 'success' });
      return Promise.resolve();
    } catch (error) {
      setOpen(false);
    }
  }

  async function editProduct() {
    try {
      await mutateAsyncEditProduct({ id, name, priceExlVAT: priceExlVAT ?? 0, VAT: VAT ?? '' });
      navigate({ name: 'products', id: undefined, searchParams: { page: 1 }, metaKey: false });
      addToast({ title: translations.successfullyAdded, severity: 'success' });
      return Promise.resolve();
    } catch (error) {
      setOpen(false);
    }
  }

  const hasEditedValues =
    name !== productData?.result?.name ||
    priceExlVAT !== productData?.result?.net_unit_price ||
    VAT !== productData?.result?.vat?.id;

  const hasNewValues = id === 'new' && !!name && priceExlVAT >= 0 && !!VAT;

  const footer: ButtonProps[] = [
    {
      label: id === 'new' ? translations.cancel : translations.restore,
      variant: 'outlined',
      onClick: () => {
        if (id !== 'new' && hasEditedValues) {
          setName(productData?.result?.name ?? '');
          setPriceExlVAT(productData?.result?.net_unit_price ?? 0);
          setVAT(productData?.result?.vat?.id);
          return;
        }

        if (id === 'new') {
          navigate({ name: 'products', id: undefined, searchParams: { page: 1 }, metaKey: false });
        }
      },
      disabled: id !== 'new' && !hasEditedValues,
    },
    {
      label: id === 'new' ? translations.save : translations.update,
      onClick: () => {
        setOpen(true);
      },
      disabled: (id !== 'new' && !hasEditedValues) || (id === 'new' && !hasNewValues),
      loading: isLoadingAddProduct || isLoadingEditProduct,
    },
  ];

  return (
    <Page
      name="product"
      loading={(productData === undefined && id !== 'new') || VATData === undefined}
      footer={footer}
    >
      <Dialog
        title={translations.dialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpen(false);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              id === 'new' ? await addProduct() : await editProduct();
            },
            loading: isLoadingAddProduct || isLoadingEditProduct,
          },
        ]}
        open={open}
        onChange={setOpen}
      >
        <Text>
          {replaceText({
            text: translations.dialogMessage,
            replaceValues: [formatPrice({ price: priceExlVAT, symbol: true })],
          })}
        </Text>
      </Dialog>
      <InformationBlock<Product['vat']['id']>
        items={[
          {
            label: translations.name,
            data: {
              value: name,
              onChange: setName,
              placeholder: '',
              onEnter: () => {},
              required: true,
            },
            type: 'editName',
          },
          {
            label: translations.priceExlVAT,
            data: {
              value: priceExlVAT,
              onChange: setPriceExlVAT,
              placeholder: 0,
              onEnter: () => {},
              required: true,
            },
            type: 'editNumber',
          },
          {
            label: translations.VAT,
            data: {
              mode: 'single',
              label: translations.VAT,
              value: VAT,
              options: (VATData?.result ?? []).map(({ id, name }) => ({
                id,
                label: name as CustomString,
              })),
              onChange: setVAT,
              required: true,
            },
            type: 'select_1',
          },
        ]}
      />
    </Page>
  );
}

export { ProductPage };
