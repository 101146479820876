import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Dialog, InformationBlock, Page, Switch, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useParams } from 'hooks/useParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { getLabelColor } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';

function ReservationPage() {
  const { id } = useParams('reservation');
  const translations = useTranslations('reservationPage');
  const theme = useTheme();

  const { addToast } = useToast();

  const {
    data: reservationData,
    isFetching: isFetchingReservation,
    refetch: refetchReservation,
  } = UseQuery.reservation({
    reservationId: id,
  });

  const { mutateAsync: mutateAsyncLockVehicle, isLoading: isLoadingLockVehicle } =
    UseMutation.lockVehicle();
  const { mutateAsync: mutateAsyncUnlockVehicle, isLoading: isLoadingUnlockVehicle } =
    UseMutation.unlockVehicle();

  const { mutateAsync: mutateAsyncCancelReservation, isLoading: isLoadingCancelReservation } =
    UseMutation.cancelReservation();
  const { mutateAsync: mutateAsyncCloseReservation, isLoading: isLoadingCloseReservation } =
    UseMutation.closeReservation();

  const { mutateAsync: mutateAsyncInvoiceReservation, isLoading: isLoadingInvoiceReservation } =
    UseMutation.invoiceReservation();

  const {
    status,
    provider,
    type,
    closed_on,
    end_date,
    start_date,
    started_on,
    total_price,
    user,
    external_id,
    cancelled_on,
    created_on,
    vehicle,
    finalized_on,
    invoice_id,
  } = reservationData?.result ?? {};

  const [openLockVehicle, setOpenLockVehicle] = React.useState(false);
  const [openUnlockVehicle, setOpenUnlockVehicle] = React.useState(false);
  const [openCloseReservation, setOpenCloseReservation] = React.useState(false);
  const [openCancelReservation, setOpenCancelReservation] = React.useState(false);
  const [openInvoiceReservation, setOpenInvoiceReservation] = React.useState(false);

  const [forceClose, setForceClose] = React.useState(false);
  const [forceCloseCheck, setForceCloseCheck] = React.useState('');

  const isConsumer = user?.consumer ?? false;
  const price = total_price ?? 0;
  const [newPrice, setNewPrice] = React.useState(0);
  const displayNewPrice = parseFloat((newPrice / 100).toFixed(2));

  function convertNewPrice(value: number) {
    const amountInCents = Math.round(value * 100);
    setNewPrice(amountInCents);
  }

  return (
    <Page
      name="reservation"
      loading={isFetchingReservation || reservationData === undefined}
      actions={[
        {
          svgName: 'lock',
          title: translations.lockVehicle,
          mode: 'closeOnClick',
          onClick: () => {
            setOpenLockVehicle(true);
          },
          disabled: !['upcoming', 'active', 'started', 'ended'].includes(status ?? ''),
        },
        {
          svgName: 'lockOpen',
          title: translations.unlockVehicle,
          mode: 'closeOnClick',
          onClick: () => {
            setOpenUnlockVehicle(true);
          },
          disabled: !['upcoming', 'active', 'started', 'ended'].includes(status ?? ''),
        },
        {
          svgName: 'close',
          title: translations.closeReservation,
          mode: 'closeOnClick',
          onClick: () => {
            setOpenCloseReservation(true);
          },
          disabled: !['active', 'started'].includes(status ?? ''),
        },
        {
          svgName: 'cancel',
          title: translations.cancelReservation,
          mode: 'closeOnClick',
          onClick: () => {
            setOpenCancelReservation(true);
          },
          disabled: !['future', 'upcoming'].includes(status ?? ''),
        },
        {
          svgName: 'attachMoney',
          title: translations.invoiceReservation,
          mode: 'closeOnClick',
          onClick: () => {
            setOpenInvoiceReservation(true);
          },
          disabled: !!finalized_on || !!invoice_id || !isConsumer,
        },
      ]}
    >
      <Dialog
        title={translations.lockVehicleDialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenLockVehicle(false);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                await mutateAsyncLockVehicle({ reservationId: id });
                setOpenLockVehicle(false);
                addToast({ title: translations.lockVehicleSuccess, severity: 'success' });
              } catch (error) {
                setOpenLockVehicle(false);
              }
            },
            loading: isLoadingLockVehicle,
          },
        ]}
        open={openLockVehicle}
        onChange={setOpenLockVehicle}
      >
        <Text>{translations.lockVehicleDialogMessage}</Text>
      </Dialog>
      <Dialog
        title={translations.unlockVehicleDialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenUnlockVehicle(false);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                await mutateAsyncUnlockVehicle({ reservationId: id });
                setOpenUnlockVehicle(false);
                addToast({ title: translations.unlockVehicleSucces, severity: 'success' });
              } catch (error) {
                setOpenUnlockVehicle(false);
              }
            },
            loading: isLoadingUnlockVehicle,
          },
        ]}
        open={openUnlockVehicle}
        onChange={setOpenUnlockVehicle}
      >
        <Text>{translations.unlockVehicleDialogMessage}</Text>
      </Dialog>
      <Dialog
        title={translations.closeReservationDialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenCloseReservation(false);
              setForceClose(false);
              setForceCloseCheck('');
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                await mutateAsyncCloseReservation({ reservationId: id, force: forceClose });
                setOpenCloseReservation(false);
                addToast({
                  title: translations.closeReservationSuccess,
                  severity: 'success',
                });
              } catch (error) {
                setOpenCloseReservation(false);
                setForceClose(false);
                setForceCloseCheck('');
              }
            },
            loading: isLoadingCloseReservation,
            disabled: forceClose && forceCloseCheck !== reservationData?.result.user?.email,
          },
        ]}
        open={openCloseReservation}
        onChange={(value) => {
          setOpenCloseReservation(value);
          setForceClose(false);
          setForceCloseCheck('');
        }}
      >
        <>
          <Text>
            {replaceText({
              text: translations.closeReservationDialogMessage,
              replaceValues: [reservationData?.result.user?.full_name ?? ''],
            })}
          </Text>
          <Box sx={{ paddingTop: 1 }}>
            <Switch
              label={translations.forceClose}
              checked={forceClose}
              onChange={setForceClose}
              fullWidth
            />
          </Box>
          {forceClose && (
            <Box sx={{ paddingTop: 1 }}>
              <Text.Bold>{translations.forceCloseWarning}</Text.Bold>
              <Box sx={{ height: theme.spacing(1) }} />
              <Text>{translations.forceClosePleaseEnter}</Text>
              <Box sx={{ height: theme.spacing(2) }} />
              <TextField
                label={translations.email}
                variant="outlined"
                placeholder={translations.email}
                value={forceCloseCheck}
                onChange={setForceCloseCheck}
                fullWidth={true}
                required={true}
                type="email"
              />
            </Box>
          )}
        </>
      </Dialog>
      <Dialog
        title={translations.cancelReservationDialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenCancelReservation(false);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                await mutateAsyncCancelReservation({ reservationId: id });
                setOpenCancelReservation(false);
                addToast({ title: translations.cancelReservationSuccess, severity: 'success' });
              } catch (error) {
                setOpenCancelReservation(false);
              }
            },
            loading: isLoadingCancelReservation,
          },
        ]}
        open={openCancelReservation}
        onChange={setOpenCancelReservation}
      >
        <Text>{translations.cancelReservationDialogMessage}</Text>
      </Dialog>
      <Dialog
        title={translations.invoiceReservationDialogTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpenInvoiceReservation(false);
              setNewPrice(0);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              if (newPrice !== price) {
                try {
                  await mutateAsyncInvoiceReservation({ id, amount: newPrice });
                  setOpenInvoiceReservation(false);
                  setNewPrice(0);
                  addToast({ title: translations.invoiceReservationSuccess, severity: 'success' });
                  refetchReservation();
                } catch (error) {
                  setOpenInvoiceReservation(false);
                  setNewPrice(0);
                }
              }
            },
            loading: isLoadingInvoiceReservation,
            disabled: !newPrice || newPrice === price,
          },
        ]}
        open={openInvoiceReservation}
        onChange={setOpenInvoiceReservation}
      >
        <Box sx={{ marginBottom: 1 }} />
        <TextField
          placeholder="0"
          label={translations.newPrice}
          type="number"
          value={displayNewPrice}
          onChange={(value) => convertNewPrice(value)}
          adornment="EUR"
          variant="outlined"
        />
      </Dialog>
      <InformationBlock
        title={`${user?.full_name} (${user?.email})`}
        link={{ to: 'driverReservations', value: user?.id ?? '' }}
        items={[
          {
            type: 'label',
            label: translations.status,
            data: {
              value: status ?? '',
              color: getLabelColor({ reservationStatus: status }),
            },
          },
          {
            type: 'label',
            label: translations.provider,
            data: {
              value: provider ?? '',
              color: getLabelColor({ provider }),
            },
          },
          {
            type: 'label',
            label: translations.type,
            data: {
              value: type ?? '',
              color: getLabelColor({ reservationType: type }),
            },
          },
          {
            type: 'dateLong',
            label: translations.createdOn,
            data: {
              value: created_on ?? '',
            },
          },
          {
            type: 'dateLong',
            label: translations.startDate,
            data: {
              value: start_date ?? '',
            },
          },
          {
            type: 'dateLong',
            label: translations.endDate,
            data: {
              value: end_date ?? '',
            },
          },
          {
            type: 'dateLong',
            label: translations.startedOn,
            data: {
              value: started_on ?? '',
            },
          },
          {
            type: 'dateLong',
            label: translations.closedOn,
            data: {
              value: closed_on ?? '',
            },
          },
          {
            type: 'dateLong',
            label: translations.cancelledOn,
            data: {
              value: cancelled_on ?? '',
            },
          },
          {
            type: 'text',
            label: translations.organisation,
            data: {
              value: user?.organisation?.name ?? '-',
            },
          },
          {
            type: 'price',
            label: translations.price,
            data: {
              value: price,
              adornment: 'EUR',
            },
          },
          {
            type: 'text',
            label: translations.identifier,
            data: {
              value: id ?? '-',
            },
          },
          {
            type: 'text',
            label: translations.externalIdentifier,
            data: {
              value: external_id ?? '-',
            },
          },
          {
            type: 'text',
            label: translations.invoiceIdentifier,
            data: {
              value: invoice_id ?? '-',
            },
          },
          {
            type: 'text',
            label: translations.finalizedOn,
            data: {
              value: finalized_on ?? '-',
            },
          },
          {
            type: 'text',
            label: translations.licensePlate,
            data: {
              value: vehicle?.license_plate ?? '-',
            },
          },
        ]}
      />
    </Page>
  );
}

export { ReservationPage };
