import { Unstable_Grid2 as Grid } from '@mui/material';
import dayjs from 'dayjs';

import { Container, LineChart } from 'components';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

export function UserStatistics() {
  const { data: userStatisticsData } = UseQuery.userStatistics();
  const translations = useTranslations('statisticsPage');

  return (
    <Grid container>
      <Container title={translations.users} width="flex" loading={userStatisticsData === undefined}>
        <LineChart
          labels={Object.keys(userStatisticsData?.result ?? []).map((key) =>
            dayjs(key).format('DD/MM'),
          )}
          data={[
            {
              label: translations.consumers,
              values: Object.keys(userStatisticsData?.result ?? [])
                .map((key) => userStatisticsData?.result[key]?.consumers)
                .filter((item) => typeof item === 'number') as number[],
            },
            {
              label: translations.employees,
              values: Object.keys(userStatisticsData?.result ?? [])
                .map((key) => userStatisticsData?.result[key]?.employees)
                .filter((item) => typeof item === 'number') as number[],
            },
          ]}
          colors={['success.main', 'info.main']}
        />
      </Container>
    </Grid>
  );
}
