import { Box, Unstable_Grid2 as Grid } from '@mui/material';

import { Container, DoughnutChart, StackedBarChart, Table } from 'components';
import { formatDate } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { customColors } from 'config/customColors';
import { UseQuery } from 'useQuery';

export function ReservationStatistics() {
  const { data: reservationStatisticsData } = UseQuery.reservationStatistics();
  const translations = useTranslations('statisticsPage');

  const { hasMobileView } = useHasMobileView();

  return (
    <>
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Container
          title={translations.completedReservations}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <>
            <StackedBarChart
              labels={[
                ...(reservationStatisticsData?.completed.by_month ?? []).map(
                  (item) => Object.keys(item)[0],
                ),
                translations.forecasted,
              ]}
              data={[
                {
                  label: translations.vecore,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['VECORE'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.check,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['CHECK'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.felyx,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['FELYX'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.mywheels,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['MYWHEELS'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.greenwheels,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['GREENWHEELS'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.forecasted,
                  values: [
                    ...(reservationStatisticsData?.completed.by_month ?? []).map((_) => 0),
                    reservationStatisticsData?.completed.forecasted ?? 0,
                  ],
                },
              ]}
              colors={[
                customColors.vecore,
                customColors.check,
                customColors.felyx,
                customColors.mywheels,
                customColors.greenwheels,
                'info.main',
              ]}
            />
            <Box sx={{ width: '100%' }}>
              {hasMobileView ? (
                <Table
                  columns={{
                    name: {
                      name: translations.name,
                      type: 'text',
                      flex: 1,
                    },
                    value: {
                      name: translations.value,
                      type: 'text',
                      width: 100,
                    },
                  }}
                  rows={[
                    {
                      id: 'annual',
                      name: translations.annualGrowthRate,
                      value: (reservationStatisticsData?.completed?.compound_growth_rate ?? 0)
                        .toFixed(2)
                        .toString(),
                    },
                    {
                      id: 'monthly',
                      name: translations.monthlyGrowthRate,
                      value: (reservationStatisticsData?.completed?.monthly_growth ?? 0)
                        .toFixed(2)
                        .toString(),
                    },
                    {
                      id: 'total',
                      name: translations.total,
                      value: (reservationStatisticsData?.completed?.total ?? 0).toString(),
                    },
                  ]}
                  loading={!reservationStatisticsData?.completed}
                  useMobileView={false}
                />
              ) : (
                <Table
                  columns={{
                    annualGrowthRate: {
                      name: translations.annualGrowthRate,
                      type: 'text',
                      flex: 1,
                    },
                    monthlyGrowthRate: {
                      name: translations.monthlyGrowthRate,
                      type: 'text',
                      flex: 1,
                    },
                    total: { name: translations.total, type: 'text', width: 100 },
                  }}
                  rows={[
                    {
                      id: 'cancelled',
                      annualGrowthRate: (
                        reservationStatisticsData?.completed?.compound_growth_rate ?? 0
                      )
                        .toFixed(2)
                        .toString(),
                      monthlyGrowthRate: (reservationStatisticsData?.completed.monthly_growth ?? 0)
                        .toFixed(2)
                        .toString(),
                      total: reservationStatisticsData?.completed?.total?.toString() ?? '',
                    },
                  ]}
                  loading={!reservationStatisticsData?.completed}
                  useMobileView={false}
                />
              )}
            </Box>
          </>
        </Container>
        <Container
          title={translations.cancelledReservations}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <>
            <StackedBarChart
              labels={[
                ...(reservationStatisticsData?.cancelled.by_month ?? []).map(
                  (item) => Object.keys(item)[0],
                ),
                translations.forecasted,
              ]}
              data={[
                {
                  label: translations.vecore,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['VECORE'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.check,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['CHECK'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.felyx,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['FELYX'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.mywheels,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['MYWHEELS'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.greenwheels,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((item) => {
                      const key = Object.keys(item)[0];

                      return item[key] ? item[key]['GREENWHEELS'] ?? 0 : 0;
                    }),
                    0,
                  ],
                },
                {
                  label: translations.forecasted,
                  values: [
                    ...(reservationStatisticsData?.cancelled.by_month ?? []).map((_) => 0),
                    reservationStatisticsData?.cancelled.forecasted ?? 0,
                  ],
                },
              ]}
              colors={[
                customColors.vecore,
                customColors.check,
                customColors.felyx,
                customColors.mywheels,
                customColors.greenwheels,
                'info.main',
              ]}
            />
            <Box sx={{ width: '100%' }}>
              {hasMobileView ? (
                <Table
                  columns={{
                    name: {
                      name: translations.name,
                      type: 'text',
                      flex: 1,
                    },
                    value: {
                      name: translations.value,
                      type: 'text',
                      width: 100,
                    },
                  }}
                  rows={[
                    {
                      id: 'annual',
                      name: translations.annualGrowthRate,
                      value: (reservationStatisticsData?.cancelled?.compound_growth_rate ?? 0)
                        .toFixed(2)
                        .toString(),
                    },
                    {
                      id: 'monthly',
                      name: translations.monthlyGrowthRate,
                      value: (reservationStatisticsData?.cancelled?.monthly_growth ?? 0)
                        .toFixed(2)
                        .toString(),
                    },
                    {
                      id: 'total',
                      name: translations.total,
                      value: (reservationStatisticsData?.cancelled?.total ?? 0)
                        .toFixed(2)
                        .toString(),
                    },
                  ]}
                  loading={!reservationStatisticsData?.cancelled}
                  useMobileView={false}
                />
              ) : (
                <Table
                  columns={{
                    annualGrowthRate: {
                      name: translations.annualGrowthRate,
                      type: 'text',
                      flex: 1,
                    },
                    monthlyGrowthRate: {
                      name: translations.monthlyGrowthRate,
                      type: 'text',
                      flex: 1,
                    },
                    total: { name: translations.total, type: 'text', width: 100 },
                  }}
                  rows={[
                    {
                      id: 'cancelled',
                      annualGrowthRate: (
                        reservationStatisticsData?.cancelled?.compound_growth_rate ?? 0
                      )
                        .toFixed(2)
                        .toString(),
                      monthlyGrowthRate: (reservationStatisticsData?.cancelled.monthly_growth ?? 0)
                        .toFixed(2)
                        .toString(),
                      total: reservationStatisticsData?.cancelled?.total?.toString() ?? '',
                    },
                  ]}
                  loading={!reservationStatisticsData?.cancelled}
                  useMobileView={false}
                />
              )}
            </Box>
          </>
        </Container>

        <Container
          title={translations.completedVsCancelled}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <DoughnutChart
            data={[
              {
                label: translations.completed,
                value: reservationStatisticsData?.completed.total ?? 0,
              },
              {
                label: translations.cancelled,
                value: reservationStatisticsData?.cancelled?.total ?? 0,
              },
            ]}
            colors={['success.main', 'error.main']}
          />
        </Container>
        <Container
          title={translations.reservationTypes}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <DoughnutChart
            data={[
              {
                label: translations.business,
                value: reservationStatisticsData?.reservation_types.Business ?? 0,
              },
              {
                label: translations.consumers,
                value: reservationStatisticsData?.reservation_types.Consumer ?? 0,
              },
              {
                label: translations.private,
                value: reservationStatisticsData?.reservation_types.Private ?? 0,
              },
            ]}
            colors={['common.black', 'info.main', 'gray']}
          />
        </Container>
        <Container
          title={translations.top10Days}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <Box sx={{ width: '100%' }}>
            <Table
              columns={{
                data: { name: translations.date, type: 'text', flex: 1 },
                amount: { name: translations.amount, type: 'text', width: 100 },
              }}
              rows={(reservationStatisticsData?.top_days ?? []).map(({ day, amount }, index) => ({
                id: index.toString(),
                data: formatDate({ date: day, variant: 'dayMonthYearExtra' }),
                amount: amount.toString(),
              }))}
              loading={reservationStatisticsData === undefined}
              useMobileView={false}
            />
          </Box>
        </Container>
        <Container
          title={translations.bottom10Days}
          width="half"
          loading={reservationStatisticsData === undefined}
        >
          <Box sx={{ width: '100%' }}>
            <Table
              columns={{
                data: { name: translations.date, type: 'text', flex: 1 },
                amount: { name: translations.amount, type: 'text', width: 100 },
              }}
              rows={(reservationStatisticsData?.bottom_days ?? []).map(
                ({ day, amount }, index) => ({
                  id: index.toString(),
                  data: formatDate({ date: day, variant: 'dayMonthYearExtra' }),
                  amount: amount.toString(),
                }),
              )}
              loading={reservationStatisticsData === undefined}
              useMobileView={false}
            />
          </Box>
        </Container>
      </Grid>
    </>
  );
}
