// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';
import validator from 'validator';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface VAT {
  id: string;
  name: string;
  percentage: number;
}

interface Product {
  id: string;
  net_unit_price: number;
  gross_unit_price: number;
  name: string;
  vat: VAT;
  vat_amount: number;
  vat_id: string;
}

interface UseProductsProps {
  page: number;
  limit?: number;
}

function useProducts({ page, limit }: UseProductsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Product>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/products',
      token,
      params: {
        page,
        limit: hasMobileView && limit === undefined ? 15 : limit,
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['products', { page, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

interface UseProductProps {
  id: string;
}

function useProduct({ id }: UseProductProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<Product>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/products/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['product', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token && validator.isUUID(id),
    retry: 0,
    select: ({ data }) => data,
  });
}

function useVAT() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<VAT>[];
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: '/vat',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['vat'], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

function useAddProduct() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface AddVoucherProps {
    name: Product['name'];
    priceExlVAT: Product['net_unit_price'];
    VAT: Product['vat']['id'];
  }

  interface Body {
    name: string;
    net_unit_price: number;
    vat_id: string;
  }

  async function addProduct({ name, priceExlVAT, VAT }: AddVoucherProps) {
    const { data } = await API.post<void, Body>({
      path: `/products`,
      token,
      version: 1,
      body: {
        name,
        net_unit_price: priceExlVAT,
        vat_id: VAT,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, AddVoucherProps>((data: AddVoucherProps) => addProduct(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['products']);
    },
  });
}

function useEditProduct() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface AddVoucherProps {
    id: Product['id'];
    name: Product['name'];
    priceExlVAT: Product['net_unit_price'];
    VAT: Product['vat']['id'];
  }

  interface Body {
    name: string;
    net_unit_price: number;
    vat_id: string;
  }

  async function addProduct({ id, name, priceExlVAT, VAT }: AddVoucherProps) {
    const { data } = await API.put<void, Body>({
      path: `/products/${id}`,
      token,
      version: 1,
      body: {
        name,
        net_unit_price: priceExlVAT,
        vat_id: VAT,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, AddVoucherProps>((data: AddVoucherProps) => addProduct(data), {
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries(['products']);
      await queryClient.invalidateQueries(['product', { id }]);
    },
  });
}

export { type Product, useAddProduct, useEditProduct, useProduct, useProducts, useVAT };
