// eslint-disable-next-line no-restricted-imports
import { Dialog as DialogMUI, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { CustomString } from 'config/translations';

import { Button, ButtonProps } from './Button';

interface DialogProps {
  title: CustomString;
  children?: JSX.Element | JSX.Element[];
  footer: ButtonProps[];
  open: boolean;
  onChange: (open: boolean) => void;
}

function Dialog({ title, children, footer, open, onChange }: DialogProps) {
  return (
    <DialogMUI
      fullScreen={false}
      open={open}
      onClose={() => {
        onChange(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions sx={{ paddingBottom: 2, paddingRight: 2 }}>
        {footer.map((action, index) => (
          <Button key={`${action.label}${index}`} {...action} />
        ))}
      </DialogActions>
    </DialogMUI>
  );
}

export { Dialog };
