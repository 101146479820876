// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface UseUpdateTravelCardStatusProps {
  invalidateQueries: boolean;
  driverId: string;
}

interface TravelCard {
  bin: string;
  brand: string;
  created_on: string;
  expires_on: string;
  masked_number: string;
  status: TravelCardStatus;
  id: string;
  payment_instrument_id: string;
  user: {
    email: string;
    full_name: string;
    organisation: { name: string };
  };
}

type TravelCardStatus = 'active' | 'closed' | 'suspended';

interface UseTravelCardsProps {
  page: number;
  limit?: number;
}

function useTravelCards({ page, limit }: UseTravelCardsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<TravelCard>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/admin/travel-cards',
      token,
      params: {
        page,
        limit: hasMobileView && limit === undefined ? 15 : limit,
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['travelCards', { page, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useUpdateTravelCardStatus({
  invalidateQueries,
  driverId,
}: UseUpdateTravelCardStatusProps) {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface UpdateTravelCardStatusProps {
    id: string;
    status: TravelCardStatus;
  }

  async function updateTravelCardStatus({ id, status }: UpdateTravelCardStatusProps) {
    interface Body {
      status: TravelCardStatus;
    }

    const { data } = await API.put<void, Body>({
      path: `/admin/travel-cards/${id}/status`,
      token,
      version: 1,
      body: {
        status,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UpdateTravelCardStatusProps>(
    (data: UpdateTravelCardStatusProps) => updateTravelCardStatus(data),
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          await queryClient.invalidateQueries(['driver', { id: driverId }]);
        }
      },
    },
  );
}

export { type TravelCard, type TravelCardStatus, useTravelCards, useUpdateTravelCardStatus };
