import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { useCognitoUser } from 'core/cognito';
import { useDarkMode } from 'core/darkMode/hooks';
import { useNavigate } from 'hooks/useNavigate';

import { CustomRoutes, customRoutes } from 'config/route';
import { getTheme } from 'config/theme';
import { NotFoundPage } from 'pages';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

function App() {
  const { navigate } = useNavigate();
  const user = useCognitoUser();

  const { pathname } = useLocation();

  React.useEffect(() => {
    // TODO remove pathname !== '/privacy' after proper fix
    if (user.authenticated === false && !user.loading && pathname !== '/privacy') {
      navigate({ name: 'login', id: undefined, searchParams: undefined, metaKey: false });
    }
  }, [user]);

  const { mode } = useDarkMode();

  const theme = React.useMemo(() => createTheme(getTheme(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* NOTE: Do not manually add Routes here! */}
        {Object.keys(customRoutes).map((routeName) => {
          const {
            title,
            path,
            hasId,
            element: Element,
          } = customRoutes[routeName as keyof CustomRoutes];

          return <Route path={`${path}${hasId ? '/:id' : ''}`} element={<Element />} key={title} />;
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

// We cannot change this default import
// eslint-disable-next-line import/no-default-export
export default App;
