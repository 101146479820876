import React from 'react';

import { InformationBlock, Page } from 'components';
import { useDarkMode } from 'core/darkMode/hooks';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

function ConfigurationPage() {
  const translations = useTranslations('configurationPage');

  const { data: configurationData, isFetching } = UseQuery.configuration();
  const { mutateAsync: mutateAsyncEditConfiguration, isLoading: isLoadingEditConfiguration } =
    UseMutation.editConfiguration();

  const [baseSignupEnabled, setBaseSignupEnabled] = React.useState<boolean>();
  const [signupEnabled, setSignupEnabled] = React.useState<boolean>();

  const { mode, setMode } = useDarkMode();

  React.useEffect(() => {
    const localSignupEnabled = configurationData?.result?.settings?.signup_enabled;

    if (localSignupEnabled === undefined) {
      return;
    }

    setBaseSignupEnabled((prevState) => {
      if (prevState !== undefined) {
        return prevState;
      }

      return localSignupEnabled;
    });

    setSignupEnabled((prevState) => {
      if (prevState !== undefined) {
        return prevState;
      }

      return localSignupEnabled;
    });
  }, [configurationData]);

  return (
    <Page
      name="configuration"
      loading={isFetching || signupEnabled === undefined}
      footer={[
        {
          label: translations.restore,
          variant: 'outlined',
          onClick: () => {
            setSignupEnabled(baseSignupEnabled);
          },
          disabled: baseSignupEnabled === undefined || baseSignupEnabled === signupEnabled,
        },
        {
          label: translations.update,
          onClick: () => {
            mutateAsyncEditConfiguration({
              settings: {
                signup_enabled: !!signupEnabled,
              },
            }).then(({ result }) => {
              setBaseSignupEnabled(!!result?.settings?.signup_enabled);
              setSignupEnabled(!!result?.settings?.signup_enabled);
            });
          },
          disabled: baseSignupEnabled === undefined || baseSignupEnabled === signupEnabled,
          loading: isLoadingEditConfiguration,
        },
      ]}
    >
      <InformationBlock
        items={[
          {
            label: translations.status,
            data: {
              value: !!signupEnabled,
              onChange: (content) => {
                setSignupEnabled(content);
              },
            },
            type: 'switch',
          },
          {
            label: translations.darkMode,
            data: {
              value: mode === 'dark' ? true : false,
              onChange: (content) => {
                setMode(content ? 'dark' : 'light');
              },
            },
            type: 'switch',
          },
        ]}
      />
    </Page>
  );
}

export { ConfigurationPage };
