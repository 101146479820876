import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Box,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  List,
  // eslint-disable-next-line no-restricted-imports
  ListItem,
  // eslint-disable-next-line no-restricted-imports
  ListItemButton,
  // eslint-disable-next-line no-restricted-imports
  ListItemText,
  // eslint-disable-next-line no-restricted-imports
  Popover,
  useTheme,
} from '@mui/material';

import { useDarkMode } from 'core/darkMode/hooks';

import { CustomString } from 'config/translations';

import { getSVG, SVG, SVGName } from './SVG';

interface ButtonBase {
  label: CustomString;
  size?: Extract<LoadingButtonProps['size'], 'small' | 'medium'>;
  variant?: Extract<LoadingButtonProps['variant'], 'outlined' | 'contained'>;
  disabled?: boolean;
}

type ButtonProps =
  | (ButtonBase & {
      loading?: boolean;
      svgName?: SVGName;
      onClick: () => void;
      onFile?: undefined;
      actions?: undefined;
    })
  | (ButtonBase & {
      loading?: undefined;
      svgName?: undefined;
      onClick?: undefined;
      onFile?: undefined;
      actions: ButtonAction[];
    })
  | (ButtonBase & {
      loading?: undefined;
      svgName?: SVGName;
      onClick?: undefined;
      onFile: (file: File) => void;
      actions?: undefined;
    });

type ButtonAction =
  | {
      svgName: SVGName;
      mode: 'closeAfterLoading' | 'closeOnClick' | 'leaveOpen';
      title: CustomString;
      onClick: () => void;
      onFile?: undefined;
      loading?: boolean;
      disabled?: boolean;
      extension?: string;
    }
  | {
      svgName: SVGName;
      mode: 'fileUpload';
      title: CustomString;
      onClick?: undefined;
      onFile: (file: File) => void;
      loading?: undefined;
      disabled?: boolean;
      extension?: string;
    };

interface ButtonActions {
  actions?: ButtonAction[];
}

type ButtonPropsWithoutSize = Omit<ButtonProps, 'size'> & { size?: undefined };

function Button({
  size = 'medium',
  variant = 'contained',
  loading,
  disabled,
  svgName,
  onClick,
  onFile,
  label,
  actions,
}: ButtonProps): JSX.Element {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const [hasActions, setHasActions] = React.useState((actions ?? []).length > 0);

  const previousActionLoading = React.useRef<'waiting' | number>(-1);
  const currentActionLoading = React.useRef<'waiting' | number>(-1);

  React.useEffect(() => {
    setHasActions((actions ?? []).length > 0);

    if (currentActionLoading.current === -1 || !actions) {
      return;
    }

    if (
      currentActionLoading.current !== 'waiting' &&
      currentActionLoading.current !== -1 &&
      actions[currentActionLoading.current] &&
      actions[currentActionLoading.current].loading
    ) {
      previousActionLoading.current = currentActionLoading.current;
      currentActionLoading.current = 'waiting';

      return;
    }

    if (
      previousActionLoading.current !== 'waiting' &&
      previousActionLoading.current !== -1 &&
      currentActionLoading.current === 'waiting' &&
      actions[previousActionLoading.current] &&
      !actions[previousActionLoading.current].loading
    ) {
      setAnchorEl(null);
      previousActionLoading.current = -1;
      currentActionLoading.current = -1;
      return;
    }
  }, [actions]);

  let LocalSVG: ReturnType<typeof getSVG> | undefined = undefined;

  if (svgName) {
    LocalSVG = getSVG(svgName);
  }

  return (
    <Box>
      <LoadingButton
        aria-describedby={label + size}
        size={size}
        variant={hasActions ? 'contained' : variant}
        color={hasActions ? 'info' : variant === 'contained' ? 'primary' : 'secondary'}
        loading={loading}
        disabled={disabled}
        onClick={hasActions ? handleClick : onClick}
        startIcon={
          LocalSVG ? (
            <LocalSVG
              color={disabled ? 'gray' : variant === 'contained' ? 'common.black' : 'primary.main'}
              size="small"
            />
          ) : undefined
        }
      >
        {onFile && (
          <Box>
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              {/* eslint-disable-next-line react/forbid-elements */}
              <input
                id="upload-csv"
                hidden
                style={{ display: 'flex', height: '100%', width: '100%', opacity: 0 }}
                accept=".csv"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files.length > 0) {
                    onFile(event.target.files[0]);
                  }
                }}
              />
            </Box>
            {label}
          </Box>
        )}
        {onFile === undefined && (
          <Box>
            {hasActions ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {label}
                <Box sx={{ width: theme.spacing(0.5) }} />
                {!!anchorEl && <SVG.KeyboardArrowUp color="common.white" size="small" />}
                {!anchorEl && <SVG.KeyboardArrowDown color="common.white" size="small" />}
              </Box>
            ) : (
              label
            )}
          </Box>
        )}
      </LoadingButton>
      <Popover
        sx={{ marginTop: 1 }}
        id={label + size}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <List>
          {(actions ?? []).map(
            (
              { extension, title, mode: localMode, svgName, onClick, onFile, loading, disabled },
              index,
            ) => {
              const SVG = getSVG(svgName);

              return (
                <ListItem
                  key={index}
                  dense
                  disablePadding
                  onClick={
                    disabled || loading || localMode === 'fileUpload'
                      ? () => {}
                      : () => {
                          if (localMode === 'closeOnClick') {
                            setAnchorEl(null);
                            onClick();
                            return;
                          }

                          if (localMode === 'closeAfterLoading') {
                            currentActionLoading.current = index;
                            onClick();
                            return;
                          }

                          onClick();
                        }
                  }
                >
                  <ListItemButton dense disabled={disabled}>
                    {localMode === 'fileUpload' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <SVG size="small" color={mode === 'dark' ? 'info.light' : 'info.main'} />
                        <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                          {/* eslint-disable-next-line react/forbid-elements */}
                          <input
                            id="upload-csv"
                            hidden
                            style={{ display: 'flex', height: '100%', width: '100%', opacity: 0 }}
                            accept={extension || '.csv'}
                            type="file"
                            onChange={(event) => {
                              setAnchorEl(null);
                              if (event.target.files && event.target.files.length > 0) {
                                onFile(event.target.files[0]);
                              }
                            }}
                          />
                        </Box>
                        <Box sx={{ width: theme.spacing(1) }} />
                        <ListItemText>{title}</ListItemText>
                      </Box>
                    )}
                    {localMode !== 'fileUpload' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {!loading && (
                          <SVG size="small" color={mode === 'dark' ? 'info.light' : 'info.main'} />
                        )}
                        {!!loading && <CircularProgress size={theme.spacing(3)} color="info" />}
                      </Box>
                    )}

                    {localMode !== 'fileUpload' && <Box sx={{ width: theme.spacing(1) }} />}
                    {localMode !== 'fileUpload' && <ListItemText>{title}</ListItemText>}
                  </ListItemButton>
                </ListItem>
              );
            },
          )}
        </List>
      </Popover>
    </Box>
  );
}

export { Button, type ButtonActions, type ButtonProps, type ButtonPropsWithoutSize };
