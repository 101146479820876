// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-syntax */
/* ONLY provide colors in following formats:
 - rgb(x,x,x)
 - #XXXXXX
 */

const customColors = {
  check: 'rgb(167,66,255)',
  felyx: 'rgb(6,81,41)',
  vecore: 'rgb(255,176,2)',
  greenwheels: 'rgb(49,170,82)',
  adyen: 'rgb(178,0,0)',
  ns: 'rgb(0,2,92)',
  mrr: 'rgb(93,2,2)',
  mywheels: 'rgb(27,112,222)',
  amber: 'rgb(226,122,18)',
} as const;

type CustomColor = (typeof customColors)[keyof typeof customColors];

export { type CustomColor, customColors };
