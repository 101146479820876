import React from 'react';

import { RoutePaths } from 'config/route';

interface UseDelayedInputProps {
  input: string;
  onChange: (value: string) => void;
  path: RoutePaths;
}

function useDelayedInput({ input, onChange, path }: UseDelayedInputProps) {
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [result, setResult] = React.useState('');

  React.useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!window.location.href.includes(path)) {
        return;
      }

      onChange(input);
      setResult(input.toString());
    }, 1000);
  }, [input]);

  return result;
}

export { useDelayedInput };
