// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { formatDate } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { Driver } from './useDrivers';
import { TravelCard } from './useTravelCard';
import { Voucher } from './useVouchers';

type Provider = 'Check' | 'Felyx' | 'Vecore' | 'Greenwheels' | 'MyWheels' | 'Amber' | 'NS';

const Fuel = {
  ELECTRIC: 'ELECTRIC',
  GASOLINE: 'GASOLINE',
  HYBRID: 'HYBRID',
  DIESEL: 'DIESEL',
  UNKNOWN: 'UNKNOWN',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

const Transportation = {
  CAR: 'CAR',
  MOPED: 'MOPED',
  MOTORCYCLE: 'MOTORCYCLE',
  ACTIVE_MOBILITY: 'ACTIVE_MOBILITY',
  PUBLIC_TRANSPORT: 'PUBLIC_TRANSPORT',
  UNKNOWN: 'UNKNOWN',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

interface Reservation {
  id: string;
  external_id: string | undefined;
  cancelled_on: string | null;
  closed_on: string | null;
  created_on: string | null;
  started_on: string | null;
  end_date: string | null;
  end_mileage: unknown;
  note: string | null;
  provider: Provider;
  start_date: string | null;
  start_mileage: unknown;
  status: 'future' | 'upcoming' | 'active' | 'started' | 'ended' | 'past' | 'cancelled';
  timing: {
    paused: number;
    driver: number;
  };
  total_mileage: number;
  total_price: number | null;
  promotional_code: string;
  trip: unknown;
  type: 'BUSINESS' | 'PRIVATE';
  user: {
    id: string;
    consumer: boolean;
    created_on: Driver['created_on'];
    email: Driver['email'];
    first_name: Driver['first_name'];
    full_name: string;
    gender: Driver['gender'];
    last_name: Driver['last_name'];
    organisation: Driver['organisation'];
    personal_info: Driver['personal_info'];
    phone_number: Driver['phone_number'];
    reference: Driver['reference'];
    status: Driver['status'];
    support: unknown;
    travelCard: TravelCard;
  };
  vehicle: {
    brand: string | null;
    fuel_card_code: string | null;
    fuel_level: number | null;
    fuel_range: number | null;
    license_plate: string;
    model: string | null;
    vehicle_status: string;
    pricing: unknown;
    pricing_webpage: string | null;
  };
  vehicleStatus: string;
  voucher: Voucher;
  finalized_on: string | null;
  invoice_id: string | null;
}

interface UseReservationsProps {
  page: number;
  startDate: string;
  endDate: string;
  userId?: string;
  organisation?: string;
  provider: Reservation['provider'] | undefined;
  status: Reservation['status'][];
  type: Reservation['type'] | undefined;
  finalized: string | undefined;
  limit?: number;
}

function useReservations({
  page,
  startDate,
  endDate,
  userId,
  organisation,
  provider,
  status,
  type,
  finalized,
  limit,
}: UseReservationsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Reservation>[];
    meta: Meta;
  }

  function fetchData() {
    interface Params {
      page: number;
      start_date: string | undefined;
      end_date: string | undefined;
      user_id: string | undefined;
      limit: number | undefined;
      organisation: string | undefined;
      vehicle_provider: string | undefined;
      status: string | undefined;
      type: string | undefined;
      finalized: string | undefined;
    }

    return API.get<Response, Params, false>({
      path: '/reservations',
      token,
      params: {
        page,
        start_date: startDate ? startDate : undefined,
        end_date: endDate ? endDate : undefined,
        user_id: userId,
        limit: hasMobileView && limit === undefined ? 15 : limit,
        organisation,
        vehicle_provider: provider ?? undefined,
        status: status.length > 0 ? status?.join(',') : undefined,
        type: type ? type : undefined,
        finalized: finalized === 'true' || finalized === 'false' ? finalized : undefined,
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    [
      'reservations',
      {
        page,
        startDate,
        endDate,
        userId,
        hasMobileView,
        organisation,
        provider,
        status,
        type,
        limit,
        finalized,
      },
    ],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && (userId === undefined || !!userId),
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

interface UseReservationProps {
  reservationId: string;
}

function useReservation({ reservationId }: UseReservationProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<Reservation>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/reservations/${reservationId}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['reservation', { reservationId }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useLockVehicle() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface LockVehicleProps {
    reservationId: string;
  }

  async function lockVehicle({ reservationId }: LockVehicleProps) {
    const { data } = await API.put<void>({
      path: `/admin/reservations/${reservationId}/lock`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, LockVehicleProps>(
    (data: LockVehicleProps) => lockVehicle(data),
    {
      onSuccess: async (_, { reservationId }) => {
        await queryClient.invalidateQueries(['reservations']);
        await queryClient.invalidateQueries(['reservation', { reservationId }]);
      },
    },
  );
}

function useUnlockVehicle() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface UnlockVehicleProps {
    reservationId: string;
  }

  async function unlockVehicle({ reservationId }: UnlockVehicleProps) {
    const { data } = await API.put<void>({
      path: `/admin/reservations/${reservationId}/unlock`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UnlockVehicleProps>(
    (data: UnlockVehicleProps) => unlockVehicle(data),
    {
      onSuccess: async (_, { reservationId }) => {
        await queryClient.invalidateQueries(['reservations']);
        await queryClient.invalidateQueries(['reservation', { reservationId }]);
      },
    },
  );
}

function useCancelReservation() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface CancelReservationProps {
    reservationId: string;
  }

  async function cancelReservation({ reservationId }: CancelReservationProps) {
    const { data } = await API.put<void>({
      path: `/admin/reservations/${reservationId}/cancel`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, CancelReservationProps>(
    (data: CancelReservationProps) => cancelReservation(data),
    {
      onSuccess: async (_, { reservationId }) => {
        await queryClient.invalidateQueries(['reservations']);
        await queryClient.invalidateQueries(['reservation', { reservationId }]);
      },
    },
  );
}

function useCloseReservation() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface CloseReservationProps {
    reservationId: string;
    force: boolean;
  }

  interface Body {
    force: boolean;
  }

  async function closeReservation({ reservationId, force }: CloseReservationProps) {
    const { data } = await API.put<void, Body>({
      path: `/admin/reservations/${reservationId}/close`,
      token,
      version: 1,
      body: {
        force,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, CloseReservationProps>(
    (data: CloseReservationProps) => closeReservation(data),
    {
      onSuccess: async (_, { reservationId }) => {
        await queryClient.invalidateQueries(['reservations']);
        await queryClient.invalidateQueries(['reservation', { reservationId }]);
      },
    },
  );
}

function useExportReservations() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface ExportReservationsProps {
    startDate: string;
    endDate: string;
  }

  async function exportReservations({ startDate, endDate }: ExportReservationsProps) {
    interface Params {
      limit: -1;
      start_date: string;
      end_date: string;
      format: 'csv';
    }

    const { data } = await API.get<Blob, Params, true>({
      path: `/reservations`,
      token,
      version: 1,
      params: {
        format: 'csv',
        limit: -1,
        start_date: formatDate({ date: startDate, variant: 'backendShort' }),
        end_date: formatDate({ date: endDate, variant: 'backendShort' }),
      },
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown, ExportReservationsProps>((data: ExportReservationsProps) =>
    exportReservations(data),
  );
}

function useInvoiceReservation() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface InvoiceReservationProps {
    id: string;
    amount: number;
  }

  async function invoiceReservation({ id, amount }: InvoiceReservationProps) {
    interface Body {
      id: string;
      amount: number;
    }

    const { data } = await API.put<void, Body>({
      path: `/admin/reservations/${id}/invoice`,
      token,
      version: 1,
      body: {
        id,
        amount,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, InvoiceReservationProps>((data: InvoiceReservationProps) =>
    invoiceReservation(data),
  );
}

export {
  Fuel,
  type Reservation,
  Transportation,
  useCancelReservation,
  useCloseReservation,
  useExportReservations,
  useInvoiceReservation,
  useLockVehicle,
  useReservation,
  useReservations,
  useUnlockVehicle,
};
