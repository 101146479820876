import React from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';

import {
  authenticationErrorNoAccess,
  cognitoUserHasDashboardRole,
  loadCognito,
  logoutCognitoUser,
} from './services';

type ExtendedCognitoUser = CognitoUser & {
  signInUserSession: {
    accessToken: {
      // TODO unknown Cignito payload
      payload: any;
    };
  };
  preferredMFA?: string;
  attributes?: {
    email?: string;
    phone_number?: string;
    phone_number_verified?: boolean;
  };
};

interface CognitoUserData {
  user: ExtendedCognitoUser & {
    challengeParam: { CODE_DELIVERY_DESTINATION: string };
  };
}

interface CognitoState {
  authenticated?: boolean;
  token: string | undefined;
  user: ExtendedCognitoUser | undefined;
  preferredMFA?: string;
  action:
    | {
        type: 'NEW_PASSWORD_REQUIRED';
        data: CognitoUserData;
      }
    | {
        type: 'UPDATE_PASSWORD_REQUESTED';
        data: {
          email: string;
        };
      }
    | {
        type: 'SMS_MFA';
        data: CognitoUserData;
      }
    | undefined;

  loading: boolean;
  error: Error | false;
}

const initialState: CognitoState = {
  authenticated: undefined,
  token: undefined,
  user: undefined,
  action: undefined,
  loading: false,
  error: false,
};

interface CognitoContextProps {
  state: CognitoState;
  setState: React.Dispatch<React.SetStateAction<CognitoState>>;
}

const CognitoContext = React.createContext<CognitoContextProps>({
  state: initialState,
  setState: () => {},
});

interface CognitoProviderProps {
  children: JSX.Element;
}

function CognitoProvider({ children }: CognitoProviderProps) {
  const [state, setState] = React.useState(initialState);
  const busyBooleanRef = React.useRef(false);

  React.useEffect(() => {
    async function determineUser() {
      if (busyBooleanRef.current) {
        return;
      }

      busyBooleanRef.current = true;

      setState((prevState) => ({ ...prevState, loading: true }));

      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user: ExtendedCognitoUser) => {
          busyBooleanRef.current = false;
          if (!cognitoUserHasDashboardRole(user)) {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              error: new Error(authenticationErrorNoAccess),
            }));

            logoutCognitoUser()
              .then(() => {
                setState((prevState) => ({ ...prevState, error: false }));
              })
              .catch(() => {
                // Do nothing
              });

            return;
          }

          setState((prevState) => ({
            ...prevState,
            user,
            authenticated: true,
            loading: false,
            error: false,
          }));
        })
        .catch(() => {
          busyBooleanRef.current = false;
          logoutCognitoUser();

          setState((prevState) => ({
            ...prevState,
            authenticated: false,
            loading: false,
          }));
        });
    }

    loadCognito();
    determineUser();
  }, []);

  return <CognitoContext.Provider value={{ state, setState }}>{children}</CognitoContext.Provider>;
}

export { CognitoContext, CognitoProvider, type ExtendedCognitoUser, initialState };
