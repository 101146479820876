// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface UseFleetsProps {
  page: number;
  limit?: number;
}

function useFleets({ page, limit }: UseFleetsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Fleet {
    id: string;
    description: string;
    is_editable: boolean | null;
    name: string;
    size: number;
  }

  interface Response {
    result: Null<Fleet>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/fleets',
      token,
      params: { page, limit: hasMobileView && limit === undefined ? 15 : limit },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['communities', { page, limit, hasMobileView }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && page >= 1,
      retry: 0,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

export { useFleets };
