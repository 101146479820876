import React from 'react';

import { Page, Table } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getLabelColor, getStatus } from 'config/shared';
import { UseQuery } from 'useQuery';

function TravelCardsPage() {
  const translations = useTranslations('travelCardsPage');
  const [page, setPage] = React.useState(1);

  const { searchParams, setSearchParams } = useSearchParams('travelCards');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }
  }, [searchParams]);

  const { data: travelCardsData, isFetching } = UseQuery.travelCards({ page });

  return (
    <Page name="travelCards">
      <>
        <Table
          columns={{
            brand: { name: translations.brand, type: 'text', width: 200 },
            issuedOn: { name: translations.issuedOn, type: 'dateTime', width: 150 },
            maskedNumber: { name: translations.maskedNumber, type: 'text', width: 200 },
            name: { name: translations.name, type: 'text', width: 200 },
            email: { name: translations.email, type: 'text', width: 200 },
            organisation: { name: translations.organisation, type: 'text', width: 200 },
            status: { name: translations.status, type: 'label', width: 150 },
            adyenId: { name: translations.adyenId, type: 'text', width: 300 },
          }}
          rows={(travelCardsData?.result ?? []).map(
            ({
              id,
              brand,
              masked_number,
              created_on,
              user,
              payment_instrument_id,
              status,
              bin,
            }) => ({
              id,
              brand: brand ?? '',
              issuedOn: created_on ?? '',
              maskedNumber: `${bin ?? ''} **** ${masked_number ?? ''}`,
              name: user?.full_name ?? '',
              email: user?.email ?? '',
              organisation: user?.organisation?.name ?? '',
              status: {
                label: getStatus({ travelCard: status }),
                color: getLabelColor({ travelCard: status }),
              },
              adyenId: payment_instrument_id ?? '',
            }),
          )}
          pagination={{
            count: getNumberOfPages({ meta: travelCardsData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({
                page,
              });
              setPage(page);
            },
          }}
          loading={isFetching}
        />
      </>
    </Page>
  );
}

export { TravelCardsPage };
