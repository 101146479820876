import React from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import {
  DatePicker,
  Dialog,
  InformationBlock,
  Page,
  Table,
  TableSearchBar,
  TableTabs,
  VerticalStack,
} from 'components';
import { downloadBlob, formatDate, getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getLabelColor } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';
import { Reservation } from 'useQuery/types';

function ReservationsPage() {
  const { navigate } = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({
          page: 1,
          organisation: value,
          provider: provider ?? '',
          status: status.join(',') ?? '',
          type: type ?? '',
          startDate: dayjs(startDate).isValid()
            ? formatDate({ date: startDate, variant: 'backendShort' }) ?? ''
            : '',
          endDate: dayjs(endDate).isValid()
            ? formatDate({ date: endDate, variant: 'backendShort' }) ?? ''
            : '',
          finalized: finalized ?? '',
        });
        return;
      }
    },
    path: '/reservations',
  });

  const translations = useTranslations('reservationsPage');
  const [page, setPage] = React.useState(1);
  const [tabIndex, setTabIndex] = React.useState(0);

  const [provider, setProvider] = React.useState<Reservation['provider']>();
  const [status, setStatus] = React.useState<Reservation['status'][]>([]);
  const [type, setType] = React.useState<Reservation['type']>();

  const [finalized, setFinalized] = React.useState<string | undefined>('');

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [dialogStartDate, setDialogStartDate] = React.useState('');
  const [dialogEndDate, setDialogEndDate] = React.useState('');

  const { data: reservationsData, isFetching: isFetchingReservations } = UseQuery.reservations({
    page,
    startDate,
    endDate,
    organisation: delayedSearchQuery,
    provider,
    status,
    type,
    finalized,
  });

  const { mutateAsync: mutateAsyncExportReservations, isLoading: isLoadingExportReservations } =
    UseMutation.exportReservations();

  const { searchParams, setSearchParams } = useSearchParams('reservations');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.organisation) {
      setSearchQuery(searchParams.organisation);
    }

    if (searchParams.provider) {
      setProvider(searchParams.provider as Reservation['provider']);
    }

    if (searchParams.status) {
      const allowedStatuses: Reservation['status'][] = [
        'future',
        'upcoming',
        'active',
        'started',
        'ended',
        'past',
        'cancelled',
      ];
      const newStatus = searchParams.status
        .split(',')
        .filter((item) => allowedStatuses.includes(item as Reservation['status']));

      setStatus(newStatus as Reservation['status'][]);
    }

    if (searchParams.type) {
      setType(searchParams.type as Reservation['type']);
    }

    if (searchParams.startDate) {
      setStartDate(searchParams.startDate);
    }

    if (searchParams.endDate) {
      setEndDate(searchParams.endDate);
    }
  }, [searchParams]);

  React.useEffect(() => {
    setSearchParams({
      page,
      organisation: searchQuery,
      provider: provider ?? '',
      status: status.join(',') ?? '',
      type: type ?? '',
      startDate: dayjs(startDate).isValid()
        ? formatDate({ date: startDate, variant: 'backendShort' }) ?? ''
        : '',
      endDate: dayjs(endDate).isValid()
        ? formatDate({ date: endDate, variant: 'backendShort' }) ?? ''
        : '',
      finalized: finalized ?? '',
    });
  }, [provider, status, type, startDate, endDate, finalized]);

  return (
    <Page
      name="reservations"
      actions={[
        {
          svgName: 'fileDownload',
          title: translations.exportReservations,
          mode: 'closeOnClick',
          onClick: () => {
            setDialogStartDate(startDate);
            setDialogEndDate(endDate);
            setOpen(true);
          },
        },
      ]}
    >
      <Dialog
        title={translations.exportReservations}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpen(false);
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                const blob = await mutateAsyncExportReservations({
                  startDate: dialogStartDate,
                  endDate: dialogEndDate,
                });
                downloadBlob({ blob, fileName: 'reservations_export' });

                setOpen(false);
              } catch (error) {
                setOpen(false);
              }
            },
            loading: isLoadingExportReservations,
            disabled: !dialogStartDate || !dialogEndDate,
          },
        ]}
        open={open}
        onChange={setOpen}
      >
        <Box sx={{ paddingTop: 1 }}>
          <DatePicker
            label={translations.startDate}
            value={dialogStartDate}
            onChange={setDialogStartDate}
            fullWidth={true}
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <DatePicker
            label={translations.endDate}
            value={dialogEndDate}
            onChange={setDialogEndDate}
            fullWidth={true}
          />
        </Box>
      </Dialog>
      <VerticalStack>
        <InformationBlock<
          Reservation['provider'],
          Reservation['status'],
          Reservation['type'],
          string | undefined
        >
          items={[
            {
              label: translations.provider,
              data: {
                mode: 'single',
                label: translations.provider,
                value: provider,
                options: [
                  { id: 'Check', label: translations.check },
                  { id: 'Felyx', label: translations.felyx },
                  { id: 'Vecore', label: translations.figo },
                  { id: 'Greenwheels', label: translations.greenwheels },
                  { id: 'MyWheels', label: translations.myWheels },
                  { id: 'Amber', label: translations.amber },
                ],
                onChange: setProvider,
                onReset: () => {
                  setProvider(undefined);
                },
              },
              type: 'select_1',
            },
            {
              label: translations.status,
              data: {
                mode: 'multiple',
                label: translations.status,
                values: status,
                options: [
                  { id: 'future', label: translations.future },
                  { id: 'upcoming', label: translations.upcoming },
                  { id: 'active', label: translations.active },
                  { id: 'started', label: translations.started },
                  { id: 'ended', label: translations.ended },
                  { id: 'past', label: translations.past },
                  { id: 'cancelled', label: translations.cancelled },
                ],
                onChange: setStatus,
              },
              type: 'select_2',
            },
            {
              label: translations.type,
              data: {
                mode: 'single',
                label: translations.provider,
                value: type,
                options: [
                  { id: 'PRIVATE', label: translations.private },
                  { id: 'BUSINESS', label: translations.business },
                ],
                onChange: setType,
                onReset: () => {
                  setType(undefined);
                },
              },
              type: 'select_3',
            },
            {
              label: translations.startDate,
              data: {
                value: startDate,
                onChange: setStartDate,
                onEnter: () => {},
                required: false,
              },
              type: 'editDate',
            },
            {
              label: translations.endDate,
              data: {
                value: endDate,
                onChange: setEndDate,
                onEnter: () => {},
                required: false,
              },
              type: 'editDate',
            },
            {
              label: translations.completed,
              data: {
                label: translations.completed,
                value: finalized,
                mode: 'single',
                onChange: setFinalized,
                onReset: () => {
                  setFinalized(undefined);
                },
                options: [
                  {
                    id: 'true',
                    label: translations.true,
                  },
                  {
                    id: 'false',
                    label: translations.false,
                  },
                ],
              },
              type: 'select_4',
            },
          ]}
        />
        <TableTabs
          variant="linkedFilter"
          data={[
            { label: translations.all, onPress: setSearchQuery, linkedValue: '', fallback: true },
            {
              label: translations.consumers,
              onPress: setSearchQuery,
              linkedValue: 'Consumers',
              fallback: false,
            },
            {
              label: translations.aethon,
              onPress: setSearchQuery,
              linkedValue: 'Aethon',
              fallback: false,
            },
            {
              label: translations.budbee,
              onPress: setSearchQuery,
              linkedValue: 'Budbee',
              fallback: false,
            },
            {
              label: translations.sungevity,
              onPress: setSearchQuery,
              linkedValue: 'Sungevity',
              fallback: false,
            },
            {
              label: translations.youngCapital,
              onPress: setSearchQuery,
              linkedValue: 'Young Capital',
              fallback: false,
            },
          ]}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          currentValue={searchQuery}
        />
        <TableSearchBar
          placeholder={translations.reservations}
          label={translations.searchReservations}
          value={searchQuery}
          onChange={(value: string) => {
            setSearchQuery(value);
          }}
          fullWidth={true}
        />
        <Table
          columns={{
            name: { name: translations.name, type: 'text', width: 200 },
            email: { name: translations.email, type: 'text', width: 200 },
            organisation: { name: translations.organisation, type: 'text', width: 200 },
            startDate: { name: translations.startDate, type: 'dateTime', width: 175 },
            endDate: { name: translations.endDate, type: 'date', width: 175 },
            price: { name: translations.price, type: 'price', width: 100 },
            promotionalCode: { name: translations.promotionalCode, type: 'text', width: 150 },
            status: { name: translations.status, type: 'label', width: 150 },
            provider: { name: translations.provider, type: 'label', width: 150 },
            type: { name: translations.type, type: 'label', width: 150 },
          }}
          rows={(reservationsData?.result ?? []).map(
            ({
              id,
              user,
              start_date,
              end_date,
              total_price,
              promotional_code,
              status,
              provider,
              type,
            }) => ({
              id,
              name: user?.full_name ?? '',
              email: user?.email ?? '',
              organisation: user?.organisation?.name ?? '',
              startDate: start_date ?? '',
              endDate: end_date ?? '',
              price: total_price ?? 0,
              promotionalCode: promotional_code ?? '',
              status: { label: status ?? '', color: getLabelColor({ reservationStatus: status }) },
              provider: { label: provider ?? '', color: getLabelColor({ provider: provider }) },
              type: { label: type ?? '', color: getLabelColor({ reservationType: type }) },
            }),
          )}
          onClickRow={({ id, metaKey }) => {
            navigate({ name: 'reservation', id: id.toString(), searchParams: undefined, metaKey });
          }}
          pagination={{
            count: getNumberOfPages({ meta: reservationsData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({
                page,
                organisation: delayedSearchQuery,
                provider: provider ?? '',
                status: status.join(',') ?? '',
                type: type ?? '',
                startDate: dayjs(startDate).isValid()
                  ? formatDate({ date: startDate, variant: 'backendShort' }) ?? ''
                  : '',
                endDate: dayjs(endDate).isValid()
                  ? formatDate({ date: endDate, variant: 'backendShort' }) ?? ''
                  : '',
                finalized: finalized ?? '',
              });
              setPage(page);
            },
          }}
          loading={isFetchingReservations || reservationsData === undefined}
        />
      </VerticalStack>
    </Page>
  );
}

export { ReservationsPage };
