// eslint-disable-next-line no-restricted-imports
import { Box, Switch as SwitchMUI } from '@mui/material';

import { useDarkMode } from 'core/darkMode/hooks';
import { textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { CustomString } from 'config/translations';

import { Text } from './Text';

interface SwitchProps {
  label: CustomString;
  checked: boolean;
  onChange: (checked: boolean) => void;
  hasFixedDarkBackground?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

function Switch({
  label,
  checked,
  onChange,
  hasFixedDarkBackground,
  disabled,
  fullWidth,
}: SwitchProps) {
  const { mode } = useDarkMode();
  const { hasMobileView } = useHasMobileView();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: fullWidth ? '100%' : textFieldWidth,
      }}
    >
      <Text>{label}</Text>
      <SwitchMUI
        sx={{
          '& .MuiSwitch-track': {
            backgroundColor: mode === 'dark' || hasFixedDarkBackground ? 'common.white' : undefined,
          },
          margin: hasMobileView ? -1 : undefined,
        }}
        checked={checked}
        color="info"
        onChange={({ target }) => {
          onChange(target.checked);
        }}
        disabled={disabled}
      />
    </Box>
  );
}

export { Switch };
