// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface Invitation {
  id: string;
  email: string;
  invitation_code: string;
  redeemed: number;
  used_by_invitee: number;
  used_by_inviter: number;
}

interface UseReservationsProps {
  page: number;
  query: string;
  limit?: number;
}

function useInvitations({ page, query, limit }: UseReservationsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Invitation>[];
    meta: Meta;
  }

  function fetchData() {
    interface Params {
      page: number;
      q: string;
      limit: number | undefined;
    }

    return API.get<Response, Params, false>({
      path: '/admin/invitation',
      token,
      params: {
        page,
        q: query ?? '',
        limit: hasMobileView && limit === undefined ? 15 : limit,
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['invitations', { page, hasMobileView, limit, query }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useExportInvitations() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  async function fetchData() {
    const { data } = await API.get<Blob, undefined, true>({
      path: `/admin/invitation/export`,
      token,
      version: 1,
      params: undefined,
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown>(() => fetchData());
}

export { useExportInvitations, useInvitations };
