// eslint-disable-next-line no-restricted-imports
import { Typography, TypographyProps } from '@mui/material';
import { Color } from 'types/shared';

interface InternalTextProps {
  color?: Color;
  bold?: boolean;
  children: number | string;
  variant?: TypographyProps['variant'];
  noWrap?: TypographyProps['noWrap'];
}

function Text({ color, bold, children, variant, noWrap }: InternalTextProps): JSX.Element {
  return (
    <Typography
      sx={{ color, fontWeight: bold ? 'bold' : undefined }}
      variant={variant}
      noWrap={noWrap}
    >
      {children}
    </Typography>
  );
}

interface TextProps {
  color?: Color;
  children: InternalTextProps['children'];
  noWrap?: TypographyProps['noWrap'];
}

function ExtraLarge({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={false} variant="h6" noWrap={noWrap}>
      {children}
    </Text>
  );
}

function ExtraLargeBold({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={true} variant="h6" noWrap={noWrap}>
      {children}
    </Text>
  );
}

function Large({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={false} variant="h5" noWrap={noWrap}>
      {children}
    </Text>
  );
}

function LargeBold({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={true} variant="h5" noWrap={noWrap}>
      {children}
    </Text>
  );
}

function Regular({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={false} variant={undefined} noWrap={noWrap}>
      {children}
    </Text>
  );
}

function Bold({ color, children, noWrap }: TextProps) {
  return (
    <Text color={color} bold={true} variant={undefined} noWrap={noWrap}>
      {children}
    </Text>
  );
}

Text.ExtraLarge = ExtraLarge;
Text.ExtraLargeBold = ExtraLargeBold;
Text.Large = Large;
Text.LargeBold = LargeBold;
Text.Regular = Regular;
Text.Bold = Bold;

export { Text };
