import { Unstable_Grid2 as Grid } from '@mui/material';

import { Container, DoughnutChart } from 'components';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

export function ProviderStatistics() {
  const { data: providerStatisticsData } = UseQuery.providerStatistics();
  const translations = useTranslations('statisticsPage');

  interface Statistics {
    converted: number;
    pending: number;
  }

  interface ProviderDonutProps {
    provider: string;
    stats: Statistics;
  }

  function ProviderDonut(props: ProviderDonutProps) {
    const { provider, stats } = props;

    return (
      <Container title={provider.replace('_', ' ')} width="small" loading={stats === undefined}>
        <DoughnutChart
          data={[
            { label: translations.converted, value: stats.converted ?? 0 },
            { label: translations.pending, value: stats.pending ?? 0 },
          ]}
          colors={['success.main', 'error.main']}
        />
      </Container>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {providerStatisticsData?.result &&
          Object.entries(providerStatisticsData.result).map(([provider, stats]) => (
            <ProviderDonut key={provider} provider={provider} stats={stats as Statistics} />
          ))}
      </Grid>
    </>
  );
}
