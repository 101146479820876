import React from 'react';
import { Box } from '@mui/material';

import { InformationBlock, InformationBlockProps, Page, Table, Text } from 'components';
import { formatDate } from 'core/shared';
import { useParams } from 'hooks/useParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { getLabelColor, getStatus } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';
import { PublicTransportation } from 'useQuery/types';

function TransactionPage() {
  const { id } = useParams('transaction');

  const { data: transactionData, isFetching } = UseQuery.transaction({ id });
  const { mutateAsync: mutateAsyncEditTransaction, isLoading: isLoadingEditTransaction } =
    UseMutation.editTransaction();

  const { mutateAsync: mutateAsyncResetTransaction } = UseMutation.resetTransaction();
  const [loading, setLoading] = React.useState(false);

  const [items, setItems] = React.useState<InformationBlockProps['items']>([]);
  const [publicTransportationData, setPublicTransportationData] = React.useState<
    PublicTransportation | undefined
  >(undefined);
  const [newMetaDescription, setNewMetaDescription] = React.useState('');

  const translations = useTranslations('transactionPage');

  React.useEffect(() => {
    if (!transactionData?.result) {
      return;
    }

    const {
      entity,
      entity_email,
      start,
      end,
      price,
      price_net,
      category,
      description,
      public_meta,
      status,
    } = transactionData.result;

    setItems([
      { label: translations.fullName, data: { value: entity ?? '' }, type: 'text' },
      { label: translations.email, data: { value: entity_email ?? '' }, type: 'text' },
      {
        label: translations.transactionDate,
        data: {
          value: end
            ? `${formatDate({ date: start, variant: 'long' })} - ${formatDate({
                date: end,
                variant: 'long',
              })}`
            : formatDate({ date: start, variant: 'long' }),
        },
        type: 'text',
      },
      {
        label: translations.priceIncVat,
        data: { value: price ?? 0, adornment: 'EUR' },
        type: 'price',
      },
      {
        label: translations.priceExVat,
        data: { value: price_net ?? 0, adornment: 'EUR' },
        type: 'price',
      },
      { label: translations.type, data: { value: category ?? '' }, type: 'text' },
      { label: translations.description, data: { value: description ?? '' }, type: 'text' },
      {
        label: translations.metaDescription,
        data: {
          value: newMetaDescription,
          placeholder: public_meta?.description ?? '',
          onChange: (content) => {
            setNewMetaDescription(content);
          },
          onEnter: () => {
            if (newMetaDescription) {
              mutateAsyncEditTransaction({ id, metaDescription: newMetaDescription }).then(() => {
                setNewMetaDescription('');
              });
            }
          },
          required: true,
        },
        type: 'editText',
      },
      {
        label: translations.status,
        data: {
          value: getStatus({ transaction: status }),
          color: getLabelColor({ transaction: status }),
        },
        type: 'label',
      },
    ]);
  }, [transactionData, newMetaDescription]);

  React.useEffect(() => {
    const data = transactionData?.result?.public_transportation;

    if (!data) {
      return;
    }

    Object.keys(data).forEach((key) => {
      setPublicTransportationData(data[key]);
    });
  }, [transactionData?.result?.public_transportation]);

  return (
    <Page
      name="transaction"
      loading={isFetching || !transactionData}
      footer={[
        {
          label: translations.restore,
          variant: 'outlined',
          onClick: () => {
            setNewMetaDescription('');
          },
          disabled: !newMetaDescription,
        },
        {
          label: translations.update,
          onClick: () => {
            if (newMetaDescription) {
              mutateAsyncEditTransaction({ id, metaDescription: newMetaDescription }).then(() => {
                setNewMetaDescription('');
              });
            }
          },
          loading: isLoadingEditTransaction,
          disabled: !newMetaDescription,
        },
      ]}
    >
      <>
        <InformationBlock
          items={items}
          actions={[
            {
              title: translations.resetToPending,
              svgName: 'restore',
              mode: 'closeAfterLoading',
              onClick: () => {
                setLoading(true);
                mutateAsyncResetTransaction({ id })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              },
              disabled:
                transactionData?.result.status !== 'COMPLETED' &&
                transactionData?.result.status !== 'SKIPPED',
              loading,
            },
          ]}
        />
        <Box sx={{ height: 24 }} />
        {publicTransportationData && (
          <>
            <Box sx={{ height: 24 }} />
            <Text.LargeBold>{translations.additionalDetails}</Text.LargeBold>
            <Box sx={{ height: 24 }} />
            <Table
              columns={{
                checkIn: { name: translations.checkIn, type: 'text', flex: 1 },
                checkOut: { name: translations.checkOut, type: 'text', flex: 1 },
                type: { name: translations.type, type: 'text', width: 100 },
                provider: { name: translations.provider, type: 'text', width: 150 },
                fare: { name: translations.provider, type: 'price', width: 100 },
              }}
              rows={[
                {
                  id: '1',
                  checkIn: replaceText({
                    text: translations.checkInCheckOut,
                    replaceValues: [
                      publicTransportationData?.checkin?.location ?? '',
                      formatDate({
                        date: publicTransportationData?.checkin?.time ?? '',
                        variant: 'long',
                      }),
                    ],
                  }),
                  checkOut: replaceText({
                    text: translations.checkInCheckOut,
                    replaceValues: [
                      publicTransportationData?.checkout?.location ?? '',
                      formatDate({
                        date: publicTransportationData?.checkout?.time ?? '',
                        variant: 'long',
                      }),
                    ],
                  }),
                  type: publicTransportationData.type,
                  provider: publicTransportationData.provider,
                  fare: publicTransportationData.price,
                },
              ]}
              loading={false}
            />
          </>
        )}
      </>
    </Page>
  );
}

export { TransactionPage };
