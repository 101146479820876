import { useContext } from 'react';

import { ToastContext } from './context';

function useToast() {
  const { currentToast, addToast, removeToast } = useContext(ToastContext);

  return { currentToast, addToast, removeToast };
}

export { useToast };
