import React from 'react';

import { DarkModeContext, Mode } from './context';

function useDarkMode() {
  const { mode, setMode } = React.useContext(DarkModeContext);

  function setLocalMode(mode: Mode) {
    localStorage.setItem('darkMode', mode);
    setMode(mode);
  }

  return { mode, setMode: setLocalMode };
}

export { useDarkMode };
