import { Box } from '@mui/material';

import { Button, ButtonProps, Text } from 'components';
import { useDarkMode } from 'core/darkMode/hooks';

import { CustomString } from 'config/translations';

interface LocalButton {
  variant: ButtonProps['variant'];
  label: CustomString;
  onClick: () => void;
  loading?: boolean;
  disabled?: ButtonProps['disabled'];
}

interface PopupProps {
  title: CustomString;
  children: JSX.Element | JSX.Element[];
  left?: LocalButton;
  right?: LocalButton;
}

function Popup({ title, children, left, right }: PopupProps) {
  const { mode } = useDarkMode();

  return (
    <Box
      sx={{
        width: '75vw',
        maxWidth: 400,
        backgroundColor: 'background.paper',
        p: 2,
        boxShadow: mode === 'light' ? `2px 4px 8px grey` : undefined,
        borderRadius: 1,
      }}
    >
      <Text.Large>{title}</Text.Large>
      <Box sx={{ flexDirection: 'column', display: 'flex' }}>{children}</Box>
      {(!!left || !!right) && <Box sx={{ paddingTop: 2 }}>{}</Box>}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {!!left && (
          <Button
            loading={left.loading}
            variant={left.variant}
            onClick={left.onClick}
            disabled={left.disabled}
            label={left.label}
          />
        )}
        <Box sx={{ display: 'flex', flex: 1 }} />
        {!!right && (
          <Button
            loading={right.loading}
            variant={right.variant}
            onClick={right.onClick}
            disabled={right.disabled}
            label={right.label}
          />
        )}
      </Box>
    </Box>
  );
}

export { Popup };
