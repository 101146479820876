import React from 'react';
import { Box, useTheme } from '@mui/material';

import { InformationBlock } from 'components';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseMutation, UseQuery } from 'useQuery';
import { Product } from 'useQuery/types';

interface OrganisationMerchantCodesProps {
  organisationId: string | undefined;
  transaction_rules: { MERCHANT_CATEGORY_CODES: string[] | null } | null | undefined;
}

function OrganisationMerchantCodes({
  organisationId = 'new',
  transaction_rules,
}: OrganisationMerchantCodesProps) {
  const theme = useTheme();
  const translations = useTranslations('organisationPage');
  const [loading, setLoading] = React.useState(true);
  const {
    mutateAsync: mutateAsyncEditMerchantCategoryCodes,
    isLoading: isLoadingEditMerchantCategoryCodes,
  } = UseMutation.editMerchantCategoryCodes();
  const { data: merchantCategoryCodesData } = UseQuery.merchantCategoryCodes();
  const [MCCs, setMCCs] = React.useState<string[]>([]);
  const DEFAULT_MERCHANT_CATEGORY_CODES = [
    '4111',
    '4112',
    '4121',
    '4131',
    '4789',
    '7512',
    '7394',
    '7519',
    '3355',
    '7311',
    '7523',
  ];

  const hasNewMerchantCategoryCodesData =
    organisationId !== 'new' &&
    JSON.stringify(
      (transaction_rules?.MERCHANT_CATEGORY_CODES ?? []).sort((a, b) => parseInt(a) - parseInt(b)),
    ) !== JSON.stringify((MCCs ?? []).sort((a, b) => parseInt(a) - parseInt(b)));

  const hasDefaultMerchantCategoryCodesData =
    organisationId !== 'new' &&
    JSON.stringify((MCCs ?? []).sort((a, b) => parseInt(a) - parseInt(b))) ===
      JSON.stringify(
        (DEFAULT_MERCHANT_CATEGORY_CODES ?? []).sort((a, b) => parseInt(a) - parseInt(b)),
      );

  if (loading) {
    setMCCs(transaction_rules?.MERCHANT_CATEGORY_CODES ?? []);
    setLoading(false);
  }

  React.useEffect(() => {
    if (organisationId === 'new') {
      setMCCs(DEFAULT_MERCHANT_CATEGORY_CODES);
    }
  }, [organisationId]);

  return (
    <>
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock<Product['id']>
        actions={[
          {
            svgName: 'restore',
            title: translations.resetDefaults,
            onClick: () => {
              setMCCs(DEFAULT_MERCHANT_CATEGORY_CODES);
            },
            mode: 'closeOnClick',
            disabled: hasDefaultMerchantCategoryCodesData,
          },
        ]}
        title={translations.merchantCategoryCodes}
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              setMCCs(transaction_rules?.MERCHANT_CATEGORY_CODES ?? []);
            },
            disabled: !hasNewMerchantCategoryCodesData,
          },
          {
            label: translations.save,
            onClick: () => {
              setLoading(true);
              mutateAsyncEditMerchantCategoryCodes({
                organisationId: organisationId ?? '',
                merchantCategoryCodes: MCCs,
              });
            },
            disabled: !hasNewMerchantCategoryCodesData,
            loading: isLoadingEditMerchantCategoryCodes,
          },
        ]}
        items={[
          {
            label: translations.merchantCategoryCodes,
            fullWidth: true,
            data: {
              label: translations.merchantCategoryCodes,
              values: MCCs,
              options: Object.keys(merchantCategoryCodesData?.result ?? {}).map((id) => ({
                id,
                label: `${id}: ${
                  merchantCategoryCodesData?.result[id] as unknown as string
                }` as CustomString,
              })),
              onChange: setMCCs,
              mode: 'multiple',
            },
            type: 'select_1',
          },
        ]}
      />
    </>
  );
}

export { OrganisationMerchantCodes };
