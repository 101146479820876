import React from 'react';

import { UseMutation, UseQuery } from 'useQuery';
import { MetaRule } from 'useQuery/types';

interface UpdateProps {
  metaRules: (Omit<MetaRule, 'id'> & { id: string | undefined })[];
}

interface UseMetaRulesReturn {
  data: MetaRule[] | undefined;
  update: ({ metaRules }: UpdateProps) => void;
  isFetching: boolean;
  isUpdating: boolean;
}

function useMetaRules(): UseMetaRulesReturn {
  const {
    data: metaRulesData,
    refetch: refetchMetaRules,
    isFetching: isFetchingMetaRules,
  } = UseQuery.metaRules();

  const { mutateAsync: mutateAsyncAddMetaRule } = UseMutation.addMetaRule({
    invalidateQueries: false,
  });
  const { mutateAsync: mutateAsyncDeleteMetaRule } = UseMutation.deleteMetaRule({
    invalidateQueries: false,
  });
  const { mutateAsync: mutateAsyncEditMetaRule } = UseMutation.useEditMetaRule({
    invalidateQueries: false,
  });

  const [metaRules, setMetaRules] = React.useState<MetaRule[]>();
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    if (metaRulesData?.result) {
      setMetaRules(metaRulesData.result);
    }
  }, [metaRulesData]);

  async function update({ metaRules: newMetaRules }: UpdateProps) {
    type Actions = (
      | ({ type: 'add' } & Omit<MetaRule, 'id'>)
      | ({ type: 'edit' } & MetaRule)
      | { type: 'delete'; id: string }
    )[];

    const actions: Actions = [];

    newMetaRules.forEach(
      ({ id, target, description, transportation_type, fuel_type, price_per_kilometer }) => {
        const original = metaRules?.find(({ id: localId }) => id === localId);

        // Add new meta rule when not present in original data
        if (!original) {
          actions.push({
            type: 'add',
            target,
            description,
            transportation_type,
            fuel_type,
            price_per_kilometer,
          });
          return;
        }

        // Update meta rule when different to original data
        if (
          target !== original.target ||
          description !== original.description ||
          transportation_type !== original.transportation_type ||
          fuel_type !== original.fuel_type ||
          price_per_kilometer !== original.price_per_kilometer
        ) {
          actions.push({
            type: 'edit',
            id: id as string,
            target,
            description,
            transportation_type,
            fuel_type,
            price_per_kilometer,
          });
          return;
        }
      },
    );

    // Delete meta rule when newMetaRules is missing id
    (metaRules ?? []).forEach(({ id: localId }) => {
      if (newMetaRules.findIndex(({ id: newId }) => newId === localId) === -1) {
        actions.push({ type: 'delete', id: localId });
      }
    });

    if (actions.length === 0) {
      return Promise.resolve();
    }

    setUpdating(true);

    try {
      for (let index = 0; index < actions.length; index++) {
        const action = actions[index];
        if (action.type === 'add') {
          await mutateAsyncAddMetaRule({
            target: action.target,
            description: action.description,
            transportation_type: action.transportation_type,
            fuel_type: action.fuel_type,
            price_per_kilometer: action.price_per_kilometer,
          });
        }

        if (action.type === 'edit') {
          await mutateAsyncEditMetaRule({
            id: action.id,
            target: action.target,
            description: action.description,
            transportation_type: action.transportation_type,
            fuel_type: action.fuel_type,
            price_per_kilometer: action.price_per_kilometer,
          });
        }

        if (action.type === 'delete') {
          await mutateAsyncDeleteMetaRule({
            id: action.id,
          });
        }
      }

      await refetchMetaRules();
      setUpdating(false);
      return Promise.resolve();
    } catch (error) {
      setUpdating(false);
      return Promise.reject(error);
    }
  }

  return {
    data: metaRules,
    update,
    isFetching: (!updating && !!isFetchingMetaRules) || metaRules === undefined,
    isUpdating: updating,
  };
}

export { useMetaRules };
