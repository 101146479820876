import React from 'react';
import { AlertProps } from '@mui/material';
import CryptoJS from 'crypto-js';

import { CustomString } from 'config/translations';

interface Toast {
  hash: string;
  title: CustomString | undefined;
  error: Error | unknown | undefined;
  duraction?: number;
  severity: AlertProps['severity'];
}

type AddToastProps =
  | {
      title: CustomString;
      error?: undefined;
      duraction?: number;
      severity: AlertProps['severity'];
    }
  | {
      title?: undefined;
      error: Error | unknown;
      duraction?: number;
      severity: AlertProps['severity'];
    };

interface ToastContextProps {
  currentToast: Toast | undefined;
  addToast: ({ title, error, duraction, severity }: AddToastProps) => void;
  removeToast: (hash: string) => void;
}

const ToastContext = React.createContext<ToastContextProps>({
  currentToast: undefined,
  addToast: () => '',
  removeToast: () => {},
});

interface TranslationsProviderProps {
  children: JSX.Element;
}

function ToastProvider({ children }: TranslationsProviderProps) {
  const [toasts, setToasts] = React.useState<Toast[]>([]);
  const [currentToast, setCurrentToast] = React.useState<Toast | undefined>(undefined);

  React.useEffect(() => {
    if (!currentToast) {
      setCurrentToast(toasts[0]);
    } else {
      setCurrentToast(undefined);
    }
  }, [toasts]);

  React.useEffect(() => {
    if (!currentToast) {
      setCurrentToast(toasts[0]);
    }
  }, [currentToast]);

  function addToast({ title, error, duraction = 5000, severity }: AddToastProps) {
    const hash = CryptoJS.MD5(JSON.stringify({ title, error, duraction, severity })).toString();

    setToasts((prevState) => {
      return [...prevState, { hash, title, error, duraction, severity }];
    });
  }

  function removeToast(hash: string) {
    const index = toasts.findIndex(({ hash: localHash }) => localHash === hash);

    if (index !== -1) {
      setToasts((prevState) => {
        const newToasts = [...prevState];
        newToasts.splice(index, 1);
        return newToasts;
      });
      return;
    }

    return;
  }

  return (
    <ToastContext.Provider value={{ currentToast, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };
