import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Dialog, InformationBlock, Select, TextField } from 'components';
import { capitalizeFirstLetter } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseMutation } from 'useQuery';

type AFASEnvironments = 'resttest' | 'restaccept' | 'rest';

interface OrganisationAfasProps {
  organisationId: string | undefined;
  connections:
    | {
        afas:
          | {
              connector_name: string;
              token: string;
              environment_type: string;
              environment_id: number;
            }
          | false;
      }
    | null
    | undefined;
}

function OrganisationAfas({ organisationId = 'new', connections }: OrganisationAfasProps) {
  const [loading, setLoading] = React.useState(true);

  const [openAfas, setOpenAfas] = React.useState(false);
  const [afasConnectorName, setAfasConnectorName] = React.useState('');
  const [afasEnvironment, setAfasEnvironment] = React.useState<AFASEnvironments>();
  const [afasEnvironmentId, setAfasEnvironmentId] = React.useState<string>('');
  const [afasToken, setAfasToken] = React.useState('');
  const { mutateAsync: mutateAsyncEditAFAS } = UseMutation.editAFAS();
  const theme = useTheme();
  const translations = useTranslations('organisationPage');
  const { addToast } = useToast();
  const hasNewAFAS =
    organisationId !== 'new' &&
    connections?.afas &&
    (afasConnectorName !== connections?.afas.connector_name ||
      afasEnvironment !== connections?.afas.environment_type ||
      afasEnvironmentId !== connections?.afas.environment_id.toString() ||
      afasToken !== connections?.afas.token);

  React.useEffect(() => {
    if (connections?.afas !== undefined && connections?.afas !== false && loading) {
      setAfasConnectorName(connections?.afas.connector_name);
      setAfasEnvironment(connections?.afas.environment_type as AFASEnvironments);
      setAfasEnvironmentId(connections?.afas.environment_id.toString());
      setAfasToken(connections?.afas.token);
    }
    setLoading(false);
  }, [connections]);

  return (
    <>
      <Dialog
        title={translations.dialogAFASConnectionTitle}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              if (connections?.afas !== undefined && connections?.afas !== false) {
                setAfasConnectorName(connections?.afas.connector_name);
                setAfasEnvironment(connections?.afas.environment_type as AFASEnvironments);
                setAfasEnvironmentId(connections?.afas.environment_id.toString());
                setAfasToken(connections?.afas.token);
              }
              setOpenAfas(false);
            },
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              setLoading(true);
              try {
                await mutateAsyncEditAFAS({
                  id: organisationId ?? '',
                  connectorName: afasConnectorName,
                  environmentId: afasEnvironmentId,
                  environmentType: afasEnvironment ?? '',
                  token: afasToken,
                });
                addToast({
                  title: translations.dialogAFASConnectionSuccess,
                  severity: 'success',
                });
                setOpenAfas(false);
              } catch (error) {
                setLoading(false);
              }
            },
            loading: loading,
            disabled:
              !afasConnectorName ||
              !afasEnvironmentId ||
              !afasEnvironment ||
              !afasToken ||
              (!hasNewAFAS && !!connections?.afas),
          },
        ]}
        open={openAfas}
        onChange={setOpenAfas}
      >
        <Box sx={{ paddingTop: 1 }}>
          <TextField
            label={translations.connectorName}
            variant="outlined"
            placeholder={translations.email}
            value={afasConnectorName}
            onChange={setAfasConnectorName}
            fullWidth={true}
            required={true}
            type="text"
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            label={translations.environmentId}
            variant="outlined"
            placeholder={translations.environmentId}
            value={afasEnvironmentId}
            onChange={setAfasEnvironmentId}
            fullWidth={true}
            required={true}
            type="text"
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <Select
            mode="single"
            label={translations.environment}
            value={afasEnvironment}
            options={[
              { id: 'resttest', label: translations.test },
              { id: 'restaccept', label: translations.acceptance },
              { id: 'rest', label: translations.production },
            ]}
            onChange={setAfasEnvironment}
          />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <TextField
            label={translations.token}
            variant="outlined"
            placeholder={translations.tokenPlaceHolder}
            value={afasToken}
            onChange={setAfasToken}
            fullWidth={true}
            required={true}
            type="text"
          />
        </Box>
      </Dialog>
      <Box sx={{ height: theme.spacing(2) }} />
      {organisationId !== 'new' && (
        <InformationBlock
          actions={[
            {
              svgName: 'edit',
              title: translations.editAfasConnection,
              onClick: () => {
                setOpenAfas(true);
              },
              mode: 'closeOnClick',
            },
          ]}
          title={translations.connections}
          items={Object.keys(connections ?? []).map((key) => ({
            label: capitalizeFirstLetter(key) as CustomString,
            data: {
              value:
                connections && connections[key as 'afas']
                  ? translations.connected
                  : translations.disconnected,
              color: connections && connections[key as 'afas'] ? 'success.main' : 'error.main',
            },
            type: 'label',
          }))}
        />
      )}
    </>
  );
}

export { OrganisationAfas };
