import {
  // eslint-disable-next-line no-restricted-imports
  useMediaQuery,
  useTheme,
} from '@mui/material';

function useHasMobileView() {
  const theme = useTheme();

  return { hasMobileView: useMediaQuery(theme.breakpoints.down('md')) };
}

export { useHasMobileView };
