import React from 'react';
import { Box, Unstable_Grid2 as Grid, useTheme } from '@mui/material';

import { Button, MultipleDepartmentEditor, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

interface NSProviderProps {
  organisationId: string;
  nsData: NSProps;
}

interface NSProps {
  agreement: string;
  department: Record<string, string>;
  company?: string;
}

function NSProvider({ organisationId, nsData }: NSProviderProps) {
  const translations = useTranslations('nsProvider');
  const { addToast } = useToast();
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  const [departmentOrder, setDepartmentOrder] = React.useState<string[]>([]); // Track department order
  const [agreement, setAgreement] = React.useState<string>();
  const [department, setDepartment] = React.useState<Record<string, string>>({});
  const [company, setCompany] = React.useState<string>();

  const { refetch: refetchOrganisationData, isLoading: loadingOrganisationData } =
    UseQuery.organisation({ id: organisationId });
  const { mutateAsync: mutateAsyncUpdateNSProvider, isLoading: loadingUpdateNSProvider } =
    UseMutation.updateOrganisationProviders();

  React.useEffect(() => {
    setAgreement(nsData?.agreement);
    setDepartment(nsData?.department || {});
    setDepartmentOrder(Object.keys(nsData?.department || {})); // Initialize order with existing keys
    setCompany(nsData?.company ?? '');
  }, [nsData]);

  const disabledSave =
    (nsData?.agreement === agreement &&
      JSON.stringify(nsData?.department) === JSON.stringify(department) && // Compare objects by value
      nsData?.company === company) ||
    agreement === undefined ||
    agreement === '' ||
    Object.values(department).length === 0 || // Ensure department has at least one entry
    loadingUpdateNSProvider ||
    loadingOrganisationData;

  async function updateNSProvider() {
    if (agreement === undefined || agreement === '' || Object.values(department).length === 0) {
      return;
    }

    try {
      await mutateAsyncUpdateNSProvider({
        id: organisationId,
        providers: {
          NS: {
            agreement,
            department,
            company: company === '' ? undefined : company,
          },
        },
      });
      await refetchOrganisationData();
      addToast({ title: translations.updatedSuccessfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
          marginBottom: 2,
          border: 1,
          borderColor: 'divider',
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasMobileView ? 'row' : 'column',
            justifyContent: hasMobileView ? 'space-between' : undefined,
          }}
        >
          <Text.Bold>{translations.NSBusinessCard}</Text.Bold>
        </Box>
        <Grid container spacing={1} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Box sx={{ paddingRight: 2 }}>
            <TextField
              type="text"
              label={translations.agreement}
              value={agreement ?? ''}
              onChange={setAgreement}
              required
            />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <TextField
              type="text"
              label={translations.company}
              value={company ?? ''}
              onChange={setCompany}
              required
            />
          </Box>
        </Grid>
        <MultipleDepartmentEditor
          department={department}
          onChange={setDepartment}
          departmentOrder={departmentOrder}
          setDepartmentOrder={setDepartmentOrder}
        />
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <Button
            label={translations.save}
            disabled={disabledSave}
            loading={loadingUpdateNSProvider}
            onClick={updateNSProvider}
          />
        </Grid>
      </Box>
    </>
  );
}

export { type NSProps, NSProvider };
