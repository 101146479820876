import { RouteNames } from './route';

type TransformObject<Temp extends Record<string, string | number> | undefined> = {
  [Key in keyof Temp]: Temp[Key] extends string ? 'string' : 'number';
};

type LocalSearchParamsMapping = {
  [Key in keyof SearchParamsMapping]: SearchParamsMapping[Key] extends undefined
    ? undefined
    : TransformObject<SearchParamsMapping[Key]>;
};

// If you encounter an TypeScript error in 'const hasCorrectKeys', the keys in 'interface SearchParamsMapping' do not match 'type RouteNames'.
const hasCorrectKeys: keyof SearchParamsMapping extends RouteNames ? true : false = true;
if (!hasCorrectKeys) {
  console.error("route.tsx: The keys in 'interface ParamsMapping' do not match 'type RouteNames'");
}

// If you encounter an TypeScript error in 'const hasCorrectKeys', the types in 'interface SearchParamsMapping' do not match 'Record<string, string> | undefined'.
const hasCorrectKeysTwo: SearchParamsMapping[RouteNames] extends
  | Record<string, string | number>
  | undefined
  ? true
  : false = true;
if (!hasCorrectKeysTwo) {
  console.error(
    "route.tsx: The types in 'interface ParamsMapping' do not match 'Record<string, string | number> | undefined'",
  );
}

// ADD NEW SEARCH PARAMS HERE
interface SearchParamsMapping {
  login: undefined;
  main: undefined;
  statistics: { tab: number };
  drivers: { page: number; q: string };
  driver: { reservationsPage: number; tab: number };
  transaction: undefined;
  transactions: { page: number; q: string };
  metaRules: undefined;
  configuration: undefined;
  voucher: undefined;
  vouchers: { page: number };
  privacy: undefined;
  reservation: undefined;
  reservations: {
    page: number;
    organisation: string;
    provider: string;
    status: string;
    type: string;
    startDate: string;
    endDate: string;
    finalized: string;
  };
  travelCards: { page: number };
  businessCards: { page: number; q: string };
  product: undefined;
  products: { page: number };
  balanceAccounts: { page: number; q: string };
  community: undefined;
  communities: { page: number; q: string };
  notFound: undefined;
  organisation: { tab: number };
  organisations: { page: number; q: string };
  invites: { page: number; q: string };
  pushNotifications: undefined;
}

// ADD NEW SEARCH PARAMS HERE
const searchParamsMapping: LocalSearchParamsMapping = {
  login: undefined,
  main: undefined,
  statistics: { tab: 'number' },
  drivers: { page: 'number', q: 'string' },
  driver: { reservationsPage: 'number', tab: 'number' },
  transaction: undefined,
  transactions: { page: 'number', q: 'string' },
  metaRules: undefined,
  configuration: undefined,
  voucher: undefined,
  vouchers: { page: 'number' },
  privacy: undefined,
  reservation: undefined,
  reservations: {
    page: 'number',
    organisation: 'string',
    provider: 'string',
    status: 'string',
    type: 'string',
    startDate: 'string',
    endDate: 'string',
    finalized: 'string',
  },
  travelCards: { page: 'number' },
  businessCards: { page: 'number', q: 'string' },
  product: undefined,
  products: { page: 'number' },
  balanceAccounts: { page: 'number', q: 'string' },
  community: undefined,
  communities: { page: 'number', q: 'string' },
  notFound: undefined,
  organisation: { tab: 'number' },
  organisations: { page: 'number', q: 'string' },
  invites: { page: 'number', q: 'string' },
  pushNotifications: undefined,
} as const;

export { type SearchParamsMapping, searchParamsMapping };
