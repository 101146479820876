import { Box, useTheme } from '@mui/material';
import { Color } from 'types/shared';

import { formatString } from 'core/shared';

import { Text } from './Text';

interface LabelProps {
  title: string;
  color: Color;
}

function Label({ title, color }: LabelProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: theme.spacing(2),
          height: theme.spacing(2),
          borderRadius: theme.spacing(1),
          backgroundColor: color,
          marginRight: 1,
        }}
      />
      <Text>{formatString({ text: title, mode: 'label' })}</Text>
    </Box>
  );
}

export { Label };
