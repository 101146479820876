import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Box, Button, TextField } from '@mui/material';

import { useTranslations } from 'hooks/useTranslations';

interface MultipleDepartmentEditorProps {
  department: Record<string, string>;
  onChange: (newDepartment: Record<string, string>) => void;
  departmentOrder: string[]; // Array to track the order of department keys
  setDepartmentOrder: (order: string[]) => void; // Setter for departmentOrder
}

function MultipleDepartmentEditor({
  department,
  onChange,
  departmentOrder,
  setDepartmentOrder,
}: MultipleDepartmentEditorProps) {
  const [newKey, setNewKey] = React.useState<string>('');
  const [newValue, setNewValue] = React.useState<string>('');
  const translations = useTranslations('organisationPage');

  // Ref to track the currently focused element
  const focusedInputRef = useRef<HTMLInputElement | null>(null);

  // Track the currently focused key
  const [editingKey, setEditingKey] = React.useState<string | null>(null);

  // Restore focus to the input after re-rendering
  useEffect(() => {
    if (focusedInputRef.current) {
      focusedInputRef.current.focus(); // Restore focus to the input
    }
  }, [department]); // Trigger on department changes

  // Update the department key and preserve the value
  function handleEditDepartmentKey(oldKey: string, newKey: string) {
    const updatedDepartment = { ...department };
    const value = updatedDepartment[oldKey];

    // Prevent duplicate keys
    if (newKey in department) {
      return;
    }

    // Update the department object
    delete updatedDepartment[oldKey];
    updatedDepartment[newKey] = value;

    // Update the order array
    const updatedOrder = departmentOrder.map((key) => (key === oldKey ? newKey : key));
    setDepartmentOrder(updatedOrder);

    onChange(updatedDepartment);

    // Set the focus on the newly edited input field
    setEditingKey(newKey);
  }

  // Update the department value
  function handleEditDepartmentValue(key: string, newValue: string) {
    const updatedDepartment = { ...department, [key]: newValue };
    onChange(updatedDepartment);
  }

  function handleAddDepartment() {
    if (newKey && newValue && !(newKey in department)) {
      const updatedDepartment = { ...department, [newKey]: newValue };
      onChange(updatedDepartment);
      setDepartmentOrder([...departmentOrder, newKey]); // Add the new key to the order array
      setNewKey('');
      setNewValue('');
    }
  }

  function handleRemoveDepartment(key: string) {
    const { [key]: _, ...remainingDepartments } = department;
    const updatedOrder = departmentOrder.filter((k) => k !== key); // Remove from order array
    setDepartmentOrder(updatedOrder);
    onChange(remainingDepartments);
  }

  return (
    <Box>
      {departmentOrder.map((key) => (
        <Box key={key} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <TextField
            label={translations.department}
            value={key}
            onChange={(e) => {
              setEditingKey(key); // Track the current key being edited
              handleEditDepartmentKey(key, e.target.value);
            }}
            inputRef={(input) => {
              if (editingKey === key) {
                focusedInputRef.current = input; // Track the current input ref
              }
            }}
            variant="outlined"
            sx={{ marginRight: 2 }}
          />
          <TextField
            label={translations.departmentName}
            value={department[key]}
            onChange={(e) => handleEditDepartmentValue(key, e.target.value)}
            variant="outlined"
            sx={{ marginRight: 2 }}
          />
          <Button onClick={() => handleRemoveDepartment(key)}>{translations.remove}</Button>
        </Box>
      ))}

      <Box sx={{ display: 'flex', marginBottom: 2 }}>
        <TextField
          label={translations.department}
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          variant="outlined"
          sx={{ marginRight: 2 }}
        />
        <TextField
          label={translations.departmentName}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          variant="outlined"
          sx={{ marginRight: 2 }}
        />
        <Button onClick={handleAddDepartment}>{translations.add}</Button>
      </Box>
    </Box>
  );
}

export { MultipleDepartmentEditor };

export type { MultipleDepartmentEditorProps };
