import React from 'react';

import { Dialog, Page, Table, Text, VerticalStack } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

function VouchersPage() {
  const translations = useTranslations('vouchersPage');
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const { navigate } = useNavigate();

  const { data: vouchersData, isFetching: isFetchingVouchersData } = UseQuery.vouchers({ page });
  const { mutateAsync: mutateAsyncDeleteVoucher } = UseMutation.deleteVoucher();

  const { searchParams, setSearchParams } = useSearchParams('vouchers');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }
  }, [searchParams]);

  interface DeleteVouchersProps {
    ids: string[];
    onDone: () => void;
  }

  async function deleteVouchers({ ids, onDone }: DeleteVouchersProps) {
    try {
      for (let index = 0; index < ids.length; index++) {
        await mutateAsyncDeleteVoucher({ id: ids[index] });
      }
      onDone();
    } catch {
      onDone();
    }
  }

  return (
    <Page
      name="vouchers"
      actions={[
        {
          title: translations.add,
          mode: 'closeOnClick',
          onClick: () => {
            navigate({ name: 'voucher', id: 'new', searchParams: undefined, metaKey: false });
          },
          svgName: 'add',
        },
        {
          title: translations.edit,
          mode: 'closeOnClick',
          onClick: () => {
            navigate({
              name: 'voucher',
              id: selectedIds[0],
              searchParams: undefined,
              metaKey: false,
            });
          },
          svgName: 'edit',
          disabled: selectedIds.length !== 1,
        },
        {
          title: translations.delete,
          mode: 'closeOnClick',
          onClick: () => {
            setOpen(true);
          },
          svgName: 'delete',
          disabled: selectedIds.length === 0,
        },
      ]}
      loading={vouchersData === undefined}
    >
      <Dialog
        title={translations.areYouSure}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpen(false);
            },
            disabled: loadingDelete,
          },
          {
            variant: 'contained',
            label: translations.delete,
            onClick: async () => {
              setLoadingDelete(true);
              deleteVouchers({
                ids: selectedIds,
                onDone: () => {
                  setLoadingDelete(false);
                  setOpen(false);
                },
              });
            },
            loading: loadingDelete,
          },
        ]}
        open={open}
        onChange={setOpen}
      >
        <Text>
          {selectedIds.length > 1
            ? replaceText({
                text: translations.deleteVouchersMessage,
                replaceValues: [selectedIds.length],
              })
            : translations.deleteVoucherMessage}
        </Text>
      </Dialog>
      <VerticalStack>
        <Table
          columns={{
            codePhrase: { name: translations.codePhrase, type: 'text', width: 200 },
            type: { name: translations.type, type: 'text', width: 200 },
            value: { name: translations.value, type: 'text', width: 100 },
            validFrom: { name: translations.validFrom, type: 'dateTime', width: 200 },
            validTo: { name: translations.validTo, type: 'dateTime', width: 200 },
            useLimit: { name: translations.maximumRides, type: 'text', width: 200 },
            timesRedeemed: { name: translations.timesRedeemed, type: 'text', width: 200 },
          }}
          rows={(vouchersData?.result ?? []).map(
            ({
              id,
              code_phrase,
              type,
              value,
              valid_from,
              valid_to,
              use_limit,
              times_redeemed,
            }) => ({
              id,
              codePhrase: code_phrase,
              type,
              value: value.toString(),
              validFrom: valid_from,
              validTo: valid_to ?? '-',
              useLimit: use_limit?.toString() ?? '-',
              timesRedeemed: times_redeemed.toString(10) ?? '',
            }),
          )}
          onClickRow={({ id, metaKey }) => {
            navigate({ name: 'voucher', id: id.toString(), searchParams: undefined, metaKey });
          }}
          selectable
          onSelect={(selectedIds) => setSelectedIds(selectedIds)}
          pagination={{
            count: getNumberOfPages({ meta: vouchersData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({ page });
              setPage(page);
            },
          }}
          loading={isFetchingVouchersData}
        />
      </VerticalStack>
    </Page>
  );
}

export { VouchersPage };
