import React from 'react';
import { Box, useTheme } from '@mui/material';

import {
  DatePicker,
  Dialog,
  InformationBlock,
  Page,
  Table,
  TableTabs,
  Text,
  TextField,
} from 'components';
import { getNumberOfPages } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'hooks/useParams';
import { useSearchParams } from 'hooks/useSearchParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { getLabelColor, getStatus } from 'config/shared';
import { CustomString } from 'config/translations';
import { UseMutation, UseQuery } from 'useQuery';

function DriverPage() {
  const { id } = useParams('driver');

  const translations = useTranslations('driverPage');

  const { data: driverData, isFetching, refetch: refetchDriver } = UseQuery.driver({ id });
  const { data: organisationsData } = UseQuery.organisations({ page: 1, limit: 10000 });
  const { data: organisationData } = UseQuery.organisation({
    id: driverData?.result.organisation?.id ?? '',
  });

  const [reservationsPage, setReservationsPage] = React.useState(1);

  const { data: reservationsData, isFetching: isFetchingReservationsData } = UseQuery.reservations({
    userId: driverData?.result.id ?? '',
    startDate: '',
    endDate: '',
    page: reservationsPage,
    provider: undefined,
    status: [],
    type: undefined,
    finalized: undefined,
  });

  const theme = useTheme();

  const { searchParams, setSearchParams } = useSearchParams('driver');

  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (searchParams.tab) {
      setTabIndex(searchParams.tab);
    }
  }, [searchParams]);

  const [openActivate, setOpenActivate] = React.useState(false);
  const [openDeactivate, setOpenDeactivate] = React.useState(false);

  const [openAnonymizeAccount, setOpenAnonymizeAccount] = React.useState(false);
  const [forgetAccountEmail, setForgetAccountEmail] = React.useState('');

  const [openAcceptDriversLicense, setOpenAcceptDriversLicense] = React.useState(false);
  const [dialogDocumentNumber, setDialogDocumentNumber] = React.useState('');
  const [dialogValidTo, setDialogValidTo] = React.useState('');
  const [dialogDateOfBirth, setDialogDateOfBirth] = React.useState('');
  const [dialogAMValidTo, setDialogAMValidTo] = React.useState('');
  const [dialogAMIssuedOn, setDialogAMIssuedOn] = React.useState('');
  const [dialogBValidTo, setDialogBValidTo] = React.useState('');
  const [dialogBIssuedOn, setDialogBIssuedOn] = React.useState('');

  const { mutateAsync: mutateAsyncEditDriverDetails } = UseMutation.editDriverDetails();

  const { mutateAsync: mutateAsyncEditDriverDashboardAccess } =
    UseMutation.editDriverDashboardAccess();

  const { mutateAsync: mutateAsyncEditDriverPaymentInstrument } =
    UseMutation.editDriverPaymentInstrument();

  const { mutateAsync: mutateAsyncEditDriver2FA } = UseMutation.editDriver2FA();

  const { mutateAsync: mutateAsyncEditDriverPersonalInformation } =
    UseMutation.editDriverPersonalInformation();

  const { mutateAsync: mutateAsyncEditProviderMeta } = UseMutation.editProviderMeta();

  const { mutateAsync: mutateAsyncAddDriverBalance } = UseMutation.addDriverBalance();

  const { mutateAsync: mutateAsyncEditDriverOrganisation } = UseMutation.editDriverOrganisation();

  const { mutateAsync: mutateAsyncDataProtection, isLoading: isLoadingDataProtection } =
    UseMutation.dataProtection();

  const { mutateAsync: mutateAsyncEditDriverVerified, isLoading: isLoadingEditDriverVerified } =
    UseMutation.editDriverVerified();

  const { mutateAsync: mutateAsyncAddDriversLicense, isLoading: isLoadingAddDriversLicense } =
    UseMutation.addDriversLicense();

  const { mutateAsync: mutateAsyncSendDriverInvite, isLoading: isLoadingSendDriverInvite } =
    UseMutation.sendDriverInvite();

  const { mutateAsync: mutateAsyncRemitDriverInvoices, isLoading: isLoadingRemitDriverInvoices } =
    UseMutation.remitDriverInvoices();

  const { navigate } = useNavigate();
  const { addToast } = useToast();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [dashboardAccess, setDashboardAccess] = React.useState(false);
  const [TwoFA, setTwoFA] = React.useState(false);
  const [travelCard, setTravelCard] = React.useState(false);
  const [validatePayment, setValidatePayment] = React.useState(true);
  const [balance, setBalance] = React.useState(0);
  const [greenwheelsCardNumber, setGreenwheelsCardNumber] = React.useState('');
  const [greenwheelsDriverId, setGreenwheelsDriverId] = React.useState('');

  const hasNewDetails =
    firstName !== (driverData?.result.first_name ?? '') ||
    lastName !== (driverData?.result.last_name ?? '') ||
    phoneNumber !== (driverData?.result.phone_number ?? '') ||
    dashboardAccess !== !!driverData?.result.groups?.includes('role:dashboard') ||
    TwoFA !== driverData?.result.mfa_status ||
    travelCard !==
      (!!driverData?.result.allow_payment_instrument &&
        !!organisationData?.result.allow_payment_instruments &&
        organisationData.result.payment_instruments_allowed) ||
    validatePayment !== driverData?.result.validate_payment;

  const [personalEmail, setPersonalEmail] = React.useState('');
  const [personalPhoneNumber, setPersonalPhoneNumber] = React.useState('');
  const [personalAddress, setPersonalAddress] = React.useState('');
  const [personalZipCode, setPersonalZipCode] = React.useState('');
  const [personalCity, setPersonalCity] = React.useState('');
  const [personalAddressVerified, setPersonalAddressVerified] = React.useState(false);

  const [organisationId, setOrganisationId] = React.useState('');

  const displayBalance = parseFloat((balance / 100).toFixed(2));

  function convertBalance(value: number) {
    const amountInCents = Math.round(value * 100);

    setBalance(amountInCents);
  }

  React.useEffect(() => {
    const firstTime = !firstName;

    if (!driverData || (!firstTime && !loading)) {
      return;
    }

    const {
      organisation,
      first_name,
      last_name,
      phone_number,
      mfa_status,
      groups,
      validate_payment,
      balance,
    } = driverData.result ?? {};

    if (firstTime || loading === 'details') {
      setFirstName(first_name ?? '');
      setLastName(last_name ?? '');
      setPhoneNumber(phone_number ?? '');
      setDashboardAccess(!!groups?.includes('role:dashboard'));
      setTwoFA(mfa_status);
      setValidatePayment(validate_payment);

      setLoading(false);
    }

    if (firstTime || loading === 'personalInformation') {
      setPersonalEmail(driverData?.result.personal_info?.email ?? '');
      setPersonalPhoneNumber(driverData?.result.personal_info?.phone_number ?? '');
      setPersonalAddress(driverData?.result.personal_info?.address ?? '');
      setPersonalZipCode(driverData?.result.personal_info?.zipcode ?? '');
      setPersonalCity(driverData?.result.personal_info?.city ?? '');
      setPersonalAddressVerified(driverData?.result.personal_info?.verified ?? false);

      setLoading(false);
    }

    if (firstTime || loading === 'organisation') {
      setOrganisationId(organisation?.id ?? '');

      setLoading(false);
    }

    if (firstTime || loading === 'wallet') {
      setBalance(balance ?? 0);

      setLoading(false);
    }

    if (firstTime || loading === 'wallet') {
      setGreenwheelsDriverId(driverData?.result.provider_data?.greenwheels?.external_id ?? '');
      setGreenwheelsCardNumber(driverData?.result.provider_data?.greenwheels?.card_number ?? '');

      setLoading(false);
    }
  }, [driverData]);

  React.useEffect(() => {
    if (!driverData || !organisationData) {
      return;
    }

    const { allow_payment_instrument } = driverData.result ?? {};

    setTravelCard(
      allow_payment_instrument &&
        !!organisationData?.result.allow_payment_instruments &&
        organisationData.result.payment_instruments_allowed,
    );
  }, [driverData, organisationData]);

  function TravelCardContent() {
    const {
      mutateAsync: updateTravelCardStatusmutateAsync,
      isLoading: isLoadingUpdateTravelCardStatus,
    } = UseMutation.updateTravelCardStatus({ invalidateQueries: true, driverId: id });

    return (
      <Table
        columns={{
          travelCardId: { name: translations.travelCardId, type: 'text', flex: 1 },
          maskedNumber: { name: translations.maskedNumber, type: 'text', flex: 1 },
          status: { name: translations.status, type: 'label', flex: 1 },
          activate: { name: translations.activate, type: 'buttonOrSVGOrEmpty', flex: 1 },
          suspend: { name: translations.suspend, type: 'buttonOrSVGOrEmpty', flex: 1 },
        }}
        rows={(driverData?.result.travel_cards ?? []).map(({ id, masked_number, status }) => ({
          id,
          travelCardId: id,
          maskedNumber: replaceText({
            text: translations.creditCardNumber,
            replaceValues: [masked_number],
          }),
          status: {
            label: getStatus({ travelCard: status }),
            color: getLabelColor({ travelCard: status }),
          },
          suspend: {
            label: translations.suspend,
            onClick: () => {
              updateTravelCardStatusmutateAsync({ id, status: 'suspended' });
            },
            disabled: status === 'suspended' || status === 'closed',
            loading: isLoadingUpdateTravelCardStatus,
          },
          activate: {
            label: translations.activate,
            onClick: () => {
              updateTravelCardStatusmutateAsync({ id, status: 'active' });
            },
            disabled: status === 'active' || status === 'closed',
            loading: isLoadingUpdateTravelCardStatus,
          },
        }))}
        loading={isFetching}
      />
    );
  }

  const CommunityContent = (
    <Table
      columns={{
        communityName: { name: translations.communityName, type: 'text', flex: 1 },
        fleets: { name: translations.fleets, type: 'text', flex: 1 },
      }}
      rows={(driverData?.result.communities ?? []).map(({ id, name, fleets }) => ({
        id: id,
        communityName: name,
        fleets: fleets.join(', '),
      }))}
      onClickRow={({ id, metaKey }) => {
        navigate({ name: 'community', id: id.toString(), searchParams: undefined, metaKey });
      }}
      loading={isFetching}
    />
  );

  const ReservationsContent = (
    <Table
      columns={{
        name: { name: translations.name, type: 'text', width: 200 },
        email: { name: translations.email, type: 'text', width: 200 },
        organisation: { name: translations.organisation, type: 'text', width: 200 },
        startDate: { name: translations.startDate, type: 'dateTime', width: 175 },
        endDate: { name: translations.endDate, type: 'dateTime', width: 175 },
        price: { name: translations.price, type: 'price', width: 100 },
        status: { name: translations.status, type: 'label', width: 150 },
        provider: { name: translations.provider, type: 'label', width: 150 },
        type: { name: translations.type, type: 'label', width: 150 },
      }}
      rows={(reservationsData?.result ?? []).map(
        ({ id, user, start_date, end_date, total_price, status, provider, type }) => ({
          id,
          name: user?.full_name ?? '',
          email: user?.email ?? '',
          organisation: user?.organisation?.name ?? '',
          startDate: start_date ?? '',
          endDate: end_date ?? '',
          price: total_price ?? 0,
          status: { label: status ?? '', color: getLabelColor({ reservationStatus: status }) },
          provider: { label: provider ?? '', color: getLabelColor({ provider }) },
          type: { label: type ?? '', color: getLabelColor({ reservationType: type }) },
        }),
      )}
      onClickRow={({ id, metaKey }) => {
        navigate({ name: 'reservation', id: id.toString(), searchParams: undefined, metaKey });
      }}
      pagination={{
        count: getNumberOfPages({ meta: reservationsData?.meta }),
        page: reservationsPage,
        onChange: (page) => {
          setSearchParams({
            reservationsPage: page,
            tab: tabIndex,
          });
          setReservationsPage(page);
        },
      }}
      loading={isFetchingReservationsData || reservationsData === undefined}
    />
  );

  const [loading, setLoading] = React.useState<
    'details' | 'personalInformation' | 'organisation' | 'wallet' | 'providers' | false
  >(false);

  const WalletContent = (
    <Box>
      <InformationBlock
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              setBalance(driverData?.result.balance ?? 0);
            },
            disabled: balance === (driverData?.result.balance ?? 0),
          },
          {
            variant: 'contained',
            label: translations.save,
            onClick: async () => {
              try {
                setLoading('wallet');
                await mutateAsyncAddDriverBalance({
                  driverId: id,
                  balance: balance,
                });
                addToast({
                  title: translations.successfullyAddedWalletBalance,
                  severity: 'success',
                });
                setLoading(false);
              } catch (error) {
                // Do nothing
              }
            },
            loading: loading === 'wallet',
            disabled: balance === (driverData?.result.balance ?? 0),
          },
        ]}
        title={translations.wallet}
        items={[
          {
            type: 'editNumber',
            label: translations.balance,
            data: {
              value: displayBalance,
              onChange: (value) => convertBalance(value),
              onEnter: () => {},
              required: true,
              adornment: 'EUR',
            },
          },
        ]}
      />
    </Box>
  );

  const ProviderContent = (
    <Box>
      <InformationBlock
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              setGreenwheelsDriverId(
                driverData?.result.provider_data?.greenwheels?.external_id ?? '',
              );
              setGreenwheelsCardNumber(
                driverData?.result.provider_data?.greenwheels?.card_number ?? '',
              );
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.save,
            onClick: async () => {
              try {
                setLoading('providers');
                await mutateAsyncEditProviderMeta({
                  id: id,
                  greenwheels_driver_id: greenwheelsDriverId,
                  greenwheels_card_number: greenwheelsCardNumber,
                });
                addToast({
                  title: translations.successfullyAddedWalletBalance,
                  severity: 'success',
                });
                setLoading(false);
              } catch (error) {
                // Do nothing
              }
            },
            loading: loading === 'providers',
            disabled: false,
          },
        ]}
        title={translations.greenwheels}
        items={[
          {
            label: translations.driverId,
            data: {
              value: greenwheelsDriverId,
              onChange: setGreenwheelsDriverId,
              onEnter: () => {},
              required: true,
            },
            type: 'editText',
          },
          {
            label: translations.cardNumber,
            data: {
              value: greenwheelsCardNumber,
              onChange: setGreenwheelsCardNumber,
              onEnter: () => {},
              required: true,
            },
            type: 'editText',
          },
        ]}
      />
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock
        title={translations.mywheels}
        items={[
          {
            label: translations.hasMyWheels,
            data: {
              value: driverData?.result.has_mywheels ? 'Yes' : 'No',
            },
            type: 'text',
          },
        ]}
      />
    </Box>
  );

  const DetailsContent = (
    <Box>
      <InformationBlock
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              if (!driverData) {
                return;
              }

              const {
                first_name,
                last_name,
                phone_number,
                mfa_status,
                allow_payment_instrument,
                groups,
                validate_payment,
              } = driverData.result ?? {};

              setFirstName(first_name ?? '');
              setLastName(last_name ?? '');
              setPhoneNumber(phone_number ?? '');
              setDashboardAccess(!!groups?.includes('role:dashboard'));
              setTwoFA(mfa_status);
              setTravelCard(
                allow_payment_instrument &&
                  !!organisationData?.result.allow_payment_instruments &&
                  organisationData.result.payment_instruments_allowed,
              );
              setValidatePayment(validate_payment ?? true);
            },
            disabled: !hasNewDetails,
          },
          {
            variant: 'contained',
            label: translations.save,
            onClick: async () => {
              try {
                setLoading('details');

                if (
                  firstName !== (driverData?.result.first_name ?? '') ||
                  lastName !== (driverData?.result.last_name ?? '') ||
                  phoneNumber !== (driverData?.result.phone_number ?? '') ||
                  validatePayment !== (driverData?.result.validate_payment ?? true)
                ) {
                  await mutateAsyncEditDriverDetails({
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    validatePayment,
                  });
                }

                if (dashboardAccess !== !!driverData?.result.groups?.includes('role:dashboard')) {
                  await mutateAsyncEditDriverDashboardAccess({ id, enabled: dashboardAccess });
                }

                if (TwoFA !== driverData?.result.mfa_status) {
                  await mutateAsyncEditDriver2FA({ id, enabled: TwoFA });
                }

                if (
                  travelCard !==
                  (!!driverData?.result.allow_payment_instrument &&
                    !!organisationData?.result.allow_payment_instruments &&
                    organisationData.result.payment_instruments_allowed)
                ) {
                  await mutateAsyncEditDriverPaymentInstrument({ id, enabled: travelCard });
                }

                await refetchDriver();

                addToast({
                  title: translations.successfullyEditDetails,
                  severity: 'success',
                });
              } catch (error) {
                setLoading(false);
              }
            },
            loading: loading === 'details',
            disabled: !hasNewDetails,
          },
        ]}
        title={translations.accountDetails}
        items={[
          {
            label: translations.firstName,
            data: { value: firstName, onChange: setFirstName, onEnter: () => {}, required: true },
            type: 'editName',
          },
          {
            label: translations.lastName,
            data: { value: lastName, onChange: setLastName, onEnter: () => {}, required: true },
            type: 'editName',
          },
          {
            label: translations.email,
            data: { value: driverData?.result?.email ?? '' },
            type: 'text',
          },
          {
            label: translations.phoneNumber,
            data: {
              value: phoneNumber,
              onChange: setPhoneNumber,
              onEnter: () => {},
              required: false,
            },
            type: 'editPhoneNumber',
          },
          {
            label: translations.dateOfBirth,
            data: { value: driverData?.result?.date_of_birth ?? '' },
            type: 'dateDayMonthYear',
          },
          {
            label: translations.reference,
            data: { value: driverData?.result?.reference ?? '' },
            type: 'text',
          },
          {
            label: translations.startsOn,
            data: { value: driverData?.result?.start_date ?? '' },
            type: 'dateDayMonthYear',
          },
          {
            label: translations.endsOn,
            data: { value: driverData?.result?.end_date ?? '' },
            type: 'dateDayMonthYear',
          },
          {
            label: translations.status,
            data: {
              color: getLabelColor({ driver: driverData?.result?.status }),
              value: getStatus({ driver: driverData?.result?.status }),
            },
            type: 'label',
          },
          {
            label: translations.dasboardAccess,
            data: {
              value: dashboardAccess,
              onChange: setDashboardAccess,
            },
            type: 'switch',
          },
          {
            label: translations['2FA'],
            data: {
              value: TwoFA,
              onChange: setTwoFA,
            },
            type: 'switch',
          },
          {
            label: translations.travelCard,
            data: {
              value: travelCard,
              onChange: setTravelCard,
              disabled:
                !organisationData?.result.allow_payment_instruments ||
                !organisationData.result.payment_instruments_allowed,
            },
            type: 'switch',
          },
          {
            label: translations.validatePayment,
            data: {
              value: validatePayment,
              onChange: setValidatePayment,
            },
            type: 'switch',
          },
        ]}
      />
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              setPersonalEmail(driverData?.result.personal_info?.email ?? '');
              setPersonalPhoneNumber(driverData?.result.personal_info?.phone_number ?? '');
              setPersonalAddress(driverData?.result.personal_info?.address ?? '');
              setPersonalZipCode(driverData?.result.personal_info?.zipcode ?? '');
              setPersonalCity(driverData?.result.personal_info?.city ?? '');
              setPersonalAddressVerified(driverData?.result.personal_info?.verified ?? false);
            },
            disabled:
              personalEmail === (driverData?.result.personal_info?.email ?? '') &&
              personalPhoneNumber === (driverData?.result.personal_info?.phone_number ?? '') &&
              personalAddress === (driverData?.result.personal_info?.address ?? '') &&
              personalZipCode === (driverData?.result.personal_info?.zipcode ?? '') &&
              personalCity === (driverData?.result.personal_info?.city ?? '') &&
              personalAddressVerified === (driverData?.result.personal_info?.verified ?? false),
          },
          {
            variant: 'contained',
            label: translations.save,
            onClick: async () => {
              try {
                setLoading('personalInformation');
                await mutateAsyncEditDriverPersonalInformation({
                  id,
                  email: personalEmail,
                  phoneNumber: personalPhoneNumber,
                });
                addToast({
                  title: translations.successfullyEditPersonalInformation,
                  severity: 'success',
                });
              } catch (error) {
                // Do nothing
              }
            },
            loading: loading === 'personalInformation',
            disabled:
              personalEmail === (driverData?.result.personal_info?.email ?? '') &&
              personalPhoneNumber === (driverData?.result.personal_info?.phone_number ?? ''),
          },
        ]}
        title={translations.personalInformation}
        items={[
          {
            label: translations.email,
            data: {
              value: personalEmail,
              placeholder: translations.email,
              onChange: setPersonalEmail,
              onEnter: () => {},
              required: true,
            },
            type: 'editEmail',
          },
          {
            label: translations.phoneNumber,
            data: {
              value: personalPhoneNumber,
              onChange: setPersonalPhoneNumber,
              onEnter: () => {},
              required: true,
            },
            type: 'editPhoneNumber',
          },
          {
            label: translations.hasPaymentInformation,
            data: {
              value: !!driverData?.result?.personal_info?.has_payment_information,
              onChange: () => {},
              disabled: true,
            },
            type: 'switch',
          },
          {
            label: translations.address,
            data: {
              value: personalAddress,
              onChange: setPersonalAddress,
              onEnter: () => {},
              required: true,
              disabled: true,
            },
            type: 'editText',
          },
          {
            label: translations.zipCode,
            data: {
              value: personalZipCode,
              onChange: setPersonalZipCode,
              onEnter: () => {},
              required: true,
              disabled: true,
            },
            type: 'editText',
          },
          {
            label: translations.city,
            data: {
              value: personalCity,
              onChange: setPersonalCity,
              onEnter: () => {},
              required: true,
              disabled: true,
            },
            type: 'editText',
          },
          {
            label: translations.verified,
            data: {
              color: personalAddressVerified ? 'success.main' : 'error.main',
              value: personalAddressVerified
                ? translations.addressIsVerified
                : translations.addressIsNotVerified,
            },
            type: 'label',
          },
        ]}
      />
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock
        title={translations.driversLicense}
        items={[
          {
            label: translations.documentNumber,
            data: { value: driverData?.result?.document_number ?? '' },
            type: 'text',
          },
          {
            label: translations.expiryDate,
            data: { value: driverData?.result?.licence?.valid_to ?? '' },
            type: 'dateDayMonthYear',
          },
        ]}
      />
      {(driverData === undefined ||
        (driverData?.result.licence?.categories?.length ?? 0) !== 0) && (
        <Box sx={{ paddingTop: 2 }}>
          <Table
            columns={{
              name: { name: translations.name, type: 'text', flex: 1 },
              issuesOn: {
                name: translations.issuedOn,
                type: 'date',
                flex: 1,
              },
              expiryDate: {
                name: translations.expiryDate,
                type: 'date',
                flex: 1,
              },
            }}
            rows={(driverData?.result.licence?.categories ?? []).map(
              ({ name, issued_on, valid_to }) => ({
                id: name,
                name,
                issuesOn: issued_on,
                expiryDate: valid_to,
              }),
            )}
            loading={!driverData?.result.email}
            useMobileView={false}
          />
        </Box>
      )}
      <Box sx={{ height: theme.spacing(2) }} />
      <InformationBlock<string>
        footer={[
          {
            variant: 'outlined',
            label: translations.restore,
            onClick: () => {
              setOrganisationId(driverData?.result.organisation?.id ?? '');
            },
            disabled: organisationId === driverData?.result.organisation?.id,
          },
          {
            variant: 'contained',
            label: translations.save,
            onClick: async () => {
              try {
                setLoading('organisation');
                await mutateAsyncEditDriverOrganisation({ id, organisationId });
                addToast({
                  title: translations.successfullyEditOrganisation,
                  severity: 'success',
                });
              } catch (error) {
                // Do nothing
              }
            },
            loading: loading === 'organisation',
            disabled: organisationId === driverData?.result.organisation?.id,
          },
        ]}
        title={translations.organisation}
        items={[
          {
            label: translations.organisationName,
            fullWidth: false,
            data: {
              label: translations.organisationName,
              value: organisationId,
              onChange: setOrganisationId,
              options: (organisationsData?.result ?? []).map(({ id, name }) => ({
                id,
                label: name as CustomString,
              })),
              mode: 'single',
            },
            type: 'select_1',
          },
        ]}
      />
    </Box>
  );

  return (
    <Page
      name="driver"
      loading={!driverData}
      actions={
        driverData?.result?.email
          ? [
              {
                svgName: driverData?.result?.status === 'DEACTIVATED' ? 'playArrow' : 'stop',
                mode: 'closeOnClick',
                title:
                  driverData?.result?.status === 'DEACTIVATED'
                    ? translations.activate
                    : translations.deactivate,
                onClick: () => {
                  if (driverData?.result?.status === 'DEACTIVATED') {
                    setOpenActivate(true);
                  } else {
                    setOpenDeactivate(true);
                  }
                },
              },
              {
                svgName: 'hideSource',
                mode: 'closeOnClick',
                title: translations.anonymizeAccount,
                onClick: () => {
                  setForgetAccountEmail('');
                  setOpenAnonymizeAccount(true);
                },
              },
              {
                svgName: 'check',
                mode: 'closeOnClick',
                title: translations.acceptDriversLicense,
                onClick: () => {
                  setForgetAccountEmail('');
                  setDialogDocumentNumber(driverData?.result?.document_number ?? '');
                  setDialogValidTo(driverData?.result?.licence?.valid_to ?? '');

                  const AM = (driverData?.result.licence?.categories ?? []).find(
                    ({ name }) => name === 'AM',
                  );

                  const B = (driverData?.result.licence?.categories ?? []).find(
                    ({ name }) => name === 'B',
                  );

                  setDialogAMValidTo(AM?.valid_to ?? '');
                  setDialogAMIssuedOn(AM?.issued_on ?? '');
                  setDialogBValidTo(B?.valid_to ?? '');
                  setDialogBIssuedOn(B?.issued_on ?? '');
                  setOpenAcceptDriversLicense(true);
                },
              },
              {
                svgName: 'send',
                mode: 'closeAfterLoading',
                title: translations.resendInvite,
                onClick: async () => {
                  try {
                    await mutateAsyncSendDriverInvite({
                      organistaionId: organisationData?.result.id ?? '',
                      driverId: driverData?.result.id ?? '',
                    });
                    addToast({ title: translations.resendInviteSuccess, severity: 'success' });
                  } catch (error) {
                    // Do nothing
                  }
                },
                disabled:
                  driverData?.result.status === 'DEACTIVATED' ||
                  driverData?.result.status === 'ACTIVATED' ||
                  !organisationData?.result.id,
                loading: isLoadingSendDriverInvite,
              },
              {
                svgName: 'moneyOff',
                mode: 'closeAfterLoading',
                title: translations.remitInvoices,
                onClick: async () => {
                  try {
                    await mutateAsyncRemitDriverInvoices({
                      driverId: driverData?.result?.id,
                    });
                    addToast({ title: translations.remitInvoicesSucces, severity: 'success' });
                  } catch (error) {
                    // Do nothing
                  }
                },
                loading: isLoadingRemitDriverInvoices,
              },
            ]
          : []
      }
    >
      <>
        <Dialog
          title={translations.dialogActivateTitle}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenActivate(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncEditDriverVerified({ id, verified: true });

                  setOpenActivate(false);
                  addToast({
                    title: translations.dialogActivateSuccess,
                    severity: 'success',
                  });
                } catch (error) {
                  setOpenActivate(false);
                }
              },

              loading: isLoadingEditDriverVerified,
            },
          ]}
          open={openActivate}
          onChange={setOpenActivate}
        />
        <Dialog
          title={translations.dialogActivateTitle}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenDeactivate(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncEditDriverVerified({ id, verified: false });

                  setOpenDeactivate(false);
                  addToast({
                    title: translations.dialogDeactivateSuccess,
                    severity: 'success',
                  });
                } catch (error) {
                  setOpenDeactivate(false);
                }
              },

              loading: isLoadingEditDriverVerified,
            },
          ]}
          open={openDeactivate}
          onChange={setOpenDeactivate}
        >
          <>
            <Text>{translations.dialogDeactivateMessage}</Text>
          </>
        </Dialog>
        <Dialog
          title={translations.areYouSure}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenAnonymizeAccount(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncDataProtection({ id, email: driverData?.result.email ?? '' });

                  navigate({
                    name: 'drivers',
                    id: undefined,
                    searchParams: { page: 1, q: '' },
                    metaKey: false,
                  });
                  addToast({
                    title: translations.successfullyAnonymizedAccount,
                    severity: 'success',
                  });
                } catch (error) {
                  setOpenAnonymizeAccount(false);
                }
              },
              disabled: forgetAccountEmail !== driverData?.result?.email || !forgetAccountEmail,
              loading: isLoadingDataProtection,
            },
          ]}
          open={openAnonymizeAccount}
          onChange={setOpenAnonymizeAccount}
        >
          <>
            <Text>
              {replaceText({
                text: translations.anonymizeAccountMessage,
                replaceValues: [driverData?.result?.email ?? ''],
              })}
            </Text>
            <Box sx={{ paddingTop: 2 }}>
              <TextField
                label={translations.email}
                variant="outlined"
                placeholder={translations.email}
                value={forgetAccountEmail}
                onChange={setForgetAccountEmail}
                fullWidth={true}
                required={true}
                type="email"
              />
            </Box>
          </>
        </Dialog>
        <Dialog
          title={translations.acceptDriversLicense}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenAcceptDriversLicense(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncAddDriversLicense({
                    id,
                    documentNumber: dialogDocumentNumber,
                    validTo: dialogValidTo,
                    dateOfBirth: dialogDateOfBirth,
                    categories:
                      !!dialogBIssuedOn && !dialogAMIssuedOn
                        ? [{ validTo: dialogBValidTo, issuedOn: dialogBValidTo, name: 'B' }]
                        : !dialogBIssuedOn && !!dialogAMIssuedOn
                        ? [{ validTo: dialogAMValidTo, issuedOn: dialogAMValidTo, name: 'AM' }]
                        : !!dialogBIssuedOn && !!dialogAMIssuedOn
                        ? [
                            { validTo: dialogAMValidTo, issuedOn: dialogAMValidTo, name: 'AM' },
                            { validTo: dialogBValidTo, issuedOn: dialogBValidTo, name: 'B' },
                          ]
                        : [],
                  });
                  setOpenAcceptDriversLicense(false);
                  addToast({
                    title: translations.successfullyAddDriversLicense,
                    severity: 'success',
                  });
                } catch (error) {
                  // Do nothgin
                }
              },
              disabled:
                !dialogDocumentNumber ||
                !dialogValidTo ||
                !dialogDateOfBirth ||
                (!dialogAMIssuedOn && !dialogAMValidTo && !dialogBIssuedOn && !dialogBValidTo) ||
                (!dialogAMIssuedOn && !!dialogAMValidTo) ||
                (!!dialogAMIssuedOn && !dialogAMValidTo) ||
                (!dialogBIssuedOn && !!dialogBValidTo) ||
                (!!dialogBIssuedOn && !dialogBValidTo),
              loading: isLoadingAddDriversLicense,
            },
          ]}
          open={openAcceptDriversLicense}
          onChange={setOpenAcceptDriversLicense}
        >
          <>
            <Box sx={{ paddingTop: 1 }}>
              <TextField
                label={translations.documentNumber}
                variant="outlined"
                placeholder={translations.documentNumber}
                value={dialogDocumentNumber}
                onChange={setDialogDocumentNumber}
                fullWidth={true}
                required={true}
                type="text"
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.expiryDate}
                value={dialogValidTo}
                onChange={(date) => {
                  setDialogValidTo(date);
                }}
                required={true}
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.dateOfBirth}
                value={dialogDateOfBirth}
                onChange={(date) => {
                  setDialogDateOfBirth(date);
                }}
                required={true}
              />
            </Box>
            <Box sx={{ paddingTop: 1 }}>
              <Text>{translations.AM}</Text>
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.expiryDate}
                value={dialogAMValidTo}
                onChange={(date) => {
                  setDialogAMValidTo(date);
                }}
                required={true}
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.issuedOn}
                value={dialogAMIssuedOn}
                onChange={(date) => {
                  setDialogAMIssuedOn(date);
                }}
                required={true}
              />
            </Box>
            <Box sx={{ paddingTop: 1 }}>
              <Text>{translations.B}</Text>
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.expiryDate}
                value={dialogBValidTo}
                onChange={(date) => {
                  setDialogBValidTo(date);
                }}
                required={true}
              />
            </Box>
            <Box sx={{ paddingTop: 2 }}>
              <DatePicker
                label={translations.issuedOn}
                value={dialogBIssuedOn}
                onChange={(date) => {
                  setDialogBIssuedOn(date);
                }}
                required={true}
              />
            </Box>
          </>
        </Dialog>
        <TableTabs
          variant="content"
          data={
            (driverData?.result?.travel_cards ?? []).length > 0
              ? [
                  { label: translations.details, content: DetailsContent },
                  { label: translations.reservations, content: ReservationsContent },
                  { label: translations.communities, content: CommunityContent },
                  { label: translations.wallet, content: WalletContent },
                  { label: translations.travelCard, content: <TravelCardContent /> },
                  { label: translations.providers, content: ProviderContent },
                ]
              : [
                  { label: translations.details, content: DetailsContent },
                  { label: translations.reservations, content: ReservationsContent },
                  { label: translations.communities, content: CommunityContent },
                  { label: translations.wallet, content: WalletContent },
                  { label: translations.providers, content: ProviderContent },
                ]
          }
          tabIndex={tabIndex}
          setTabIndex={(index) => {
            setSearchParams({ reservationsPage, tab: index });
            setTabIndex(index);
          }}
        />
      </>
    </Page>
  );
}

export { DriverPage };
