import React from 'react';

import { getAppEnviroment } from 'config/shared';

type Mode = 'light' | 'dark';

interface DarkModeContextProps {
  mode: Mode;
  setMode: (mode: Mode) => void;
}

const DarkModeContext = React.createContext<DarkModeContextProps>({
  mode: 'light',
  setMode: () => {},
});

interface DarkModeProviderProps {
  children: JSX.Element;
}

function DarkModeProvider({ children }: DarkModeProviderProps) {
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    localStorage.getItem('darkMode') === 'light'
      ? 'light'
      : localStorage.getItem('darkMode') === 'dark'
      ? 'dark'
      : window.matchMedia('(prefers-color-scheme: dark)').matches &&
        getAppEnviroment() !== 'PRODUCTION'
      ? 'dark'
      : 'light',
  );

  return <DarkModeContext.Provider value={{ mode, setMode }}>{children}</DarkModeContext.Provider>;
}

export { DarkModeContext, DarkModeProvider, type Mode };
