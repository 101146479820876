import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';

import config from 'config/cognito.json';
import { getAppEnviroment } from 'config/shared';

import { ExtendedCognitoUser } from './context';

function cognitoUserHasDashboardRole(user: ExtendedCognitoUser): boolean {
  return user.signInUserSession.accessToken.payload['cognito:groups'].includes('role:admin');
}

const authenticationErrorNoAccess =
  'You do not have access to use the platform. Please try again with a different account.';

function loadCognito() {
  const { REGION, USER_POOL_ID, APP_CLIENT_ID } = config[getAppEnviroment()];

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: APP_CLIENT_ID,
    },
  });
}

async function logoutCognitoUser() {
  Auth.signOut()
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

async function getCognitoToken() {
  Auth.currentSession()
    .then((session) => Promise.resolve(session.getAccessToken().getJwtToken()))
    .catch((error) => Promise.reject(error));
}

function getPasswordForgottenCognitoUrl() {
  const { PROJECT, REGION, APP_CLIENT_ID, REDIRECT_URL } = config[getAppEnviroment()];
  return `https://${PROJECT}.auth.${REGION}.amazoncognito.com/forgotPassword?client_id=${APP_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code`;
}

export {
  authenticationErrorNoAccess,
  cognitoUserHasDashboardRole,
  getCognitoToken,
  getPasswordForgottenCognitoUrl,
  loadCognito,
  logoutCognitoUser,
};
