// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-syntax */
import { PaletteMode, PaletteOptions, ThemeOptions } from '@mui/material';

const primaryMain = '#FFB006';
const secondaryMain = '#1C2536';

function getTheme(mode: PaletteMode): ThemeOptions {
  const defaultOptions: ThemeOptions = {
    spacing: 8,
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: 'Muli',
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            '& .MuiPaginationItem-circular': {
              backgroundColor: secondaryMain,
              color: 'white',
            },
            '& .MuiPaginationItem-previousNext': {
              backgroundColor: 'transparent',
              color: secondaryMain,
            },
            '& .MuiPaginationItem-ellipsis': {
              backgroundColor: 'transparent',
              color: secondaryMain,
            },
            '& .Mui-selected': {
              backgroundColor: primaryMain,
            },
          },
        },
      },
    },
  };

  const defaultPalette: PaletteOptions = {
    mode,
    primary: { main: primaryMain },
    secondary: { main: secondaryMain },
    background: {
      default: '#F5F4F2',
      paper: '#FFFFFF',
    },
    error: { main: '#b20000' },
    warning: { main: primaryMain },
    info: { main: '#5f63de' },
    success: { main: '#89b424' },
    common: {
      black: '#121212',
      white: '#FFFDFA',
    },
    tonalOffset: { light: 0.6, dark: 0.6 },
  };

  if (mode === 'light') {
    return {
      ...defaultOptions,
      palette: {
        ...defaultPalette,
      },
    };
  }

  return {
    ...defaultOptions,
    typography: {
      ...defaultOptions.typography,
      allVariants: {
        color: '#FFFDFA',
      },
    },
    components: {
      ...defaultOptions.components,
      MuiButton: {
        styleOverrides: {
          outlined: {
            color: '#CC8B00',
            borderColor: '#CC8B00',
          },
        },
      },
    },
    palette: {
      ...defaultPalette,
      secondary: { main: secondaryMain },
      background: {
        default: '#161616',
        paper: '#383838',
      },
    },
  };
}

export { getTheme, secondaryMain };
