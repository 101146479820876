import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams as useSearchParamsReact } from 'react-router-dom';

import { SearchParamsMapping, searchParamsMapping } from 'config/searchParams';

type SearchParams = {
  [Key in keyof SearchParamsMapping]: SearchParamsMapping[Key] extends undefined
    ? undefined
    : {
        [Key2 in keyof SearchParamsMapping[Key]]: SearchParamsMapping[Key][Key2] | undefined;
      };
};

function useSearchParams<Key extends keyof SearchParamsMapping>(
  key: Key,
): {
  searchParams: SearchParams[Key];
  setSearchParams: (params: SearchParamsMapping[Key]) => void;
} {
  const [searchParams, setSearchParams] = React.useState<
    Record<string, string | number | undefined>
  >({});
  const [searchParamsReact, setSearchParamsReact] = useSearchParamsReact();

  React.useEffect(() => {
    const mapping = searchParamsMapping[key];
    if (mapping === undefined) {
      setSearchParams({});
      return;
    }

    const result: Record<string, string | number | undefined> = {};
    const localMapping: Record<string, string | number> = { ...mapping };
    Object.keys(localMapping).forEach((key) => {
      result[key] = searchParamsReact.get(key) ?? undefined;
      if (
        typeof result[key] === 'string' &&
        localMapping[key as keyof typeof localMapping] === 'number'
      ) {
        const value = parseInt(result[key] as string);
        result[key] = Number.isNaN(value) ? undefined : value;
      }
    });

    setSearchParams(result);
  }, [searchParamsReact]);

  function setLocalSearchParams(params: SearchParamsMapping[Key]) {
    if (params === undefined) {
      return;
    }

    const convertedParams: Record<string, string> = {};
    const localParams: Record<string, string | number> = { ...params };
    Object.keys(localParams).forEach((key) => {
      convertedParams[key as string] = localParams[key as keyof typeof localParams].toString();
    });

    setSearchParamsReact(convertedParams, { replace: true });
  }

  return { searchParams: searchParams as SearchParams[Key], setSearchParams: setLocalSearchParams };
}

export { useSearchParams };
