import { Box, Tooltip, Unstable_Grid2 as Grid, useTheme } from '@mui/material';
import { Color } from 'types/shared';

import { useDarkMode } from 'core/darkMode/hooks';
import { formatDate, textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';

import { Button, ButtonActions, ButtonProps, ButtonPropsWithoutSize } from './Button';
import { DatePicker } from './DatePicker';
import { DateTimePicker } from './DateTimePicker';
import { Label } from './Label';
import { Link } from './Link';
import { Select, SelectProps } from './Select';
import { getSVG, SVGName } from './SVG';
import { SVGButton } from './SVGButton';
import { Switch } from './Switch';
import { Text } from './Text';
import { TextField } from './TextField';
import { getVerticalStackSx, VerticalPosition } from './VerticalStack';

type Adornment = 'EUR' | 'USD' | 'GBP';

type Item<SelectIds1 = never, SelectIds2 = never, SelectIds3 = never, SelectIds4 = never> =
  | {
      type: 'price';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: number;
        adornment: Adornment;
      };
    }
  | {
      type: 'text';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
      };
    }
  | {
      type: 'dateLong';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
      };
    }
  | {
      type: 'editDate';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
        onChange: (date: string) => void;
        onEnter: () => void;
        disabled?: boolean;
        required: boolean;
      };
    }
  | {
      type: 'editDateTime';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
        onChange: (date: string) => void;
        onEnter: () => void;
        disabled?: boolean;
        required: boolean;
      };
    }
  | {
      type: 'dateDayMonthYear';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
      };
    }
  | {
      type: 'label';
      label: CustomString;
      fullWidth?: undefined;
      data: {
        color: Color;
        value: string;
      };
    }
  | {
      type: 'editText' | 'editEmail' | 'editPhoneNumber' | 'editName';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: string;
        placeholder?: string;
        onChange: (value: string) => void;
        onEnter: () => void;
        disabled?: boolean;
        required: boolean;
        multiline?: boolean;
      };
    }
  | {
      type: 'editNumber';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: number;
        placeholder?: number;
        onChange: (value: number) => void;
        onEnter: () => void;
        disabled?: boolean;
        required: boolean;
        adornment?: Adornment;
      };
    }
  | {
      type: 'switch';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        value: boolean;
        placeholder?: number;
        onChange: (value: boolean) => void;
        disabled?: boolean;
      };
    }
  | {
      type: 'button';
      label: CustomString;
      fullWidth?: boolean;
      data: ButtonPropsWithoutSize;
    }
  | {
      type: 'svg';
      label: CustomString;
      fullWidth?: boolean;
      data: {
        svgName: SVGName;
        tooltip: string;
        disabled?: boolean;
      };
    }
  | {
      type: 'select_1';
      label: CustomString;
      fullWidth?: boolean;
      data: SelectProps<SelectIds1>;
    }
  | {
      type: 'select_2';
      label: CustomString;
      fullWidth?: boolean;
      data: SelectProps<SelectIds2>;
    }
  | {
      type: 'select_3';
      label: CustomString;
      fullWidth?: boolean;
      data: SelectProps<SelectIds3>;
    }
  | {
      type: 'select_4';
      label: CustomString;
      fullWidth?: boolean;
      data: SelectProps<SelectIds4>;
    };

type LinkItemProps = 'driverPage' | 'driverReservations' | 'url';

interface LinkProps {
  to: LinkItemProps;
  value: string;
}

type InformationBlockProps<
  SelectIds1 = never,
  SelectIds2 = never,
  SelectIds3 = never,
  SelectIds4 = never,
> =
  | ({
      title?: string;
      link?: undefined;
      items: Item<SelectIds1, SelectIds2, SelectIds3, SelectIds4>[];
      footer?: ButtonProps[];
      useSingleActionWithoutPopover?: boolean;
    } & ButtonActions &
      VerticalPosition)
  | ({
      title: string;
      link: LinkProps;
      items: Item<SelectIds1, SelectIds2, SelectIds3, SelectIds4>[];
      footer?: ButtonProps[];
      useSingleActionWithoutPopover?: boolean;
    } & ButtonActions &
      VerticalPosition);

function InformationBlock<
  SelectIds1 = never,
  SelectIds2 = never,
  SelectIds3 = never,
  SelectIds4 = never,
>({
  title,
  link,
  items,
  footer,
  useSingleActionWithoutPopover,
  actions,
  verticalPosition,
}: InformationBlockProps<SelectIds1, SelectIds2, SelectIds3, SelectIds4>) {
  const theme = useTheme();
  const { mode } = useDarkMode();
  const translations = useTranslations('button');
  const { navigate } = useNavigate();

  const { hasMobileView } = useHasMobileView();

  const convertedItems = items.map((item) => {
    if (item.type === 'price') {
      return {
        ...item,
        data: { ...item.data, value: (item.data.value / 100).toFixed(2) },
      };
    }

    if (item.type === 'dateLong') {
      return {
        ...item,
        data: { ...item.data, value: formatDate({ date: item.data.value, variant: 'long' }) },
      };
    }

    if (item.type === 'dateDayMonthYear') {
      return {
        ...item,
        data: {
          ...item.data,
          value: formatDate({ date: item.data.value, variant: 'dayMonthYear' }),
        },
      };
    }

    return item;
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        padding: 2,
        border: 1,
        borderColor: 'divider',
        alignItems: 'flex-start',
        borderRadius: 1,
        position: 'relative',
        maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
        ...getVerticalStackSx({ theme, verticalPosition }),
      }}
    >
      {!!title && (
        <Box
          sx={{
            paddingBottom: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: 1, wordBreak: 'break-word', minWidth: 0 }}>
            {link && link.to === 'driverReservations' ? (
              <Link
                svg={{ svgName: 'launch', color: 'common.white', size: 'small' }}
                underline="none"
                onClick={() => {
                  navigate({
                    name: 'driver',
                    id: link.value,
                    searchParams: { reservationsPage: 1, tab: 1 },
                    metaKey: false,
                  });
                }}
              >
                <Text.Bold>{title}</Text.Bold>
              </Link>
            ) : (
              <Text.Bold>{title}</Text.Bold>
            )}
          </Box>
          <Box sx={{ paddingLeft: 2 }}>
            {!!actions && actions.length > 1 && (
              <Button size="small" label={translations.actions} actions={actions} />
            )}
            {!!actions && !useSingleActionWithoutPopover && actions.length === 1 && (
              <Button size="small" label={translations.actions} actions={actions} />
            )}
            {!!actions && useSingleActionWithoutPopover && actions.length === 1 && (
              <Button
                label={actions[0].title}
                onClick={actions[0].onClick ?? (() => {})}
                size="small"
                svgName={actions[0].svgName}
              />
            )}
          </Box>
        </Box>
      )}
      <Grid container spacing={2}>
        {convertedItems.map(({ type, label, data, fullWidth }, index) => {
          const CustomSVG = type === 'svg' ? getSVG(data.svgName) : undefined;

          return (
            <Grid
              xs={12}
              sm={12}
              md={fullWidth ? 12 : 6}
              xl={fullWidth ? 12 : 4}
              key={
                ((data as unknown as { placeholder?: string }).placeholder ?? label.toString()) +
                index.toString()
              }
              alignSelf="flex-start"
            >
              {type === 'label' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: hasMobileView ? 'row' : 'column',
                    justifyContent: hasMobileView ? 'space-between' : undefined,
                  }}
                >
                  <Text.Bold>{label}</Text.Bold>
                  <Label title={data.value} color={data.color} />
                </Box>
              )}
              {(type === 'text' || type === 'dateLong' || type === 'dateDayMonthYear') && (
                <TextField
                  type="text"
                  key={label + index}
                  variant="outlined"
                  label={label}
                  onChange={() => {}}
                  value={data.value !== '' ? data.value : '-'}
                  disabled
                  multiline
                  fullWidth={fullWidth}
                />
              )}

              {type === 'price' && (
                <TextField
                  type="text"
                  key={label + index}
                  variant="outlined"
                  label={label}
                  onChange={() => {}}
                  value={data.value !== '' ? data.value : '-'}
                  adornment={data.adornment}
                  disabled
                  multiline
                  fullWidth={fullWidth}
                />
              )}
              {type === 'editNumber' && (
                <TextField
                  label={label}
                  variant="outlined"
                  placeholder={(data?.placeholder ?? 0).toString()}
                  value={data.value}
                  onChange={data.onChange}
                  onKeyEnterDown={data.onEnter}
                  fullWidth={fullWidth}
                  type="number"
                  disabled={data.disabled}
                  required={data.required && !data.disabled}
                  adornment={data.adornment}
                />
              )}
              {(type === 'editText' ||
                type === 'editEmail' ||
                type === 'editPhoneNumber' ||
                type === 'editName') && (
                <TextField
                  key={(data?.placeholder ?? label) + index}
                  label={label}
                  variant="outlined"
                  placeholder={data?.placeholder}
                  value={data.value}
                  onChange={data.onChange}
                  onKeyEnterDown={data.onEnter}
                  fullWidth={fullWidth}
                  disabled={data.disabled}
                  type={
                    type === 'editText'
                      ? 'text'
                      : type === 'editEmail'
                      ? 'email'
                      : type === 'editPhoneNumber'
                      ? 'phoneNumber'
                      : type === 'editName'
                      ? 'name'
                      : 'text'
                  }
                  required={data.required && !data.disabled}
                  multiline={data.multiline}
                />
              )}

              {type === 'editDate' && (
                <DatePicker
                  label={label}
                  value={data.value}
                  onChange={data.onChange}
                  required={data.required}
                  disabled={data.disabled}
                />
              )}
              {type === 'editDateTime' && (
                <DateTimePicker
                  label={label}
                  value={data.value}
                  onChange={data.onChange}
                  required={data.required}
                  disabled={data.disabled}
                />
              )}
              {type === 'switch' && (
                <Switch
                  label={label}
                  checked={data.value}
                  onChange={data.onChange}
                  disabled={data.disabled}
                />
              )}
              {type === 'button' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: fullWidth ? '100%' : textFieldWidth,
                  }}
                >
                  <Text>{label}</Text>
                  <Button {...(data as ButtonProps)} size="small" />
                </Box>
              )}
              {type === 'svg' && !!CustomSVG && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: fullWidth ? '100%' : textFieldWidth,
                  }}
                >
                  <Box sx={{ display: 'flex', flex: 1 }}>
                    <Text>{label}</Text>
                  </Box>

                  <Tooltip title={data.tooltip}>
                    <Box
                      sx={{
                        justifyContent: 'center',
                        paddingRight: 1,
                      }}
                    >
                      <CustomSVG
                        size="small"
                        color={mode === 'dark' ? 'common.white' : 'common.black'}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {(type === 'select_1' ||
                type === 'select_2' ||
                type === 'select_3' ||
                type === 'select_4') && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: fullWidth ? '100%' : textFieldWidth,
                  }}
                >
                  <Select
                    {...(data as unknown as SelectProps<string>)}
                    label={label}
                    fullWidth={fullWidth}
                  />
                  {!data.disabled && data.mode === 'single' && !!data.onReset && (
                    <Box sx={{ width: theme.spacing(2) }} />
                  )}
                  {!data.disabled && data.mode === 'single' && !!data.onReset && (
                    <SVGButton
                      svgName="close"
                      color="primary"
                      size="small"
                      onClick={() => {
                        data.onReset && data.onReset();
                      }}
                      disabled={!data.value}
                    />
                  )}
                </Box>
              )}
            </Grid>
          );
        })}
      </Grid>
      {footer && footer.length > 0 && (
        <Grid
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          {footer.map((props, index) => (
            <Grid key={index} sx={{ paddingLeft: 1 }}>
              <Button {...props} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export { InformationBlock, type InformationBlockProps };
