import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Button, Dialog, InformationBlock, Page, Table, Text } from 'components';
import { parseCSV } from 'core/csv';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { UseMutation } from 'useQuery';

function PushNotificationsPage() {
  const translations = useTranslations('pushNotificationsPage');
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();
  const { addToast } = useToast();

  const {
    mutateAsync: mutateAsyncSendPushNotifications,
    isLoading: isLoadingSendPushNotifications,
  } = UseMutation.sendPushNotifications();

  type Fields = 'id' | 'first_name' | 'last_name' | 'email';

  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [file, setFile] = React.useState<File>();
  const [rawData, setRawData] = React.useState<Record<Fields, string>[]>([]);
  const [data, setData] = React.useState<Record<Fields, string>[][]>([]);
  const [error, setError] = React.useState<Error>();

  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!file) {
      setRawData([]);
      setData([]);
      return;
    }

    parseCSV<Fields>({ fields: ['id', 'first_name', 'last_name', 'email'], file }).then(
      ({ data: localData, error }) => {
        const result: Record<Fields, string>[][] = [];
        let counter = 0;
        let subSet: Record<Fields, string>[] = [];
        localData.forEach((item) => {
          counter += 1;
          subSet.push(item);

          if (counter === (hasMobileView ? 10 : 25)) {
            counter = 0;
            result.push([...subSet]);
            subSet = [];
          }
        });

        setRawData(localData);
        setData(result);
        setError(error);
      },
    );
  }, [file, hasMobileView]);

  return (
    <Page
      name="pushNotifications"
      actions={[
        {
          svgName: 'fileUpload',
          title: translations.uploadCSV,
          mode: 'fileUpload',
          onFile: setFile,
        },
      ]}
    >
      <>
        <Dialog
          title={replaceText({ text: translations.dialogTitle, replaceValues: [rawData.length] })}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpen(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  await mutateAsyncSendPushNotifications({
                    ids: rawData.map(({ id }) => id),
                    title,
                    body,
                  });

                  setTitle('');
                  setBody('');
                  setFile(undefined);

                  addToast({ title: translations.dialogSuccess, severity: 'success' });
                  setOpen(false);
                } catch (error) {
                  setOpen(false);
                }
              },
              loading: isLoadingSendPushNotifications,
            },
          ]}
          open={open}
          onChange={setOpen}
        >
          <Text.Bold>{title}</Text.Bold>
          <Box sx={{ height: theme.spacing(1) }} />
          <Text>{body}</Text>
        </Dialog>
        <InformationBlock
          title={translations.content}
          items={[
            {
              label: replaceText({
                text: translations.title,
                replaceValues: [title.length, 65],
              }),
              fullWidth: true,
              data: {
                value: title,
                placeholder: translations.titleSingle,
                onChange: setTitle,
                onEnter: () => {},
                required: true,
              },
              type: 'editText',
            },
            {
              label: replaceText({
                text: translations.body,
                replaceValues: [body.length, 113],
              }),
              fullWidth: true,
              data: {
                value: body,
                placeholder: translations.bodySingle,
                onChange: setBody,
                onEnter: () => {},
                required: true,
                multiline: true,
              },
              type: 'editText',
            },
          ]}
        />
        <Box sx={{ width: '100%', paddingTop: 2, display: 'flex', alignItems: 'center' }}>
          {rawData.length > 0 && (
            <Text>
              {replaceText({ text: translations.imported, replaceValues: [rawData.length] })}
            </Text>
          )}
          <Box sx={{ display: 'flex', flex: 1 }} />
          <Button
            label={translations.send}
            onClick={() => {
              setOpen(true);
            }}
            disabled={
              !!error ||
              rawData.length === 0 ||
              title.length === 0 ||
              title.length > 65 ||
              body.length === 0 ||
              body.length > 113
            }
          />
        </Box>
        <Box sx={{ height: theme.spacing(2) }} />
        <Table
          columns={{
            firstName: { name: translations.firstName, type: 'text', flex: 1 },
            lastName: { name: translations.lastName, type: 'text', flex: 1 },
            email: { name: translations.email, type: 'text', flex: 1 },
          }}
          rows={(data[page - 1] ?? []).map(({ id, first_name, last_name, email }) => ({
            id,
            firstName: first_name,
            lastName: last_name,
            email,
          }))}
          loading={false}
          pagination={{
            count: data.length,
            page,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
        {error && (
          <Box sx={{ paddingTop: 2 }}>
            <Text color="error.main">{error.message}</Text>
          </Box>
        )}
      </>
    </Page>
  );
}

export { PushNotificationsPage };
