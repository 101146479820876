// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

function useEditMerchantCategoryCodes() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface EditMerchantCategoryCodesProps {
    organisationId: string;
    merchantCategoryCodes: string[];
  }

  interface Body {
    organisation_id: string;
    data: { merchant_category_codes: string[] };
  }

  async function editMerchantCategoryCodes({
    organisationId,
    merchantCategoryCodes,
  }: EditMerchantCategoryCodesProps) {
    const { data } = await API.put<void, Body>({
      path: `/transaction-rules`,
      token,
      version: 1,
      body: {
        organisation_id: organisationId,
        data: { merchant_category_codes: merchantCategoryCodes },
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, EditMerchantCategoryCodesProps>(
    (data: EditMerchantCategoryCodesProps) => editMerchantCategoryCodes(data),
    {
      onSuccess: async (_, { organisationId }) => {
        await queryClient.invalidateQueries(['organisation', { id: organisationId }]);
        await queryClient.invalidateQueries(['organisations']);
      },
    },
  );
}

function useMerchantCategoryCodes() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Response {
    result: Record<string, string>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: '/admin/utility/mcc',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['merchantCategoryCodes'],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

export { useEditMerchantCategoryCodes, useMerchantCategoryCodes };
