import React from 'react';

function usePageWidth() {
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setPageWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setPageWidth(window.innerWidth);
      });
    };
  }, []);

  return pageWidth;
}

export { usePageWidth };
