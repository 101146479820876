import React from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  // eslint-disable-next-line no-restricted-imports
  Select as SelectMUI,
  // eslint-disable-next-line no-restricted-imports
  TextField,
  useTheme,
} from '@mui/material';

import { textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { usePageWidth } from 'hooks/usePageWidth';

import { CustomString } from 'config/translations';

type SelectProps<Ids> =
  | {
      mode: 'single';
      label: CustomString;
      value: Ids | undefined;
      values?: undefined;
      options: { id: Ids; label: CustomString }[];
      onChange: (id: Ids) => void;
      onInput?: (event: any) => void;
      onReset?: () => void;
      disabled?: boolean;
      required?: boolean;
      fullWidth?: boolean;
    }
  | {
      mode: 'multiple';
      label: CustomString;
      value?: undefined;
      values: Ids[];
      options: { id: Ids; label: CustomString }[];
      onChange: (ids: Ids[]) => void;
      onInput?: (event: any) => void;
      onReset?: undefined;
      disabled?: boolean;
      required?: undefined;
      fullWidth?: boolean;
    };

function Select<Ids extends string>({
  mode,
  label,
  value,
  values,
  options,
  onChange,
  onInput,
  disabled,
  required,
  fullWidth,
}: SelectProps<Ids>) {
  const theme = useTheme();
  const pageWidth = usePageWidth();

  const { hasMobileView } = useHasMobileView();

  const [convertedOptions, setConvertedOptions] = React.useState<typeof options>([]);

  React.useEffect(() => {
    const usedOptionLabels: Record<string, number> = {};

    const newConvertedOptions: typeof options = options.map((option) => {
      if (usedOptionLabels[option.label] !== undefined) {
        usedOptionLabels[option.label] += 1;
        return {
          ...option,
          label: `${option.label} (${usedOptionLabels[option.label] - 1})` as CustomString,
        };
      }

      usedOptionLabels[option.label] = 1;
      return option;
    });

    setConvertedOptions(newConvertedOptions);
  }, [options]);

  if (mode === 'multiple') {
    return (
      <Autocomplete
        multiple
        value={values}
        id={`${JSON.stringify(convertedOptions)}id`}
        sx={{
          width: fullWidth ? '100%' : textFieldWidth,
          maxWidth: pageWidth - parseInt(theme.spacing(10).replace('px', ''), 10),
        }}
        options={convertedOptions.map(({ id }) => id)}
        getOptionLabel={(option) =>
          convertedOptions.find(({ id }) => option === id)?.label ?? option
        }
        onChange={(_, values) => {
          onChange(values);
        }}
        onInputChange={onInput}
        defaultValue={[]}
        filterSelectedOptions
        renderInput={(params) => {
          return <TextField {...params} label={label} />;
        }}
        disabled={disabled}
        size={hasMobileView ? 'small' : undefined}
      />
    );
  }

  return (
    <FormControl
      sx={{
        width: fullWidth ? '100%' : textFieldWidth,
      }}
      disabled={disabled}
      required={required}
      size={hasMobileView ? 'small' : undefined}
    >
      <InputLabel id={JSON.stringify(options)}>{label}</InputLabel>
      <SelectMUI
        sx={{
          '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        labelId={JSON.stringify(options)}
        id={`${JSON.stringify(options)}id`}
        value={value ?? ''}
        label={label}
        onChange={({ target }) => {
          onChange(target.value as Ids);
        }}
      >
        {options.map(({ id, label }, index) => (
          <MenuItem key={index} value={id}>
            {label}
          </MenuItem>
        ))}
      </SelectMUI>
    </FormControl>
  );
}

export { Select, type SelectProps };
