import React from 'react';
import { Box, useTheme } from '@mui/material';

import { ButtonProps, Dialog, InformationBlock, Page, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'hooks/useParams';
import { replaceText, useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseMutation, UseQuery } from 'useQuery';

function CommunityPage() {
  const [name, setName] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [users, setUsers] = React.useState<string[]>([]);
  const [fleets, setFleets] = React.useState<string[]>([]);

  const [usersQuery, setUsersQuery] = React.useState<string>('');

  const theme = useTheme();

  const { id } = useParams('community');
  const translations = useTranslations('communityPage');
  const { navigate } = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [deleteCheck, setDeleteCheck] = React.useState('');

  const { addToast } = useToast();

  const { mutateAsync: mutateAsyncDeleteCommunity, isLoading: isLoadingDeleteCommunity } =
    UseMutation.deleteCommunity();

  const { mutateAsync: mutateAsyncAddCommunity, isLoading: isLoadingAddCommunity } =
    UseMutation.addCommunity();

  const { mutateAsync: mutateAsyncEditCommunity, isLoading: isLoadingEditCommunity } =
    UseMutation.editCommunity();

  const { data: communityData, isLoading: isLoadingCommunityData } = UseQuery.community({ id });
  const { data: fleetsData, isLoading: isLoadingFleetsData } = UseQuery.fleets({
    page: 1,
    limit: -1,
  });
  const { data: driversData, isLoading: isLoadingDriversData } = UseQuery.drivers({
    page: 1,
    limit: 25,
    query: usersQuery,
  });

  const isLoading =
    isLoadingCommunityData ||
    isLoadingFleetsData ||
    isLoadingDriversData ||
    (communityData === undefined && id !== 'new') ||
    fleetsData === undefined ||
    driversData === undefined;

  React.useEffect(() => {
    const result = communityData?.result;

    if (!result) {
      return;
    }

    const { name, description, users, fleets } = result;

    setName(name);
    setDescription(description);
    setUsers((users ?? []).map(({ id }) => id));
    setFleets((fleets ?? []).map(({ id }) => id));
  }, [communityData]);

  const hasNewData =
    id !== 'new' &&
    (name !== communityData?.result?.name ||
      description !== communityData?.result?.description ||
      JSON.stringify(users.sort()) !==
        JSON.stringify((communityData?.result?.users ?? []).map(({ id }) => id).sort()) ||
      JSON.stringify(fleets.sort()) !==
        JSON.stringify((communityData?.result?.fleets ?? []).map(({ id }) => id).sort()));

  async function addCommunity() {
    try {
      await mutateAsyncAddCommunity({ name, description, fleets, users });
      navigate({
        name: 'communities',
        id: undefined,
        searchParams: { page: 1, q: '' },
        metaKey: false,
      });
      addToast({ title: translations.successfullyAdded, severity: 'success' });
      return Promise.resolve();
    } catch (error) {
      // Do nothing
    }
  }

  async function editCommunity() {
    try {
      await mutateAsyncEditCommunity({ id, name, description, fleets, users });
      navigate({
        name: 'communities',
        id: undefined,
        searchParams: { page: 1, q: '' },
        metaKey: false,
      });
      addToast({ title: translations.successfullyEdited, severity: 'success' });
      return Promise.resolve();
    } catch (error) {
      // Do nothing
    }
  }

  const footer: ButtonProps[] =
    (communityData !== undefined && communityData?.result.is_editable) || id === 'new'
      ? [
          {
            label: id === 'new' ? translations.cancel : translations.restore,
            variant: 'outlined',
            onClick: () => {
              if (id === 'new') {
                navigate({
                  name: 'vouchers',
                  id: undefined,
                  searchParams: { page: 1 },
                  metaKey: false,
                });
              } else {
                const result = communityData?.result;

                if (!result) {
                  return;
                }

                const { name, description, users, fleets } = result;

                setName(name);
                setDescription(description);
                setUsers(users ? users.map(({ id }) => id) : []);
                setFleets(fleets ? fleets.map(({ id }) => id) : []);
              }
            },
            disabled:
              (id !== 'new' && !hasNewData) ||
              isLoading ||
              (id === 'new' &&
                (!name || !description || fleets.length === 0 || users.length === 0)),
          },
          {
            label: id === 'new' ? translations.save : translations.update,
            onClick: () => {
              id === 'new' ? addCommunity() : editCommunity();
            },
            loading: isLoadingAddCommunity || isLoadingEditCommunity,
            disabled:
              (id !== 'new' && !hasNewData) ||
              isLoading ||
              (id === 'new' &&
                (!name || !description || fleets.length === 0 || users.length === 0)),
          },
        ]
      : [];

  return (
    <Page
      name="community"
      footer={footer}
      loading={isLoading}
      actions={[
        {
          svgName: 'delete',
          title: translations.deleteCommunity,
          mode: 'closeOnClick',
          onClick: () => {
            setOpen(true);
          },
        },
      ]}
    >
      <Dialog
        title={translations.areYouSure}
        footer={[
          {
            variant: 'outlined',
            label: translations.cancel,
            onClick: () => {
              setOpen(false);
              setDeleteCheck('');
            },
            disabled: false,
          },
          {
            variant: 'contained',
            label: translations.confirm,
            onClick: async () => {
              try {
                await mutateAsyncDeleteCommunity({ id });
                setOpen(false);
                navigate({
                  name: 'communities',
                  id: undefined,
                  searchParams: { page: 1, q: '' },
                  metaKey: false,
                });
                addToast({ title: translations.deleteCommunitySuccess, severity: 'success' });
              } catch (error) {
                setOpen(false);
              }
            },
            loading: isLoadingDeleteCommunity,
            disabled: deleteCheck !== communityData?.result.name,
          },
        ]}
        open={open}
        onChange={(value) => {
          setOpen(value);
          setDeleteCheck('');
        }}
      >
        <Text>
          {replaceText({
            text: translations.dialogDeleteMessage,
            replaceValues: [communityData?.result?.name ?? ''],
          })}
        </Text>
        <Box sx={{ height: theme.spacing(2) }} />
        <TextField
          label={translations.name}
          variant="outlined"
          placeholder={translations.name}
          value={deleteCheck}
          onChange={setDeleteCheck}
          fullWidth={true}
          required={true}
          type="name"
        />
      </Dialog>
      <InformationBlock<string, string>
        items={[
          {
            label: translations.name,
            data: {
              value: name,
              onChange: setName,
              placeholder: '',
              onEnter: () => {},
              required: true,
              disabled: !communityData?.result.is_editable && id !== 'new',
            },
            type: 'editName',
          },
          {
            label: translations.description,
            fullWidth: true,
            data: {
              value: description,
              onChange: setDescription,
              placeholder: '',
              onEnter: () => {},
              required: true,
              multiline: true,
              disabled: !communityData?.result.is_editable && id !== 'new',
            },
            type: 'editText',
          },
          {
            label: translations.fleets,
            fullWidth: true,
            data: {
              mode: 'multiple',
              label: translations.fleets,
              values: fleets,
              options: (fleetsData?.result ?? []).map(({ id, name }) => ({
                id,
                label: name as CustomString,
              })),
              onChange: setFleets,
              disabled: !communityData?.result.is_editable && id !== 'new',
            },
            type: 'select_1',
          },
          {
            label: translations.users,
            fullWidth: true,
            data: {
              mode: 'multiple',
              label: translations.users,
              values: users,
              options: (driversData?.result ?? []).map(({ id, first_name, last_name, email }) => ({
                id,
                label: `${first_name} ${last_name} - ${email}` as CustomString,
              })),
              onChange: setUsers,
              onInput: (event) => {
                setUsersQuery(event.target.value);
              },
              disabled: !communityData?.result.is_editable && id !== 'new',
            },
            type: 'select_2',
          },
        ]}
      />
    </Page>
  );
}

export { CommunityPage };
