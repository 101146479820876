import {
  Box,
  CircularProgress,
  Unstable_Grid2 as Grid,
  // eslint-disable-next-line no-restricted-imports
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { CustomString } from 'config/translations';

import { Button, ButtonProps } from './Button';
import { Text } from './Text';

interface ContainerProps {
  title: CustomString | string;
  children: JSX.Element;
  width: 'small' | 'medium' | 'half' | 'flex';
  footer?:
    | {
        left?: undefined;
        right: Omit<ButtonProps, 'size'>;
      }
    | {
        left: Omit<ButtonProps, 'size'>;
        right?: undefined;
      }
    | {
        left: ButtonProps; //Omit<ButtonProps, 'size'>;
        right: Omit<ButtonProps, 'size'>;
      };
  loading?: boolean;
}

function Container({ title, children, width, footer, loading }: ContainerProps) {
  const theme = useTheme();

  return (
    <Grid
      xs={12}
      sm={width === 'small' ? 6 : 12}
      md={width === 'small' ? 6 : 12}
      lg={width === 'small' ? 4 : width === 'half' ? 6 : width === 'medium' ? 8 : 12}
      xl={width === 'small' ? 4 : width === 'half' ? 6 : width === 'medium' ? 8 : 12}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: `calc(100vw - ${theme.spacing(6)})`,
          paddingBottom: footer ? 1 : 2,
        }}
      >
        <Box sx={{ width: '100%', paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}>
          <Text.ExtraLarge noWrap>{title}</Text.ExtraLarge>
        </Box>

        {!loading && children}
        {!!loading && <CircularProgress size={48} />}
        {footer && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingTop: 1,
            }}
          >
            {footer.left ? <Button {...(footer.left as ButtonProps)} size="small" /> : <Box />}
            {footer.right && <Button {...(footer.right as ButtonProps)} size="small" />}
          </Box>
        )}
      </Box>
    </Grid>
  );
}

interface ContainerSpacingProps {
  width: 'small';
}

function ContainerSpacing({ width }: ContainerSpacingProps) {
  const theme = useTheme();
  const useSpacing = useMediaQuery(theme.breakpoints.down('lg'));

  if (useSpacing) {
    return <Box />;
  }

  return (
    <Grid xs={0} sm={0} md={0} lg={width === 'small' ? 4 : 0} xl={width === 'small' ? 4 : 0} />
  );
}

export { Container, ContainerSpacing };
