import { Theme } from '@mui/material';
import { Color } from 'types/shared';

import { CustomColor } from 'config/customColors';

interface AddAlphaToColorProps {
  color: string;
  alpha?: 1 | 0.85;
}

function addAlphaToColor({ color, alpha }: AddAlphaToColorProps): string {
  if (color.startsWith('#') && color.length === 7) {
    return `${color}${Math.round((alpha ?? 1.0) * 255)
      .toString(16)
      .toUpperCase()}`;
  }

  // eslint-disable-next-line no-restricted-syntax
  if (color.startsWith('rgb(') && color.endsWith(')')) {
    // eslint-disable-next-line no-restricted-syntax
    return `rgba(${color.split('rgb(')[1]}`.replace(')', `, ${alpha ?? 1})`);
  }

  return color;
}

interface GetColorFromMUI {
  color: Color | CustomColor;
  alpha?: 1 | 0.85;
  theme: Theme;
}

function getColorFromTheme({ color, alpha, theme }: GetColorFromMUI): string {
  if (isMUIColor({ color, theme })) {
    const fields = color.split('.');

    if (fields.length === 2) {
      const firstArgument = fields[0] as keyof typeof theme.palette;
      if (theme.palette[firstArgument]) {
        const secondArgument = fields[1] as keyof (typeof theme.palette)[typeof firstArgument];

        if (theme.palette[firstArgument][secondArgument]) {
          return addAlphaToColor({
            color: theme.palette[firstArgument][secondArgument] as string,
            alpha,
          });
        }
      }
    }
  }

  return addAlphaToColor({
    color,
    alpha,
  });
}

interface IsMUIColorProps {
  color: Color | CustomColor;
  theme: Theme;
}

function isMUIColor({ color, theme }: IsMUIColorProps): boolean {
  const fields = color.split('.');

  if (fields.length === 2) {
    const firstArgument = fields[0] as keyof typeof theme.palette;
    if (theme.palette[firstArgument]) {
      const secondArgument = fields[1] as keyof (typeof theme.palette)[typeof firstArgument];

      if (theme.palette[firstArgument][secondArgument]) {
        return true;
      }
    }
  }

  return false;
}

interface GetColorRangeFromThemeProps {
  amount?: number;
  alpha?: 1 | 0.85;
  mode: 'primary' | 'successInfoWarningError';
  theme: Theme;
}

function getColorRangeFromTheme({
  amount,
  alpha,
  mode,
  theme,
}: GetColorRangeFromThemeProps): string[] {
  if (mode === 'primary') {
    const colors: string[] = ['primary.light', 'primary.main', 'primary.dark'];

    const result: string[] = [];
    colors.forEach((color) => {
      result.push(getColorFromTheme({ color: `${color}` as Color, alpha, theme }));
    });

    return result.filter((_, index) => index < (amount ?? Number.MAX_VALUE));
  }

  const colors: string[] = ['success', 'info', 'warning', 'error'];
  const variants: string[] = ['main', 'dark', 'light'];

  const result: string[] = [];

  variants.forEach((variant) => {
    colors.forEach((color) => {
      result.push(getColorFromTheme({ color: `${color}.${variant}` as Color, alpha, theme }));
    });
  });

  return result.filter((_, index) => index < (amount ?? Number.MAX_VALUE));
}

export { getColorFromTheme, getColorRangeFromTheme, type GetColorRangeFromThemeProps };
