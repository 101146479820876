// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

function useSendPushNotifications() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface SendPushNotificationsProps {
    ids: string[];
    title: string;
    body: string;
  }

  interface Body {
    user_ids: string[];
    title: string;
    body: string;
    topic: 'message';
  }

  async function sendPushNotifications({ ids, title, body }: SendPushNotificationsProps) {
    const { data } = await API.put<void, Body>({
      path: '/admin/notification/batch-send',
      token,
      version: 1,
      body: {
        user_ids: ids,
        title,
        body,
        topic: 'message',
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, SendPushNotificationsProps>(
    (data: SendPushNotificationsProps) => sendPushNotifications(data),
  );
}

export { useSendPushNotifications };
