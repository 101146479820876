import { memo } from 'react';
import { Box, useTheme } from '@mui/material';

import { TextField } from './TextField';
import { getVerticalStackSx, VerticalPosition } from './VerticalStack';

type TableSearchBarProps = {
  placeholder: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  fullWidth: boolean;
} & VerticalPosition;

function TableSearchBar({
  placeholder,
  label,
  value,
  onChange,
  fullWidth,
  verticalPosition,
}: TableSearchBarProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: 'background.paper',
        ...getVerticalStackSx({ theme, verticalPosition }),
      }}
    >
      <TextField
        variant="outlined"
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        type="text"
      />
    </Box>
  );
}

const TableSearchBarMemo = memo(TableSearchBar);

export { TableSearchBarMemo as TableSearchBar };
