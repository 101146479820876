import { Box, useTheme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { DateTimePicker as DateTimePickerMUI, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { SVGButton } from './SVGButton';

interface DateTimePickerProps {
  label: string;
  value: string;
  fullWidth?: boolean;
  onChange: (data: string) => void;
  required?: boolean;
  disabled?: boolean;
}

function DateTimePicker({
  label,
  value,
  fullWidth,
  onChange,
  required,
  disabled,
}: DateTimePickerProps) {
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  if (hasMobileView) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: fullWidth ? '100%' : textFieldWidth,
        }}
      >
        <MobileDateTimePicker
          format="dd-MM-yyyy HH:mm"
          slotProps={{ textField: { size: 'small' } }}
          ampm={false}
          label={!!required && !disabled ? `${label} *` : label}
          value={dayjs(value).isValid() ? new Date(value) : null}
          onChange={(result) => {
            if (result) {
              if (dayjs(result).isValid()) {
                onChange(dayjs(result).toISOString());
              } else {
                onChange('');
              }
            }
          }}
          sx={{ width: fullWidth ? '100%' : textFieldWidth }}
          disabled={disabled}
        />
        {!disabled && <Box sx={{ width: theme.spacing(2) }} />}
        {!disabled && (
          <SVGButton
            svgName="close"
            color="primary"
            size="small"
            onClick={() => {
              onChange('');
            }}
            disabled={!value}
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: fullWidth ? '100%' : textFieldWidth,
      }}
    >
      <DateTimePickerMUI
        format="dd-MM-yyyy HH:mm"
        ampm={false}
        label={required && !disabled ? `${label} *` : label}
        value={dayjs(value).isValid() ? new Date(value) : null}
        onChange={(result) => {
          if (result) {
            if (dayjs(result).isValid()) {
              onChange(dayjs(result).toISOString());
            } else {
              onChange('');
            }
          }
        }}
        sx={{ width: fullWidth ? '100%' : textFieldWidth }}
        disabled={disabled}
      />
      {!disabled && <Box sx={{ width: theme.spacing(2) }} />}
      {!disabled && (
        <SVGButton
          svgName="close"
          color="primary"
          size="small"
          onClick={() => {
            onChange('');
          }}
          disabled={!value}
        />
      )}
    </Box>
  );
}

export { DateTimePicker };
