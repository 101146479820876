import { IconButton } from '@mui/material';
import { Color } from 'types/shared';

import { getSVG, SVGName, SVGProps } from './SVG';

interface SVGButtonProps {
  svgName: SVGName;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  size: SVGProps['size'];
  disabled?: boolean;
  onClick: () => void;
}

function SVGButton({ svgName, color, disabled, size, onClick }: SVGButtonProps) {
  const SVG = getSVG(svgName);

  return (
    <IconButton color={color} disabled={disabled} onClick={onClick}>
      <SVG color={(disabled ? `${color}.light` : `${color}.main`) as Color} size={size} />
    </IconButton>
  );
}

export { SVGButton };
