import axios, { AxiosError, AxiosResponse, isAxiosError, RawAxiosRequestHeaders } from 'axios';
import sizeOfObject from 'object-sizeof';
import qs from 'qs';

import { getApiUrl } from 'config/api';

import { formatDate } from './shared';

const API_URL = process.env.REACT_APP_API_URL ?? getApiUrl();

interface CustomError {
  reason?: string;
  message?: string;
  errors?: Array<Record<string, string>>;
}

type BaseBody = unknown | undefined;
type BaseParams = unknown | undefined;

type Version = 1 | 2 | false;

const errorReasons = {
  email_already_exists: 'This email address is already in use',
  required_field: 'Field is required',
  invalid_email: 'This is not a valid email address',
  not_enough_characters: 'Not enough characters',
  missing_special_character: 'Needs a special character',
  invalid_date_format: 'This is not a valid date',
  unknown_field: 'This field is not recognized',
  not_found: 'Combination could not be found',
  invalid_credentials: 'Your email address and/or your password is incorrect.',
  invalid_code: 'The verification code you have entered is invalid or expired',
  min_length_is_6: 'Should be at least 6 characters long',
  max_length_is_6: 'Should be maximum 6 characters long',
  max_length_is_30: 'Should be maximum 30 characters long',
  reservation_is_active: "This reservation has already started, you can't cancel it anymore.",
  general: 'Something went wrong. Please try again or contact support.',
};

interface TransformErrorProps {
  error: unknown;
  path: string;
}

function transformError({ error, path }: TransformErrorProps): Error {
  if (error instanceof AxiosError || error instanceof Error) {
    let customReason: string | undefined = undefined;
    let customMessage: string | undefined = undefined;
    let status: number | undefined = undefined;
    let errorsObject: Array<Record<string, string>> | undefined = undefined;
    const errorMessage = error.message;

    if (error instanceof AxiosError) {
      const { response } = error as AxiosError<CustomError>;
      customReason = response?.data?.reason;
      customMessage = response?.data?.message;
      errorsObject = response?.data?.errors;
      status = response?.status;
    }

    const initialTransformResult = {
      path,
      status: status ?? 'unknown',
    };

    if (customMessage) {
      const transformResult = {
        ...initialTransformResult,
        method: 'error.response?.data?.message',
        from: customMessage,
        to: customMessage,
        errors: errorsObject,
      };
      console.info(`Converted API error ${JSON.stringify(transformResult, null, 2)}`);

      return new Error(customMessage);
    }

    if (customReason) {
      if (customReason in errorReasons) {
        const transformResult = {
          ...initialTransformResult,
          method: 'errorReasons[error.response?.data?.reason]',
          from: customReason,
          to: errorReasons[customReason as keyof typeof errorReasons],
          errors: errorsObject,
        };
        console.info(`Converted API error ${JSON.stringify(transformResult, null, 2)}`);

        return new Error(errorReasons[customReason as keyof typeof errorReasons]);
      }

      const transformResult = {
        ...initialTransformResult,
        method: 'errorReasons[error.response?.data?.reason]',
        from: `!('${customReason}' in errorReasons)`,
        to: errorReasons.general,
        errors: errorsObject,
      };
      console.info(`Converted API error ${JSON.stringify(transformResult, null, 2)}`);

      return new Error(errorReasons.general);
    }

    if (!errorMessage.includes('with status code')) {
      return new Error(errorMessage);
    }

    const transformResult = {
      ...initialTransformResult,
      method: 'errorReasons.general',
      from: errorMessage,
      to: errorReasons.general,
      errors: errorsObject,
    };
    console.info(`Converted API error ${JSON.stringify(transformResult, null, 2)}`);

    return new Error(errorReasons.general);
  }

  const transformResult = {
    method: 'errorReasons.general',
    from: '!(error instanceof AxiosError || error instanceof Error)',
    to: errorReasons.general,
  };
  console.info(`Converted API error ${JSON.stringify(transformResult, null, 2)}`);

  return new Error(errorReasons.general);
}

function getSize(size: number) {
  if (!size || typeof size !== 'number') {
    return '';
  }

  let result = size;
  let unit = 'B';
  if (result / 1024 > 1) {
    result = result / 1024;
    unit = 'kB';

    if (result / 1024 > 1) {
      result = result / 1024;
      unit = 'MB';
    }
  }

  return ` | ${Math.floor(result)} ${unit}`;
}

// encodeURI(...) does not transform '+'
// As a result, we need to encodeURIComponent(...) which does transforms '+'
function processParams(params: Record<string, unknown> | undefined) {
  if (params === undefined) {
    return params;
  }

  return JSON.parse(
    JSON.stringify(params, (key, value) =>
      !!key && typeof value === 'string' ? encodeURIComponent(value) : value,
    ),
  );
}

interface LogResponseProps<Params extends BaseParams, Body extends BaseBody> {
  method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
  base: string | undefined;
  path: string;
  version: Version;
  response?: AxiosResponse;
  start: number;
  params?: Params;
  body?: Body;
}

function logResponse<Params extends BaseParams = undefined, Body extends BaseBody = undefined>({
  method,
  base,
  path,
  version,
  response,
  start,
  params,
  body,
}: LogResponseProps<Params, Body>) {
  let consoleMessage = `${formatDate({ date: Date.now(), variant: 'console' })} | ${
    response?.status ?? 'NETWORK ERROR'
  } | ${method} | ${version ? `v${version}` : ''}${base ? base : ''}${path} | ${
    Date.now() - start
  } ms${getSize(sizeOfObject(response?.data))}`;
  const appendedQuery = JSON.stringify(processParams(params ?? {}), null, 2);

  if (appendedQuery !== '{}') {
    consoleMessage += ` ${appendedQuery}`;
  }

  const appendedBody = JSON.stringify(body ?? {}, null, 2);
  if (appendedBody !== '{}') {
    consoleMessage += ` ${appendedBody}`;
  }

  console.info(consoleMessage);
}

interface GetUrlProps<Params extends BaseParams> {
  base?: string;
  path: string;
  version: Version;
  params?: Params;
}

function getUrl<Params extends BaseParams = undefined>({
  base,
  path,
  version,
  params,
}: GetUrlProps<Params>): string {
  return `${base ?? API_URL}${version ? `/v${version}` : ''}${path}${qs.stringify(
    processParams(params as Record<string, unknown>),
    {
      encode: false,
      addQueryPrefix: true,
    },
  )}`;
}

interface GetConvertedHeadersProps {
  headers: RawAxiosRequestHeaders | undefined;
  token: string | undefined;
}

async function getConvertedHeaders({
  headers,
  token,
}: GetConvertedHeadersProps): Promise<RawAxiosRequestHeaders> {
  return Promise.resolve({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(headers ?? {}),
  });
}

type GetProps<Params extends BaseParams, ExpectFile extends boolean> = ExpectFile extends true
  ? {
      base?: string;
      path: string;
      version: Version;
      params: Params;
      headers?: RawAxiosRequestHeaders;
      token: string | undefined;
      expectFile: true;
      onError: ((error: unknown) => void) | false;
    }
  : {
      base?: string;
      path: string;
      version: Version;
      params: Params;
      headers?: RawAxiosRequestHeaders;
      token: string | undefined;
      expectFile?: false;
      onError: ((error: unknown) => void) | false;
    };

async function get<
  Result extends ExpectFile extends true ? Blob : unknown,
  Params extends BaseParams = never,
  ExpectFile extends boolean = never,
>({
  base,
  path,
  version,
  params,
  headers,
  token,
  expectFile,
  onError,
}: GetProps<Params, ExpectFile>): Promise<AxiosResponse<Result>> {
  const convertedHeaders = await getConvertedHeaders({ headers, token });

  const start = Date.now();
  try {
    const response = await axios({
      method: 'GET',
      url: getUrl({ base, path, version, params }),
      headers: convertedHeaders,
      responseType: expectFile ? 'blob' : undefined,
    });

    logResponse({ method: 'GET', base, path, version, response, start, params });

    return Promise.resolve(response);
  } catch (error) {
    logResponse({
      method: 'GET',
      base,
      path,
      version,
      response: isAxiosError(error) ? error?.response : undefined,
      start,
      params,
    });

    onError && onError(error);
    return Promise.reject(transformError({ error, path }));
  }
}

interface PutProps<Body extends BaseBody> {
  base?: string;
  path: string;
  version: Version;
  body: Body;
  headers?: RawAxiosRequestHeaders;
  token: string | undefined;
  onError: ((error: unknown) => void) | false;
}

async function put<Response extends unknown = never, Body extends BaseBody = undefined>({
  base,
  path,
  version,
  body,
  headers,
  token,
  onError,
}: PutProps<Body>): Promise<AxiosResponse<Response>> {
  const convertedHeaders = await getConvertedHeaders({ headers, token });

  const start = Date.now();
  try {
    const response = await axios.put(getUrl({ base, path, version }), body, {
      headers: convertedHeaders,
    });

    logResponse({ method: 'PUT', base, path, version, response, start, body });

    return Promise.resolve(response);
  } catch (error) {
    logResponse({
      method: 'PUT',
      base,
      path,
      version,
      response: isAxiosError(error) ? error?.response : undefined,
      start,
      body,
    });

    onError && onError(error);
    return Promise.reject(transformError({ error, path }));
  }
}

interface PostProps<Body extends BaseBody> {
  base?: string;
  path: string;
  version: Version;
  body: Body;
  headers?: RawAxiosRequestHeaders;
  token: string | undefined;
  expectFile?: boolean;
  fileObject?: File | undefined;
  onError: ((error: unknown) => void) | false;
}

async function post<Response extends unknown = never, Body extends BaseBody = undefined>({
  base,
  path,
  version,
  body,
  headers,
  token,
  expectFile = false,
  onError,
  fileObject = undefined,
}: PostProps<Body>): Promise<AxiosResponse<Response>> {
  const convertedHeaders = await getConvertedHeaders({ headers, token });
  const start = Date.now();
  let payload: Body | FormData = body;
  if (fileObject) {
    convertedHeaders['Content-Type'] = 'multipart/form-data;';
    payload = new FormData();
    payload.append('file', fileObject, fileObject.name);
  }

  try {
    const response = await axios.post(getUrl({ base, path, version }), payload, {
      headers: convertedHeaders,
      responseType: expectFile ? 'blob' : undefined,
    });

    logResponse({ method: 'POST', base, path, version, response, start, body });

    return Promise.resolve(response);
  } catch (error) {
    logResponse({
      method: 'POST',
      base,
      path,
      version,
      response: isAxiosError(error) ? error?.response : undefined,
      start,
      body,
    });

    onError && onError(error);
    return Promise.reject(transformError({ error, path }));
  }
}

interface PatchProps<Body extends BaseBody> {
  base?: string;
  path: string;
  version: Version;
  body: Body;
  headers?: RawAxiosRequestHeaders;
  token: string | undefined;
  onError: ((error: unknown) => void) | false;
}

async function patch<Response extends unknown = never, Body extends BaseBody = undefined>({
  base,
  path,
  version,
  body,
  headers,
  token,
  onError,
}: PatchProps<Body>): Promise<AxiosResponse<Response>> {
  const convertedHeaders = await getConvertedHeaders({ headers, token });

  const start = Date.now();
  try {
    const response = await axios.patch(getUrl({ base, path, version }), body, {
      headers: convertedHeaders,
    });

    logResponse({ method: 'PATCH', base, path, version, response, start, body });

    return Promise.resolve(response);
  } catch (error) {
    logResponse({
      method: 'PATCH',
      base,
      path,
      version,
      response: isAxiosError(error) ? error?.response : undefined,
      start,
      body,
    });

    onError && onError(error);
    return Promise.reject(transformError({ error, path }));
  }
}

interface DeleteProps {
  base?: string;
  path: string;
  version: Version;
  headers?: RawAxiosRequestHeaders;
  token: string | undefined;
  onError: ((error: unknown) => void) | false;
}

// the function is called localDelete since delete is a reserved keyword
async function localDelete<Response extends unknown = never>({
  base,
  path,
  version,
  headers,
  token,
  onError,
}: DeleteProps): Promise<AxiosResponse<Response>> {
  const convertedHeaders = await getConvertedHeaders({ headers, token });

  const start = Date.now();
  try {
    const response = await axios.delete(getUrl({ base, path, version }), {
      headers: convertedHeaders,
    });

    logResponse({ method: 'DELETE', base, path, version, response, start });

    return Promise.resolve(response);
  } catch (error) {
    logResponse({
      method: 'DELETE',
      base,
      path,
      version,
      response: isAxiosError(error) ? error?.response : undefined,
      start,
    });

    onError && onError(error);
    return Promise.reject(transformError({ error, path }));
  }
}

const API = {
  get,
  put,
  post,
  patch,
  // the function is called localDelete since delete is a reserved keyword
  delete: localDelete,
};

export { API, errorReasons, get, patch, post, put };
