// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';
import { Meta } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

function useGetBalance() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Response {
    result: {
      available: number;
      balance: number;
      reserved: number;
    };
    meta: Meta;
  }
  interface GetBalanceProps {
    id: string;
  }

  async function getBalance({ id }: GetBalanceProps) {
    const { data } = await API.get<Response, undefined, false>({
      path: `/admin/organisation/${id}/balance`,
      token,
      version: 1,
      params: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Response, unknown, GetBalanceProps>((data) => getBalance(data));
}

function useUpdateBalance() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface UpdateBalanceProps {
    id: string;
    amount: number;
    direction: 'to' | 'from';
  }

  interface Body {
    amount: number;
    direction: 'to' | 'from';
  }

  async function updateBalance({ id, amount, direction }: UpdateBalanceProps) {
    const { data } = await API.post<void, Body>({
      path: `/admin/organisation/${id}/transfer`,
      token,
      version: 1,
      body: {
        amount,
        direction,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UpdateBalanceProps>((data) => updateBalance(data));
}

export { useGetBalance, useUpdateBalance };
