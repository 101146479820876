import React from 'react';

import { Page, Table, TableSearchBar, VerticalStack } from 'components';
import { downloadBlob, getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

function InvitesPage() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams('invites');
  const { navigate } = useNavigate();

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({ page: 1, q: value });
        return;
      }

      setSearchParams({ page, q: value });
    },
    path: '/invites',
  });

  const [page, setPage] = React.useState(1);

  const { data: invitationsData, isFetching } = UseQuery.invitations({
    page,
    query: delayedSearchQuery,
  });

  const { mutateAsync: mutateAsyncExportInvitations, isLoading: isLoadingExportInvitations } =
    UseMutation.exportInvitations();

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  const translations = useTranslations('invitesPages');

  return (
    <Page
      name="invites"
      actions={[
        {
          svgName: 'fileDownload',
          title: translations.exportAllInivites,
          mode: 'closeAfterLoading',
          onClick: async () => {
            try {
              const blob = await mutateAsyncExportInvitations();
              downloadBlob({ blob, fileName: 'invitations_export' });
            } catch (error) {
              // Do nothing
            }
          },
          loading: isLoadingExportInvitations,
        },
      ]}
    >
      <>
        <VerticalStack>
          <TableSearchBar
            placeholder={translations.emailOrCode}
            label={translations.searchEmailOrCode}
            value={searchQuery}
            onChange={(value: string) => {
              setSearchQuery(value);
            }}
            fullWidth={true}
          />
          <Table
            columns={{
              email: { name: translations.email, type: 'text', flex: 1 },
              code: { name: translations.code, type: 'text', flex: 1 },
              timesRedeemed: { name: translations.timesRedeemed, type: 'text', flex: 1 },
              timesUsedByInvitee: { name: translations.timesUsedByInvitee, type: 'text', flex: 1 },
              timesUsedByInviter: { name: translations.timesUsedByInviter, type: 'text', flex: 1 },
            }}
            rows={(invitationsData?.result ?? []).map(
              ({ id, email, invitation_code, redeemed, used_by_invitee, used_by_inviter }) => ({
                id,
                email,
                code:
                  invitation_code.length === 6
                    ? `${invitation_code.substring(0, 3)}-${invitation_code.substring(3)}`
                    : invitation_code,
                timesRedeemed: (redeemed ?? 0).toString(),
                timesUsedByInvitee: (used_by_invitee ?? 0).toString(),
                timesUsedByInviter: (used_by_inviter ?? 0).toString(),
              }),
            )}
            onClickRow={({ id, metaKey }) => {
              navigate({
                name: 'driver',
                id: id.toString(),
                searchParams: { reservationsPage: 1, tab: 0 },
                metaKey,
              });
            }}
            pagination={{
              count: getNumberOfPages({ meta: invitationsData?.meta }),
              page,
              onChange: (page) => {
                setPage(page);
                setSearchParams({ page, q: delayedSearchQuery });
              },
            }}
            loading={isFetching}
          />
        </VerticalStack>
      </>
    </Page>
  );
}

export { InvitesPage };
