// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

import { Fuel, Transportation } from './useReservations';

interface MetaRule {
  id: string;
  description: string;
  target: string;
  price_per_kilometer?: number;
  transportation_type?: keyof typeof Transportation;
  fuel_type?: keyof typeof Fuel;
}

function useMetaRules() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<MetaRule[]>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/meta-rules`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['meta'], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

interface UseAddMetaRuleProps {
  invalidateQueries: boolean;
}

function useAddMetaRule({ invalidateQueries }: UseAddMetaRuleProps) {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  type UseAddMetaRuleProps = Omit<MetaRule, 'id'>;

  async function addMetaRule(metaRule: UseAddMetaRuleProps) {
    const { data } = await API.post<void, Omit<MetaRule, 'id'>>({
      path: `/meta-rules`,
      token,
      version: 1,
      body: metaRule,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UseAddMetaRuleProps>(
    (data: UseAddMetaRuleProps) => addMetaRule(data),
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          await queryClient.invalidateQueries(['meta']);
          await queryClient.invalidateQueries(['transactions']);
        }
      },
    },
  );
}

interface UseEditMetaRuleProps {
  invalidateQueries: boolean;
}

function useEditMetaRule({ invalidateQueries }: UseEditMetaRuleProps) {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  type UseEditMetaRuleProps = MetaRule;

  async function editMetaRule(metaRule: UseEditMetaRuleProps) {
    const { data } = await API.put<void, Omit<MetaRule, 'id'>>({
      path: `/meta-rules/${metaRule.id}`,
      token,
      version: 1,
      body: {
        target: metaRule.target,
        description: metaRule.description,
        transportation_type: metaRule.transportation_type,
        fuel_type: metaRule.fuel_type,
        price_per_kilometer: metaRule.price_per_kilometer,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UseEditMetaRuleProps>(
    (data: UseEditMetaRuleProps) => editMetaRule(data),
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          await queryClient.invalidateQueries(['meta']);
          await queryClient.invalidateQueries(['transactions']);
        }
      },
    },
  );
}

interface UseDeleteMetaRuleProps {
  invalidateQueries: boolean;
}

function useDeleteMetaRule({ invalidateQueries }: UseDeleteMetaRuleProps) {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  type UseDeleteMetaRuleProps = Pick<MetaRule, 'id'>;

  async function deleteMetaRule({ id }: UseDeleteMetaRuleProps) {
    const { data } = await API.delete<void>({
      path: `/meta-rules/${id}`,
      token,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UseDeleteMetaRuleProps>(
    (data: UseDeleteMetaRuleProps) => deleteMetaRule(data),
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          await queryClient.invalidateQueries(['meta']);
          await queryClient.invalidateQueries(['transactions']);
        }
      },
    },
  );
}

export { type MetaRule, useAddMetaRule, useDeleteMetaRule, useEditMetaRule, useMetaRules };
