import React from 'react';
import { Box } from '@mui/material';

import { Dialog, Page, Table, TableSearchBar, Text, TextField, VerticalStack } from 'components';
import { downloadBlob, getNumberOfPages } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getLabelColor, getStatus } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';

function DriversPage() {
  const [page, setPage] = React.useState(1);

  const translations = useTranslations('driversPage');

  const [searchQuery, setSearchQuery] = React.useState('');
  const [openSyncGreenwheels, setOpenSyncGreenwheels] = React.useState(false);
  const [dialogCookie, setDialogCookie] = React.useState('');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value) => {
      if (value) {
        setSearchParams({ page: 1, q: value });
        return;
      }

      setSearchParams({ page, q: value });
    },
    path: '/drivers',
  });

  const { data: driversData, isFetching: isFetchingDriversData } = UseQuery.drivers({
    page,
    query: delayedSearchQuery,
  });
  const { mutateAsync: mutateAsyncExportDrivers, isLoading: isLoadingExportDrivers } =
    UseMutation.exportDrivers();
  const { mutateAsync: mutateAsyncSyncGreenwheels, isLoading: isLoadingSyncGreenwheels } =
    UseMutation.syncGreenwheels();

  const { navigate } = useNavigate();
  const { addToast } = useToast();

  const { searchParams, setSearchParams } = useSearchParams('drivers');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  return (
    <Page
      name="drivers"
      actions={[
        {
          svgName: 'fileDownload',
          title: translations.exportAllDrivers,
          mode: 'closeAfterLoading',
          onClick: async () => {
            try {
              const blob = await mutateAsyncExportDrivers();
              downloadBlob({ blob, fileName: 'drivers_export' });
            } catch (error) {
              // Do nothing
            }
          },
          loading: isLoadingExportDrivers,
        },
        {
          svgName: 'check',
          title: translations.syncGreenwheels,
          mode: 'closeOnClick',
          onClick: () => {
            setDialogCookie('');
            setOpenSyncGreenwheels(true);
          },
          loading: false,
        },
      ]}
    >
      <>
        <Dialog
          title={translations.areYouSure}
          footer={[
            {
              variant: 'outlined',
              label: translations.cancel,
              onClick: () => {
                setOpenSyncGreenwheels(false);
              },
              disabled: false,
            },
            {
              variant: 'contained',
              label: translations.confirm,
              onClick: async () => {
                try {
                  const blob = await mutateAsyncSyncGreenwheels({ cookie: dialogCookie });
                  downloadBlob({ blob, fileName: 'report.csv' });
                  addToast({
                    title: translations.successfullySyncedGreenwheels,
                    severity: 'success',
                  });
                } catch (error) {
                  setOpenSyncGreenwheels(false);
                }
              },
              disabled: false,
              loading: isLoadingSyncGreenwheels,
            },
          ]}
          open={openSyncGreenwheels}
          onChange={setOpenSyncGreenwheels}
        >
          <Box sx={{ paddingTop: 1, width: 500 }}>
            <Text>{translations.cookieExplanation}</Text>
            <TextField
              label={translations.cookie}
              variant="outlined"
              value={dialogCookie}
              onChange={setDialogCookie}
              fullWidth={true}
              required={true}
              type="text"
            />
          </Box>
        </Dialog>
      </>
      <VerticalStack>
        <TableSearchBar
          placeholder={translations.drivers}
          label={translations.searchDrivers}
          value={searchQuery}
          onChange={setSearchQuery}
          fullWidth={true}
        />
        <Table
          columns={{
            name: { name: translations.name, type: 'text', width: 200 },
            email: { name: translations.email, type: 'text', flex: 1 },
            phoneNumber: { name: translations.phoneNumber, type: 'text', width: 200 },
            accountCreatedOn: { name: translations.accountCreatedOn, type: 'dateTime', width: 250 },
            organisation: { name: translations.organisation, type: 'text', width: 150 },
            status: { name: translations.status, type: 'label', width: 150 },
          }}
          rows={(driversData?.result ?? []).map(
            ({
              id,
              first_name,
              last_name,
              email,
              phone_number,
              created_on,
              organisation,
              status,
            }) => ({
              id,
              name: `${first_name} ${last_name}`,
              email,
              phoneNumber: phone_number,
              accountCreatedOn: created_on,
              organisation: organisation?.name ?? '',
              status: {
                label: getStatus({ driver: status }),
                color: getLabelColor({ driver: status }),
              },
            }),
          )}
          onClickRow={({ id, metaKey }) => {
            navigate({
              name: 'driver',
              id: id.toString(),
              searchParams: { reservationsPage: 1, tab: 0 },
              metaKey,
            });
          }}
          pagination={{
            count: getNumberOfPages({ meta: driversData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({ page, q: searchQuery });
              setPage(page);
            },
          }}
          loading={isFetchingDriversData}
        />
      </VerticalStack>
    </Page>
  );
}

export { DriversPage };
