import React from 'react';

import { Page } from 'components';
import { useCognitoUser } from 'core/cognito';
import { useNavigate } from 'hooks/useNavigate';

function MainPage() {
  const { authenticated } = useCognitoUser();
  const { navigate } = useNavigate();

  React.useEffect(() => {
    if (authenticated) {
      navigate({ name: 'statistics', id: undefined, searchParams: { tab: 1 }, metaKey: false });
    }
  }, [authenticated]);

  return <Page name="main" />;
}

export { MainPage };
