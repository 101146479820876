import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Box, MenuItem, Select, Unstable_Grid2 as Grid, useTheme } from '@mui/material';
import { codeDatas } from 'better-currency-codes';

import { Button, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

interface AdyenProviderProps {
  organisationId: string;
  adyenData: AdyenProps;
}

interface AdyenProps {
  currency: string;
  auto_top_up_amount?: number;
  notification_threshold?: number;
}

function AdyenProvider({ organisationId, adyenData }: AdyenProviderProps) {
  const translations = useTranslations('adyenProvider');
  const { addToast } = useToast();
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  const [currency, setCurrency] = React.useState<string>();

  const { refetch: refetchOrganisationData, isLoading: loadingOrganisationData } =
    UseQuery.organisation({ id: organisationId });
  const { mutateAsync: mutateAsyncUpdateAdyenProvider, isLoading: loadingUpdateAdyenProvider } =
    UseMutation.updateOrganisationProviders();

  React.useEffect(() => {
    setCurrency(adyenData?.currency);
  }, [adyenData]);

  const disabledSave =
    adyenData?.currency === currency ||
    currency === undefined ||
    currency === '' ||
    loadingUpdateAdyenProvider ||
    loadingOrganisationData;

  function formatCurrency(amountInCents: number | undefined, currency: string) {
    if (amountInCents === undefined || amountInCents === null) {
      return 'Disabled'; // Handle case when threshold is not set
    }

    // Divide by 100 to get the proper value in the main currency unit
    const amount = amountInCents / 100;

    // Format using Intl.NumberFormat for the selected currency
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  }

  async function updateAdyenProvider() {
    if (currency === undefined || currency === '') {
      return;
    }

    try {
      await mutateAsyncUpdateAdyenProvider({
        id: organisationId,
        providers: {
          ADYEN: {
            currency,
          },
        },
      });
      await refetchOrganisationData();
      addToast({ title: translations.updatedSuccessfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  const sortedCurrencyCodes = [...codeDatas()].sort((a, b) =>
    a.entities[0].name.localeCompare(b.entities[0].name),
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
          marginBottom: 2,
          border: 1,
          borderColor: 'divider',
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasMobileView ? 'row' : 'column',
            justifyContent: hasMobileView ? 'space-between' : undefined,
          }}
        >
          <Text.Bold>{translations.adyenSettings}</Text.Bold>
        </Box>
        <Grid container spacing={1} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Box sx={{ paddingRight: 2 }}>
            <Select
              label={translations.currency}
              value={currency ?? ''}
              onChange={(event) => setCurrency(event.target.value)}
              fullWidth
            >
              {sortedCurrencyCodes.map((option) => (
                <MenuItem key={option.code.alphabetic} value={option.code.alphabetic}>
                  {`${option.entities[0].name} - ${option.name.full} - ${option.symbol.native}`}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <TextField
              type="text"
              label={translations.topUpAmount}
              value={adyenData.auto_top_up_amount?.toString() ?? 'Disabled'}
              disabled={true}
              onChange={() => {}}
              required
            />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <TextField
              type="text"
              label={translations.notificationThreshold}
              value={formatCurrency(adyenData.notification_threshold, currency ?? 'EUR')}
              disabled={true}
              onChange={() => {}}
              required
            />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <Button
            label={translations.save}
            disabled={disabledSave}
            loading={loadingUpdateAdyenProvider}
            onClick={updateAdyenProvider}
          />
        </Grid>
      </Box>
    </>
  );
}

export { type AdyenProps, AdyenProvider };
