import React from 'react';

import { Page, RowTypes, Table, TableSearchBar, VerticalStack } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

function CommunitiesPage() {
  const { navigate } = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({
          page: 1,
          q: value,
        });
        return;
      }
    },
    path: '/communities',
  });

  const translations = useTranslations('communitesPage');
  const [page, setPage] = React.useState(1);

  const { data: communitiesData, isFetching: isFetchingcCmmunitiesData } = UseQuery.communities({
    page,
    query: delayedSearchQuery,
  });

  const { searchParams, setSearchParams } = useSearchParams('communities');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  return (
    <Page
      name="communities"
      actions={[
        {
          svgName: 'add',
          title: translations.addCommunity,
          mode: 'closeOnClick',
          onClick: () => {
            navigate({ name: 'community', id: 'new', searchParams: undefined, metaKey: false });
          },
        },
      ]}
    >
      <VerticalStack>
        <TableSearchBar
          placeholder={translations.communities}
          label={translations.searchCommunities}
          value={searchQuery}
          onChange={(value: string) => {
            setSearchQuery(value);
          }}
          fullWidth={true}
        />
        <Table
          columns={{
            name: { name: translations.name, type: 'text', flex: 1 },
            size: { name: translations.size, type: 'text', width: 75 },
            editable: { name: translations.editable, type: 'buttonOrSVGOrEmpty', width: 100 },
          }}
          rows={(communitiesData?.result ?? []).map(({ id, name, size, is_editable }) => ({
            id,
            name: name ?? '',
            size: size.toString() ?? '',
            editable: {
              svgName: is_editable ? 'check' : 'lock',
            } as RowTypes['buttonOrSVGOrEmpty'],
          }))}
          onClickRow={({ id, metaKey }) => {
            navigate({ name: 'community', id: id.toString(), searchParams: undefined, metaKey });
          }}
          pagination={{
            count: getNumberOfPages({ meta: communitiesData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({
                page,
                q: delayedSearchQuery,
              });
              setPage(page);
            },
          }}
          loading={isFetchingcCmmunitiesData || communitiesData === undefined}
        />
      </VerticalStack>
    </Page>
  );
}

export { CommunitiesPage };
