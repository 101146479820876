// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

interface Configuration {
  settings: {
    signup_enabled?: boolean | undefined;
  };
}

function useConfiguration() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Configuration;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: '/admin/configuration',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['configuration'], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

function useEditConfiguration() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Configuration {
    settings: {
      signup_enabled: boolean;
    };
  }

  interface Response {
    result: Configuration;
    meta: Meta;
  }

  async function editConfiguration(configuration: Configuration) {
    const { data } = await API.put<Response, Configuration>({
      path: `/admin/configuration`,
      token,
      version: 1,
      body: configuration,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Response, unknown, Configuration>(
    (data: Configuration) => editConfiguration(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['configuration']);
      },
    },
  );
}

export { type Configuration, useConfiguration, useEditConfiguration };
