import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { MenuItem } from 'components/Menu';

const initialMenuItems: MenuItem[] = [];

interface MenuItemsContextProps {
  menuItems: MenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
}

const MenuItemsContext = React.createContext<MenuItemsContextProps>({
  menuItems: initialMenuItems,
  setMenuItems: () => {},
});

interface MenuItemsProviderProps {
  children: JSX.Element;
}

function MenuItemsProvider({ children }: MenuItemsProviderProps) {
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>(initialMenuItems);

  return (
    <MenuItemsContext.Provider value={{ menuItems, setMenuItems }}>
      {children}
    </MenuItemsContext.Provider>
  );
}

export { MenuItemsContext, MenuItemsProvider };
