import React from 'react';
import { Box, CircularProgress, Pagination, Tooltip, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Color } from 'types/shared';

import {
  Button,
  ButtonProps,
  ButtonPropsWithoutSize,
  getSVG,
  InformationBlock,
  InformationBlockProps,
  Label,
  SVG,
  SVGName,
  Text,
  VerticalPosition,
} from 'components';
import { useDarkMode } from 'core/darkMode/hooks';
import { formatDate, formatPrice } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';

import { getVerticalStackSx } from './VerticalStack';

type Types =
  | 'text'
  | 'price'
  | 'date'
  | 'dateTime'
  | 'id'
  | 'label'
  | 'buttonOrSVGOrEmpty'
  | 'textOrSVG';

interface RowTypes {
  text: string;
  price: number;
  date: string | number | Date;
  dateTime: string | number | Date;
  label: { label: string; color: Color };
  buttonOrSVGOrEmpty: ButtonPropsWithoutSize | { svgName: SVGName; tooltip: string } | undefined;
  textOrSVG: string | { svgName: SVGName; tooltip: string };
}

type Selectable =
  | {
      selectable: true;
      onSelect: (id: string[]) => void;
    }
  | {
      selectable?: false;
      onSelect?: undefined;
    };

type Row<Columns extends Record<string, Column>> = {
  [Key in keyof Columns]: Columns[Key]['type'] extends 'text'
    ? RowTypes['text'] | RowTypes['text']
    : Columns[Key]['type'] extends 'date'
    ? RowTypes['date']
    : Columns[Key]['type'] extends 'dateTime'
    ? RowTypes['dateTime']
    : Columns[Key]['type'] extends 'label'
    ? RowTypes['label']
    : Columns[Key]['type'] extends 'buttonOrSVGOrEmpty'
    ? RowTypes['buttonOrSVGOrEmpty']
    : Columns[Key]['type'] extends 'textOrSVG'
    ? RowTypes['textOrSVG']
    : Columns[Key]['type'] extends 'price'
    ? RowTypes['price']
    : Columns[Key]['type'] extends 'id'
    ? string
    : never;
} & { id: string };

type TableProps<Columns extends Record<string, Column>> = {
  columns: Columns;
  rows: Row<Columns>[];
  onClickRow?: ({ id, metaKey }: { id: string; metaKey: boolean }) => void;
  pagination?: {
    count: number;
    page: number;
    onChange: (page: number) => void;
  };
  loading: boolean;
  useMobileView?: boolean;
} & VerticalPosition &
  Selectable;

type Column =
  | {
      name: CustomString;
      type: Types;
      width: number;
      flex?: undefined;
      clickable?: boolean;
      hidden?: boolean;
    }
  | {
      name: CustomString;
      type: Types;
      width?: undefined;
      flex: number;
      clickable?: boolean;
      hidden?: boolean;
    };

function Table<Columns extends Record<Fields, Column>, Fields extends string = string>({
  columns,
  rows,
  onClickRow,
  pagination,
  verticalPosition,
  selectable,
  onSelect,
  loading,
  useMobileView = true,
}: TableProps<Columns>) {
  const { addToast } = useToast();
  const theme = useTheme();
  const { mode } = useDarkMode();
  const translations = useTranslations('general');

  // There is a bug where content in <Table /> jumps when one of columns === 'name
  // To be on the save side, we add '_' to all columns and rows respectively
  const newColumns = React.useMemo(() => {
    const newColumns = {} as Record<string, Column>;
    Object.keys(columns).forEach((item) => {
      if (item !== 'id') {
        newColumns[`_${item}`] = columns[item as keyof Columns];
      } else {
        newColumns[item] = columns[item as keyof Columns];
      }
    });

    return newColumns;
  }, [JSON.stringify(columns)]);

  columns = newColumns as Columns;

  const newRows = React.useMemo(() => {
    return rows.map((row) => {
      const newRow = {} as Row<Columns>;
      Object.keys(row).forEach((item) => {
        if (item !== 'id') {
          newRow[`_${item}` as keyof typeof row] = row[item as keyof typeof row];
        } else {
          newRow[item] = row[item];
        }
      });

      return newRow;
    });
  }, [JSON.stringify(rows)]);

  rows = newRows as Row<Columns>[];

  const { hasMobileView } = useHasMobileView();

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<Record<string, boolean>>(
    { name: false },
  );

  React.useEffect(() => {
    const newColumnVisibilityModel: Record<string, boolean> = {};
    Object.keys(columns).forEach((item) => {
      newColumnVisibilityModel[item] = !columns[item as keyof typeof columns].hidden;
    });

    setColumnVisibilityModel(newColumnVisibilityModel);
  }, [Object.keys(columns).toString()]);

  React.useEffect(() => {
    if (
      pagination &&
      pagination?.count < pagination?.page &&
      pagination?.page !== 1 &&
      pagination.count !== 0
    ) {
      pagination?.onChange(1);
    }
  }, [pagination]);

  // Check for identical rows and columns
  const [shownToast, setShownToast] = React.useState(false);

  React.useEffect(() => {
    if (
      rows.length > 0 &&
      Object.keys(rows[0])
        .filter((item) => item !== 'id')
        .sort((a, b) => (a > b ? 1 : -1))
        .toString() !==
        Object.keys(columns)
          .filter((item) => item !== 'id')
          .sort((a, b) => (a > b ? 1 : -1))
          .toString() &&
      !shownToast
    ) {
      console.error(`Found a table with non-matching columns and rows keys`);
      console.info(
        'columns',
        Object.keys(columns).filter((item) => item !== 'id'),
      );
      console.info(
        'rows[0]',
        Object.keys(rows[0]).filter((item) => item !== 'id'),
      );
      addToast({
        title: translations.oops,
        severity: 'error',
      });
      setShownToast(true);
    }
  }, [rows, JSON.stringify(columns)]);

  interface RenderCellProps {
    type: Types;
    columnName: keyof Columns;
  }

  const renderCell = React.useCallback(
    ({ type, columnName }: RenderCellProps) => {
      return type === 'label'
        ? ({ row }: { row: Row<Columns> }) => (
            <Label
              title={(row[columnName as keyof Columns] as RowTypes['label']).label}
              color={(row[columnName as keyof Columns] as RowTypes['label']).color}
            />
          )
        : type === 'buttonOrSVGOrEmpty'
        ? ({ row }: { row: Row<Columns> }) => {
            const props = row[columnName as keyof Columns] as RowTypes['buttonOrSVGOrEmpty'];

            if (typeof props === 'object' && 'svgName' in props) {
              const SVG = getSVG(props.svgName as SVGName);

              return (
                <Tooltip title={'tooltip' in props ? props?.tooltip ?? '' : ''}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <SVG size="small" color={mode === 'dark' ? 'common.white' : 'common.black'} />
                  </Box>
                </Tooltip>
              );
            }

            if (props === undefined) {
              return undefined;
            }

            return <Button {...(props as ButtonProps)} size="small" />;
          }
        : type === 'textOrSVG'
        ? ({ row }: { row: Row<Columns> }) => {
            const props = row[columnName as keyof Columns] as RowTypes['textOrSVG'];

            if (typeof props === 'object' && 'svgName' in props) {
              const SVG = getSVG(props.svgName);

              return (
                <Tooltip title={props?.tooltip ?? ''}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <SVG size="small" color={mode === 'dark' ? 'common.white' : 'common.black'} />
                  </Box>
                </Tooltip>
              );
            }

            return props;
          }
        : undefined;
    },
    [getSVG, mode],
  );

  const LocalPagination = React.useCallback(() => {
    if (!pagination || pagination.count === 0) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 2 }}>
        <Pagination
          sx={{
            '& .MuiPaginationItem-ellipsis': {
              color: mode === 'dark' ? 'common.white' : 'common.black',
            },
            '& .MuiPaginationItem-previousNext': {
              color: mode === 'dark' ? 'common.white' : 'common.black',
            },
          }}
          page={pagination.page}
          count={pagination.count}
          color="primary"
          shape="circular"
          onChange={(_, page) => {
            pagination.onChange(page);
          }}
        />
      </Box>
    );
  }, [pagination]);

  const convertedColumns = React.useMemo(() => {
    return Object.keys(columns).map((columnName) => {
      const { name, width, flex, type } = columns[columnName as keyof Columns];

      return {
        field: columnName,
        headerName: name,
        width,
        flex,
        renderCell: renderCell({ type, columnName: columnName as keyof Columns }),
        renderHeader: () => <Text.Bold>{name}</Text.Bold>,
      };
    });
  }, [JSON.stringify(columns), renderCell]);

  const convertedRows = React.useMemo(() => {
    return rows.map((row) => {
      const convertedRow = { ...row };
      Object.keys(convertedRow).forEach((key) => {
        const type = columns[key as Fields]?.type;

        if (type === 'price') {
          const typeValue = convertedRow[key as Fields];

          convertedRow[key as Fields] = formatPrice({
            price: parseInt((convertedRow[key as Fields] as RowTypes['price']).toString()),
            symbol: true,
          }) as typeof typeValue;
        }

        if (type === 'dateTime') {
          const typeValue = convertedRow[key as Fields];

          let result = '-';
          if (dayjs(convertedRow[key as Fields] as string).isValid()) {
            result = formatDate({
              date: convertedRow[key as Fields] as RowTypes['dateTime'],
              variant: 'long',
            });
          }

          convertedRow[key as Fields] = result as typeof typeValue;
        }

        if (type === 'date') {
          const typeValue = convertedRow[key as Fields];

          let result = '-';
          if (dayjs(convertedRow[key as Fields] as string).isValid()) {
            result = formatDate({
              date: convertedRow[key as Fields] as RowTypes['date'],
              variant: 'dayMonthYear',
            });
          }

          convertedRow[key as Fields] = result as typeof typeValue;
        }
      });

      return convertedRow;
    });
  }, [rows, JSON.stringify(columns)]);

  const convertedRowsMobileView = React.useMemo(() => {
    return rows.map((row) => {
      const convertedRow = { ...row };
      Object.keys(convertedRow).forEach((key) => {
        const type = columns[key as Fields]?.type;

        if (type === 'price') {
          const typeValue = convertedRow[key as Fields];

          convertedRow[key as Fields] = formatPrice({
            price: parseInt((convertedRow[key as Fields] as RowTypes['price']).toString()),
            symbol: true,
          }) as typeof typeValue;
        }

        if (type === 'dateTime') {
          const typeValue = convertedRow[key as Fields];

          let result = '-';
          if (dayjs(convertedRow[key as Fields] as string).isValid()) {
            result = formatDate({
              date: convertedRow[key as Fields] as RowTypes['date'],
              variant: 'long',
            });
          }

          convertedRow[key as Fields] = result as typeof typeValue;
        }

        if (type === 'date') {
          const typeValue = convertedRow[key as Fields];

          let result = '-';
          if (dayjs(convertedRow[key as Fields] as string).isValid()) {
            result = formatDate({
              date: convertedRow[key as Fields] as RowTypes['date'],
              variant: 'dayMonthYear',
            });
          }

          convertedRow[key as Fields] = result as typeof typeValue;
        }
      });

      return convertedRow;
    });
  }, [rows, JSON.stringify(columns)]);

  const renderLocalInformationBlock = React.useCallback(
    (convertedRow: Row<Columns>, index: number): JSX.Element => {
      const localItems: InformationBlockProps['items'] = [];

      Object.keys(convertedRow).forEach((field) => {
        if (field === 'id' || !convertedRow[field as keyof Columns]) {
          return;
        }

        const type = columns[field as keyof Columns].type;

        if (type === 'text' || type === 'date' || type === 'dateTime' || type === 'price') {
          localItems.push({
            type: 'text',
            label: columns[field as keyof Columns].name,
            data: { value: convertedRow[field as keyof Columns] as string },
          });
          return;
        }

        if (type === 'label') {
          localItems.push({
            type: 'label',
            label: columns[field as keyof Columns].name,
            data: {
              value: (convertedRow[field as keyof Columns] as RowTypes['label']).label,
              color: (convertedRow[field as keyof Columns] as RowTypes['label']).color,
            },
          });
          return;
        }

        if (type === 'buttonOrSVGOrEmpty') {
          const content = convertedRow[field as keyof Columns] as RowTypes['buttonOrSVGOrEmpty'];

          if (!content) {
            return;
          }

          if (typeof content === 'object' && 'svgName' in content && content.svgName) {
            localItems.push({
              type: 'svg',
              label: columns[field as keyof Columns].name,
              data: {
                svgName: content.svgName,
                tooltip: (content as { tooltip: string | undefined }).tooltip ?? '',
              },
            });
            return;
          }

          localItems.push({
            type: 'button',
            label: columns[field as keyof Columns].name,
            data: content as ButtonPropsWithoutSize,
          });
          return;
        }

        if (type === 'textOrSVG') {
          const content = convertedRow[field as keyof Columns] as RowTypes['textOrSVG'];

          if (typeof content === 'object' && 'svgName' in content) {
            localItems.push({
              type: 'svg',
              label: columns[field as keyof Columns].name,
              data: { svgName: content.svgName, tooltip: content.tooltip },
            });
            return;
          }

          localItems.push({
            type: 'text',
            label: columns[field as keyof Columns].name,
            data: { value: convertedRow[field as keyof Columns] as string },
          });
          return;
        }

        if (type !== 'id') {
          throw new Error(`Found unkown type=${type} in renderLocalInformationBlock(...)`);
        }
      });

      let title = convertedRow.id;
      if (localItems.length > 0 && localItems[0].type === 'text') {
        title = localItems[0].data.value.toString();
        localItems.shift();
      }

      return (
        <Box
          key={convertedRow.id}
          sx={{ paddingBottom: 2, paddingTop: index === 0 && !!verticalPosition ? 2 : 0 }}
        >
          <InformationBlock
            title={title}
            items={localItems}
            useSingleActionWithoutPopover={true}
            actions={
              onClickRow
                ? [
                    {
                      svgName: 'east',
                      mode: 'closeOnClick',
                      onClick: () => {
                        onClickRow({ id: convertedRow.id.toString(), metaKey: false });
                      },
                      title: translations.open,
                    },
                  ]
                : undefined
            }
          />
        </Box>
      );
    },
    [JSON.stringify(columns), onClickRow],
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: hasMobileView ? 2 : 4,
          paddingBottom: hasMobileView ? 2 : 4,
          backgroundColor: 'background.paper',
          ...getVerticalStackSx({ theme, verticalPosition }),
        }}
      >
        <Box sx={{ display: 'flex', width: 150, justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
            }}
          >
            <CircularProgress />
          </Box>
          <SVG.Search size="large" color="background.paper" />
        </Box>
        <Text.ExtraLarge>{translations.loading}</Text.ExtraLarge>
      </Box>
    );
  }

  if (hasMobileView && useMobileView && rows.length !== 0) {
    return (
      <>
        {convertedRowsMobileView.map(renderLocalInformationBlock)}
        <LocalPagination />
      </>
    );
  }

  if (rows.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: hasMobileView ? 2 : 4,
          paddingBottom: hasMobileView ? 2 : 4,
          backgroundColor: 'background.paper',
          ...getVerticalStackSx({ theme, verticalPosition }),
        }}
      >
        <Box sx={{ display: 'flex', width: 150, justifyContent: 'center' }}>
          <SVG.Search size="large" color="primary.main" />
        </Box>
        <Text.ExtraLarge>{translations.noRecords}</Text.ExtraLarge>
      </Box>
    );
  }

  return (
    <>
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell': {
            //height: 0,
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: 1,
            fontWeight: 900,
          },
          '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          ...getVerticalStackSx({ theme, verticalPosition }),
          backgroundColor: 'background.paper',
        }}
        autoHeight={true}
        density={hasMobileView ? 'compact' : 'standard'}
        rows={convertedRows}
        columns={convertedColumns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newColumnVisibilityModel) => {
          setColumnVisibilityModel(newColumnVisibilityModel);
        }}
        pageSize={100}
        rowsPerPageOptions={[100]}
        onCellClick={({ id, field, formattedValue }, { metaKey }) => {
          const result = columns[field as keyof Columns];

          if (
            (result && result?.type !== 'buttonOrSVGOrEmpty') ||
            (result?.type === 'buttonOrSVGOrEmpty' &&
              ('name' in formattedValue || formattedValue === undefined))
          ) {
            onClickRow && onClickRow({ id: id.toString(), metaKey });
          }
        }}
        hideFooter={rows.length <= 100 && !selectable}
        hideFooterPagination
        isRowSelectable={() => !!selectable}
        checkboxSelection={selectable}
        onSelectionModelChange={(result) => {
          onSelect && onSelect(result as string[]);
        }}
      />
      <LocalPagination />
    </>
  );
}

export { type RowTypes, Table };
