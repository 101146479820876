// eslint-disable-next-line no-restricted-imports
import { useParams as useParamsReact } from 'react-router-dom';

import { CustomRoutes, customRoutes } from 'config/route';

type UseParamsReturn<Name extends keyof CustomRoutes> = CustomRoutes[Name]['hasId'] extends true
  ? { id: string }
  : undefined;

function useParams<Name extends keyof CustomRoutes>(name: Name): UseParamsReturn<Name> {
  const params = useParamsReact();

  if (!customRoutes[name] || !customRoutes[name].hasId) {
    return undefined as UseParamsReturn<Name>;
  }

  return params as UseParamsReturn<Name>;
}

export { useParams };
