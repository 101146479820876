import { Box, useTheme } from '@mui/material';
import { DatePicker as DatePickerMUI, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { SVGButton } from './SVGButton';

type View = 'day' | 'month' | 'year';

interface DateTimePickerProps {
  label: string;
  value: string;
  fullWidth?: boolean;
  onChange: (data: string) => void;
  required?: boolean;
  disabled?: boolean;
  disabledEmpty?: boolean;
  views?: View[];
}

function DatePicker({
  label,
  value,
  fullWidth,
  onChange,
  required,
  disabled,
  disabledEmpty,
  views,
}: DateTimePickerProps) {
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  function getFormat() {
    if (!views) {
      return 'dd-MM-yyyy';
    }
    const formatParts = {
      day: 'dd',
      month: 'MM',
      year: 'yyyy',
    };

    return views.map((view) => formatParts[view]).join('-');
  }

  function getOpenTo() {
    if (getFormat() === 'dd-MM-yyyy') {
      return 'day';
    }

    return 'month';
  }

  if (hasMobileView) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: fullWidth ? '100%' : textFieldWidth,
        }}
      >
        <MobileDatePicker
          views={views}
          slotProps={{ textField: { size: 'small' } }}
          format={getFormat()}
          closeOnSelect={true}
          label={!!required && !disabled ? `${label} *` : label}
          value={dayjs(value).isValid() ? new Date(value) : null}
          onChange={(result) => {
            if (result) {
              if (dayjs(result).isValid()) {
                onChange(dayjs(result).toISOString());
              } else {
                onChange('');
              }
            }
          }}
          sx={{ width: fullWidth ? '100%' : textFieldWidth }}
          disabled={disabled}
        />
        {!!disabledEmpty || (!disabled && <Box sx={{ width: theme.spacing(2) }} />)}
        {!!disabledEmpty ||
          (!disabled && (
            <SVGButton
              svgName="close"
              color="primary"
              size="small"
              onClick={() => {
                onChange('');
              }}
              disabled={!value}
            />
          ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: fullWidth ? '100%' : textFieldWidth,
      }}
    >
      <DatePickerMUI
        views={views}
        openTo={getOpenTo()}
        format={getFormat()}
        closeOnSelect={true}
        label={required && !disabled ? `${label} *` : label}
        value={dayjs(value).isValid() ? new Date(value) : null}
        onChange={(result) => {
          if (result) {
            if (dayjs(result).isValid()) {
              onChange(dayjs(result).toISOString());
            } else {
              onChange('');
            }
          }
        }}
        sx={{ width: fullWidth ? '100%' : textFieldWidth }}
        disabled={disabled}
      />
      {!!disabledEmpty || (!disabled && <Box sx={{ width: theme.spacing(2) }} />)}
      {!!disabledEmpty ||
        (!disabled && (
          <SVGButton
            svgName="close"
            color="primary"
            size="small"
            onClick={() => {
              onChange('');
            }}
            disabled={!value}
          />
        ))}
    </Box>
  );
}

export { DatePicker };
