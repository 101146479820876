import React from 'react';
import {
  Avatar,
  Box,
  InputAdornment,
  // eslint-disable-next-line no-restricted-imports
  TextField,
  useTheme,
} from '@mui/material';

import { Button, Select, SVGButton, Text } from 'components';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
// eslint-disable-next-line no-restricted-imports
import { Fuel, Transportation } from 'useQuery/useReservations';

interface Value {
  id: string | undefined;
  data: [string, string, string | undefined, string | undefined, number | undefined];
}

interface DualListEditorProps {
  title: string;
  columnNames: CustomString[];
  values: Value[];
  onChange: (values: Value[]) => void;
}

interface DualListEditorRowProps {
  value: Value;
  index: number;
  refs: React.RefObject<HTMLInputElement>[];
  values: DualListEditorProps['values'];
  columnNames: DualListEditorProps['columnNames'];
  onChange: DualListEditorProps['onChange'];
}

function DualListEditorRow({
  value,
  index,
  refs,
  values,
  columnNames,
  onChange,
}: DualListEditorRowProps) {
  const translations = useTranslations('dualListEditor');

  const { hasMobileView } = useHasMobileView();

  function deleteRow(index: number) {
    if (index === 0 && values.length === 1) {
      onChange([]);
      return;
    }

    if ((index > 0 && index <= values.length - 1) || (index === 0 && values.length > 1)) {
      const newValues = [...values];
      delete newValues[index];

      onChange(newValues.filter((item) => item !== null));
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          marginRight: 2,
          backgroundColor: !!value.data[0] && !!value.data[1] ? 'primary.main' : undefined,
        }}
      >
        {index + 1}
      </Avatar>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'row' } }}>
        <TextField
          autoCorrect="off"
          autoComplete="off"
          key={index * 2}
          sx={{ marginRight: 2, display: 'flex', flex: 1 }}
          inputRef={refs[index * 2]}
          variant="filled"
          label={columnNames[0]}
          value={value.data[0]}
          autoFocus={index === 0}
          onChange={(event) => {
            const newValues = [...values];

            if (newValues[index] === undefined) {
              newValues.push({ id: undefined, data: ['', '', undefined, undefined, undefined] });
            }

            newValues[index].data[0] = event.target.value;
            onChange(newValues);
          }}
          onKeyDown={({ nativeEvent }) => {
            // Go to second item of the current pair
            if (nativeEvent.code === 'Enter' && refs[index * 2 + 1]?.current) {
              refs[index * 2 + 1].current?.focus();
            }
          }}
          margin="dense"
          error={!value.data[0] && index < values.length - 1}
          helperText={
            !value.data[0] && index < values.length - 1 ? translations.required : undefined
          }
          size={hasMobileView ? 'small' : undefined}
        />

        <TextField
          autoCorrect="off"
          autoComplete="off"
          key={index * 2 + 1}
          sx={{ marginRight: 2, display: 'flex', flex: 1 }}
          inputRef={refs[index * 2 + 1]}
          variant="filled"
          label={columnNames[1]}
          value={value.data[1]}
          onChange={(event) => {
            const newValues = [...values];

            if (newValues[index] === undefined) {
              newValues.push({ id: undefined, data: ['', '', undefined, undefined, undefined] });
            }

            newValues[index].data[1] = event.target.value;
            onChange(newValues);
          }}
          onKeyDown={({ nativeEvent }) => {
            if (nativeEvent.code !== 'Enter' && nativeEvent.code !== 'Tab') {
              return;
            }

            nativeEvent.preventDefault();

            if (nativeEvent.code === 'Enter') {
              // Go back to the beginning of the current pair if the first item is not filled in
              if (!value.data[0] && refs[index * 2]?.current) {
                refs[index * 2]?.current?.focus();
                return;
              }

              // Add new pair when when the current pair is fully filled in
              if (
                !!value.data[0] &&
                !!value.data[1] &&
                !!value.data[2] &&
                !!value.data[3] &&
                !!value.data[4]
              ) {
                onChange([
                  ...values,
                  { id: undefined, data: ['', '', undefined, undefined, undefined] },
                ]);
                return;
              }

              return;
            }

            // Go to the next pair
            if (refs[index * 2 + 2]?.current) {
              refs[index * 2 + 2].current?.focus();
              return;
            }

            if (refs[0]?.current) {
              // Go back to the first pair
              refs[0].current?.focus();
              return;
            }
          }}
          margin="dense"
          error={!value.data[1] && index < values.length - 1}
          helperText={
            !value.data[1] && index < values.length - 1 ? translations.required : undefined
          }
          size={hasMobileView ? 'small' : undefined}
        />

        <Box padding={1} justifyContent={'center'} alignItems={'center'} width={'20%'}>
          <Select
            fullWidth
            mode="single"
            options={Object.values(Transportation).map((value) => ({
              id: value,
              label: value as unknown as CustomString,
            }))}
            key={index * 2 + 2}
            label={columnNames[2]}
            value={value.data[2]}
            onChange={(event) => {
              const newValues = [...values];

              if (newValues[index] === undefined) {
                newValues.push({ id: undefined, data: ['', '', undefined, undefined, undefined] });
              }

              newValues[index].data[2] = event;
              onChange(newValues);
            }}
          />
        </Box>

        <Box padding={1} justifyContent={'center'} alignItems={'center'} width={'20%'}>
          <Select
            fullWidth
            mode="single"
            options={Object.values(Fuel).map((value) => ({
              id: value,
              label: value as unknown as CustomString,
            }))}
            key={index * 2 + 3}
            label={columnNames[3]}
            value={value.data[3]}
            onChange={(event) => {
              const newValues = [...values];

              if (newValues[index] === undefined) {
                newValues.push({ id: undefined, data: ['', '', undefined, undefined, undefined] });
              }

              newValues[index].data[3] = event;
              onChange(newValues);
            }}
          />
        </Box>

        <TextField
          autoCorrect="off"
          autoComplete="off"
          key={index * 2 + 4}
          sx={{ marginRight: 2, display: 'flex', flex: 1 }}
          inputRef={refs[index * 2 + 4]}
          variant="filled"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          label={columnNames[4]}
          value={value.data[4]}
          onChange={(event) => {
            const newValues = [...values];

            if (newValues[index] === undefined) {
              newValues.push({ id: undefined, data: ['', '', undefined, undefined, undefined] });
            }

            newValues[index].data[4] = event.target.value ? Number(event.target.value) : undefined;
            onChange(newValues);
          }}
          onKeyDown={({ nativeEvent }) => {
            if (nativeEvent.code !== 'Enter' && nativeEvent.code !== 'Tab') {
              return;
            }

            nativeEvent.preventDefault();

            if (nativeEvent.code === 'Enter') {
              // Go back to the beginning of the current pair if the first item is not filled in
              if (!value.data[0] && refs[index * 2]?.current) {
                refs[index * 2]?.current?.focus();
                return;
              }

              // Add new pair when when the current pair is fully filled in
              if (
                !!value.data[0] &&
                !!value.data[1] &&
                !!value.data[2] &&
                !!value.data[3] &&
                !!value.data[4]
              ) {
                onChange([
                  ...values,
                  { id: undefined, data: ['', '', undefined, undefined, undefined] },
                ]);
                return;
              }

              return;
            }

            // Go to the next pair
            if (refs[index * 2 + 4]?.current) {
              refs[index * 2 + 4].current?.focus();
              return;
            }

            if (refs[0]?.current) {
              // Go back to the first pair
              refs[0].current?.focus();
              return;
            }
          }}
          margin="dense"
          size={hasMobileView ? 'small' : undefined}
        />
      </Box>
      <SVGButton
        svgName="delete"
        color="info"
        onClick={() => {
          deleteRow(index);
        }}
        size="small"
        disabled={values.length === 0}
      />
    </Box>
  );
}

function DualListEditor({ title, columnNames, values, onChange }: DualListEditorProps) {
  const translations = useTranslations('dualListEditor');
  const theme = useTheme();

  const [refs, setRefs] = React.useState<React.RefObject<HTMLInputElement>[]>([]);
  const [previousValuesLength, setPreviousValuesLength] = React.useState(0);

  React.useEffect(() => {
    if (previousValuesLength < values.length && refs[(values.length - 1) * 2]?.current) {
      refs[(values.length - 1) * 2]?.current?.focus();
      setPreviousValuesLength(values.length);
      return;
    }

    if (previousValuesLength > values.length) {
      setPreviousValuesLength(values.length);
      return;
    }
  }, [values, refs]);

  React.useEffect(() => {
    setRefs((refs) => {
      const newRefs: React.RefObject<HTMLInputElement>[] = [];
      for (let index = 0; index < (values.length + 1) * 2; index++) {
        newRefs[index] = refs[index] || React.createRef<string>();
      }

      return newRefs;
    });
  }, [values.length]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          maxWidth: `calc(100vw - ${theme.spacing(6)})`,
        }}
      >
        <Text.ExtraLarge>{title}</Text.ExtraLarge>
      </Box>

      {values.map((value, index) => (
        <DualListEditorRow
          key={index}
          index={index}
          value={value}
          refs={refs}
          values={values}
          onChange={onChange}
          columnNames={columnNames}
        />
      ))}
      {values.length === 0 && (
        <DualListEditorRow
          index={values.length}
          value={{ id: undefined, data: ['', '', undefined, undefined, undefined] }}
          refs={refs}
          values={values}
          onChange={onChange}
          columnNames={columnNames}
        />
      )}
      <Box
        sx={{
          paddingTop: 2,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          maxWidth: `calc(100vw - ${theme.spacing(6)})`,
        }}
      >
        <Button
          label={translations.addMore}
          disabled={values.length === 0}
          onClick={() => {
            onChange([
              ...values,
              { id: undefined, data: ['', '', undefined, undefined, undefined] },
            ]);
          }}
        />
      </Box>
    </Box>
  );
}

export { DualListEditor, type DualListEditorProps };
