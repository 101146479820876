import React from 'react';

import { Page, Table, TableSearchBar, VerticalStack } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useToast } from 'core/toast/hooks';
import { useDelayedInput } from 'hooks/useDelayedInput';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { getLabelColor } from 'config/shared';
import { UseMutation, UseQuery } from 'useQuery';
import { BusinessCardStatus } from 'useQuery/types';

function BusinessCardsPage() {
  const translations = useTranslations('businessCardsPage');
  const { addToast } = useToast();
  const { searchParams, setSearchParams } = useSearchParams('businessCards');

  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');

  const delayedSearchQuery = useDelayedInput({
    input: searchQuery,
    onChange: (value: string) => {
      if (value) {
        setSearchParams({ page: 1, q: value });
        return;
      }

      setSearchParams({ page, q: value });
    },
    path: '/businessCards',
  });

  const { data: businessCardsData, isFetching } = UseQuery.businessCards({
    page,
    query: delayedSearchQuery,
  });

  const { mutateAsync: businessCardsUpload } = UseMutation.bulkBusinessCardsUpload();

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }

    if (searchParams.q) {
      setSearchQuery(searchParams.q);
    }
  }, [searchParams]);

  return (
    <Page
      name="businessCards"
      actions={[
        {
          mode: 'fileUpload',
          svgName: 'fileUpload',
          title: translations.uploadBusinessCardsXlsx,
          extension: '.xlsx',
          onFile: async (file) => {
            try {
              await businessCardsUpload({ file });

              addToast({ title: translations.uploadSuccessful, severity: 'success' });
            } catch (e) {
              // Do nothing
            }
          },
        },
      ]}
    >
      <>
        <VerticalStack>
          <TableSearchBar
            placeholder={translations.businessCards}
            label={translations.searchBusinessCards}
            value={searchQuery}
            onChange={(value: string) => {
              setSearchQuery(value);
            }}
            fullWidth={true}
          />
          <Table
            columns={{
              organisation: { name: translations.organisation, type: 'text', width: 150 },
              fullName: { name: translations.fullName, type: 'text', width: 150 },
              email: { name: translations.email, type: 'text', width: 250 },
              engravedCardId: { name: translations.engravedCardId, type: 'text', width: 150 },
              contractStart: { name: translations.contractStart, type: 'text', flex: 1 },
              contractEnd: { name: translations.contractEnd, type: 'text', flex: 1 },
              class_: { name: translations.class, type: 'text', flex: 1 },
              product: { name: translations.product, type: 'text', flex: 1 },
              addons: { name: translations.addons, type: 'text', flex: 1 },
              status: { name: translations.status, type: 'label', width: 250 },
            }}
            rows={(businessCardsData?.result ?? []).map(
              ({
                id,
                user: { organisation, full_name, email },
                engraved_card_id,
                contract_start,
                contract_end,
                class: class_,
                product,
                status,
              }) => ({
                id,
                organisation: organisation ?? '-',
                fullName: full_name,
                email,
                engravedCardId: engraved_card_id ?? '-',
                contractStart: contract_start,
                contractEnd: contract_end ?? '-',
                class_,
                product: product.label,
                addons: product.addons.map((addon) => addon.label).join(', '),
                status: {
                  label: status,
                  color: getLabelColor({ businessCardStatus: status as BusinessCardStatus }),
                },
              }),
            )}
            pagination={{
              count: getNumberOfPages({ meta: businessCardsData?.meta }),
              page,
              onChange: (page) => {
                setPage(page);
                setSearchParams({ page, q: searchQuery });
              },
            }}
            loading={isFetching}
          />
        </VerticalStack>
      </>
    </Page>
  );
}

export { BusinessCardsPage };
