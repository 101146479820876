import React from 'react';
import ReactDOM from 'react-dom/client';
// eslint-disable-next-line no-restricted-imports
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CognitoProvider } from 'core/cognito';
import { DarkModeProvider } from 'core/darkMode/context';
import { MenuItemsProvider } from 'core/menuItems/context';
import { ToastProvider } from 'core/toast/context';
import { TranslationsProvider } from 'core/translations/context';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Note: QueryClient() can be used here since we're outside <QueryClientProvider />
const queryClient = new QueryClient();

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TranslationsProvider>
          <MenuItemsProvider>
            <ToastProvider>
              <DarkModeProvider>
                <CognitoProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CognitoProvider>
              </DarkModeProvider>
            </ToastProvider>
          </MenuItemsProvider>
        </TranslationsProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
