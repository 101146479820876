import { CustomString, englishTranslations, LocalizedContent } from 'config/translations';

function getTranslations<Key extends keyof LocalizedContent>(key: Key): LocalizedContent[Key] {
  return englishTranslations[key] as LocalizedContent[Key];
}

function useTranslations(key?: undefined): LocalizedContent;
// eslint-disable-next-line no-redeclare
function useTranslations<Key extends keyof LocalizedContent>(key?: Key): LocalizedContent[Key];
// eslint-disable-next-line no-redeclare
function useTranslations<Key extends keyof LocalizedContent>(
  key?: Key | undefined,
): LocalizedContent | LocalizedContent[Key] {
  if (key === undefined) {
    return englishTranslations as LocalizedContent;
  }

  return englishTranslations[key] as LocalizedContent[Key];
}

interface ReplaceTextProps {
  text: CustomString;
  replaceValues: (string | number)[];
}

function replaceText({ text, replaceValues }: ReplaceTextProps): CustomString {
  let result = text;

  replaceValues.forEach((value, index) => {
    result = result.replace(
      `$${index}`,
      typeof value === 'string' || typeof value === 'number' ? value.toString() : '',
    ) as CustomString;
  });

  return result;
}

export { getTranslations, replaceText, useTranslations };
