// eslint-disable-next-line no-restricted-imports
import { createSearchParams, useNavigate as useNavigateReact } from 'react-router-dom';

import { CustomRoutes, customRoutes } from 'config/route';
import { SearchParamsMapping } from 'config/searchParams';

interface NavigateProps<Name extends keyof CustomRoutes> {
  name: Name;
  id: CustomRoutes[Name]['hasId'] extends true ? string : undefined;
  searchParams: SearchParamsMapping[Name];
  metaKey: boolean;
}

function useNavigate(): {
  navigate: <Name extends keyof CustomRoutes>({
    name,
    id,
    searchParams,
    metaKey,
  }: NavigateProps<Name>) => void;
} {
  const navigateReact = useNavigateReact();

  function navigate<Name extends keyof CustomRoutes>({
    name,
    id,
    searchParams,
    metaKey,
  }: NavigateProps<Name>): void {
    let result = '';
    if (searchParams !== undefined) {
      const convertedSearchParams: Record<string, string> = {};
      const localSearchParams: Record<string, string | number> = { ...searchParams };
      Object.keys(searchParams).forEach((key) => {
        convertedSearchParams[key] =
          localSearchParams[key as keyof typeof localSearchParams].toString();
      });

      result = `?${createSearchParams(convertedSearchParams).toString()}`;
    }

    if (id !== undefined) {
      if (metaKey) {
        window.open(`${customRoutes[name].path}/${id}${result}`, '_blank', 'noreferrer');
        return;
      }

      return navigateReact(`${customRoutes[name].path}/${id}${result}`);
    }

    if (metaKey) {
      window.open(`${customRoutes[name].path}${result}`, '_blank', 'noreferrer');
      return;
    }

    return navigateReact(`${customRoutes[name].path}${result}`);
  }

  return { navigate };
}

export { useNavigate };
