import React from 'react';
import {
  InputAdornment as InputAdornmentMUI,
  // eslint-disable-next-line no-restricted-imports
  TextField as TextFieldMUI,
  TextFieldProps as TextFieldPropsMUI,
} from '@mui/material';

import { textFieldWidth } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';

type Type = 'number' | 'phoneNumber' | 'email' | 'name' | 'text' | 'password';

interface TextFieldPropsBase {
  inputRef?: React.Ref<HTMLInputElement>;
  type: Type;
  autoFocus?: boolean;
  variant?: TextFieldPropsMUI['variant'];
  label: string;
  placeholder?: string;
  onKeyEnterDown?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  multiline?: boolean;
  helperText?: string;
  error?: boolean;
  adornment?: string;
}

type TextFieldProps =
  | (TextFieldPropsBase & {
      type: 'number';
      value: number;
      onChange: (value: number) => void;
    })
  | (TextFieldPropsBase & {
      type: 'phoneNumber' | 'email' | 'name' | 'text' | 'password';
      value: string;
      onChange: (value: string) => void;
    });

function TextField({
  inputRef,
  type,
  autoFocus,
  variant,
  label,
  placeholder,
  value,
  onChange,
  onKeyEnterDown,
  fullWidth,
  disabled,
  required,
  multiline,
  helperText,
  error,
  adornment,
}: TextFieldProps) {
  const { hasMobileView } = useHasMobileView();
  const [localHelperText, setLocalHelperText] = React.useState('');

  const [isEmptyNumber, setIsEmptyNumber] = React.useState(false);

  React.useEffect(() => {
    if (typeof value === 'string' && !value) {
      setLocalHelperText('');
    }
    const emailValidationPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const phoneNumberPattern =
      /^(\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|=2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14})$/;

    if (type === 'email' && !!value) {
      if (!emailValidationPattern.test(value)) {
        setLocalHelperText('Invalid email');
      } else {
        setLocalHelperText('');
      }
    }

    if (type === 'phoneNumber' && !!value) {
      if (!phoneNumberPattern.test(value) || value.length !== 12) {
        setLocalHelperText('Invalid phone number');
      } else {
        setLocalHelperText('');
      }
    }
  }, [value]);

  function getAdornment(adornment: string) {
    switch (adornment) {
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'EUR':
      default:
        return '€';
    }
  }

  return (
    <TextFieldMUI
      inputRef={inputRef}
      sx={{ width: fullWidth ? '100%' : textFieldWidth }}
      autoFocus={autoFocus}
      variant={variant}
      label={label}
      placeholder={placeholder}
      value={
        type === 'number'
          ? Number.isNaN(value)
            ? ''
            : value === 0 && isEmptyNumber
            ? ''
            : value
          : value
      }
      onChange={({ target }) => {
        if (type === 'number') {
          setIsEmptyNumber(!target.value);

          const value = parseFloat(target.value) ?? 0;
          onChange(Number.isNaN(value) ? 0 : value);
          return;
        }
        if (type === 'phoneNumber' || type === 'email' || type === 'text' || type === 'password') {
          onChange(target.value);
          return;
        }
        if (type === 'name') {
          onChange(target.value);
          return;
        }
      }}
      onKeyDown={({ nativeEvent }) => {
        if (nativeEvent.code === 'Enter' && onKeyEnterDown) {
          onKeyEnterDown();
        }
      }}
      disabled={disabled}
      required={required}
      helperText={helperText ?? localHelperText}
      error={!!localHelperText || !!error}
      size={hasMobileView ? 'small' : undefined}
      multiline={multiline}
      type={type === 'password' ? type : type === 'number' ? 'number' : undefined}
      InputProps={
        adornment
          ? {
              startAdornment: (
                <InputAdornmentMUI position="start">{getAdornment(adornment)}</InputAdornmentMUI>
              ),
            }
          : undefined
      }
    />
  );
}

export { TextField };
