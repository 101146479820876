import React, { useContext } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';

import { CognitoContext, initialState } from './context';
import {
  authenticationErrorNoAccess,
  cognitoUserHasDashboardRole,
  logoutCognitoUser,
} from './services';

function useCognitoContext() {
  const context = useContext(CognitoContext);

  if (!context) {
    throw new Error('Components should be rendered inside the CognitoProvider component');
  }

  return context;
}

function useCognitoActions() {
  const { state, setState } = useCognitoContext();

  interface LoginProps {
    email: string;
    password: string;
  }

  function login({ email, password }: LoginProps) {
    setState((prevState) => ({ ...prevState, loading: true }));

    Auth.signIn(email.toLowerCase(), password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'SMS_MFA') {
          setState((prevState) => ({
            ...prevState,
            action: {
              type: user.challengeName,
              data: {
                user,
              },
            },
            authenticated: false,
            loading: false,
            error: false,
          }));
        } else {
          if (!cognitoUserHasDashboardRole(user)) {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              error: new Error(authenticationErrorNoAccess),
            }));
            return;
          }

          setState((prevState) => ({
            ...prevState,
            user,
            authenticated: true,
            loading: false,
            error: false,
          }));
        }
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
        logoutCognitoUser();
      });
  }

  function logout() {
    setState((prevState) => ({ ...prevState, loading: true }));

    logoutCognitoUser()
      .then(() => {
        setState({ ...initialState, authenticated: false });
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
      });
  }

  interface RequestResetPasswordProps {
    email: string;
  }

  function requestResetPassword({ email }: RequestResetPasswordProps) {
    setState((prevState) => ({ ...prevState, loading: true }));

    Auth.forgotPassword(email)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          action: {
            type: 'UPDATE_PASSWORD_REQUESTED',
            data: {
              email,
            },
          },
          authenticated: false,
          loading: false,
          error: false,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
      });
  }

  interface ResetPasswordProps {
    email: string;
    code: string;
    newPassword: string;
  }

  function resetPassword({ email, code, newPassword }: ResetPasswordProps) {
    setState((prevState) => ({ ...prevState, loading: true }));

    Auth.forgotPasswordSubmit(email, code, newPassword.trim())
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          action: undefined,
          authenticated: false,
          loading: false,
          error: false,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
      });
  }

  function completePassword(user: CognitoUser, password: string) {
    setState((prevState) => ({ ...prevState, loading: true }));

    Auth.completeNewPassword(user, password.trim(), {})
      .then((user) => {
        setState((prevState) => ({
          ...prevState,
          user: user,
          action: undefined,
          authenticated: true,
          loading: false,
          error: false,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
      });
  }

  interface VerifySmsCodeProps {
    code: string;
  }

  function verifySmsCode({ code }: VerifySmsCodeProps) {
    if (state.action?.type !== 'SMS_MFA') {
      setState((prevState) => ({
        ...prevState,
        error: new Error("verifySmsCode(): state.action?.type !== 'SMS_MFA'"),
      }));
      return;
    }

    setState((prevState) => ({ ...prevState, loading: true }));

    Auth.confirmSignIn(state.action.data.user, code, 'SMS_MFA')
      .then((user) => {
        if (!cognitoUserHasDashboardRole(user)) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: new Error(authenticationErrorNoAccess),
          }));
          return;
        }

        setState((prevState) => ({
          ...prevState,
          user,
          authenticated: true,
          loading: false,
          error: false,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: error instanceof Error ? error : new Error('!instanceof Error'),
        }));
      });
  }

  return {
    login,
    logout,
    requestResetPassword,
    resetPassword,
    completePassword,
    verifySmsCode,
  };
}

function useCognitoUser() {
  const { state, setState } = useCognitoContext();

  React.useEffect(() => {
    if (state.authenticated && !state.loading && !state.token) {
      Auth.currentSession()
        .then((session) => {
          const token = session.getAccessToken().getJwtToken();
          setState((prevState) => ({ ...prevState, token }));
        })
        .catch((error) => {
          console.info(error);
        });
    }
  }, [state]);

  return state;
}

export { useCognitoActions, useCognitoUser };
