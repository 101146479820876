import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Alert,
  AppBar,
  Box,
  ButtonBase,
  CircularProgress,
  CssBaseline,
  Drawer,
  IconButton,
  // eslint-disable-next-line no-restricted-imports
  List,
  // eslint-disable-next-line no-restricted-imports
  ListItem,
  Snackbar,
  Toolbar,
  useTheme,
} from '@mui/material';
import { AxiosError } from 'axios';

import { useCognitoActions, useCognitoUser } from 'core/cognito';
import { useDarkMode } from 'core/darkMode/hooks';
import { useMenuItems } from 'core/menuItems/hooks';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useNavigate } from 'hooks/useNavigate';
import { useRoute } from 'hooks/useRoute';
import { useTranslations } from 'hooks/useTranslations';

import { CustomRoutes, customRoutes } from 'config/route';
import { secondaryMain } from 'config/theme';

import { Button, ButtonActions } from './Button';
import { PageProps } from './Page';
import { getSVG, SVG, SVGName } from './SVG';
import { Text } from './Text';

interface LocalMenuItemProps {
  title: string;
  selected: boolean;
  name: keyof CustomRoutes | undefined;
  svgName: SVGName;
  onClick?: () => void;
}

function LocalMenuItem({ title, selected, name, svgName, onClick }: LocalMenuItemProps) {
  const { navigate } = useNavigate();
  const LocalSVG = getSVG(svgName);

  const { mode } = useDarkMode();

  return (
    <ButtonBase
      key={title}
      onClick={({ metaKey }) => {
        if (onClick) {
          onClick();
          return;
        }

        if (name !== undefined && !customRoutes[name].hasId) {
          navigate({ name, id: undefined, searchParams: undefined, metaKey });
        }
      }}
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: mode === 'dark' ? 'secondary.dark' : 'secondary.light',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          paddingBottom: 1,
          paddingTop: 1,
          paddingRight: 2,
          paddingLeft: 4,
        }}
      >
        <LocalSVG color={selected ? 'primary.main' : 'common.white'} size="small" />
        <Box sx={{ paddingLeft: 2 }}>
          <Text color={selected ? 'primary.main' : 'common.white'} bold={selected}>
            {title}
          </Text>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SVG.ArrowForwardIos
            color={selected ? 'primary.main' : 'common.white'}
            size="extraSmall"
          />
        </Box>
      </Box>
    </ButtonBase>
  );
}

const drawerWidth = 280;

type MenuProps<Name extends keyof CustomRoutes> = {
  name: PageProps<Name>['name'];
  overwriteTitle?: string;
  loading?: boolean;
  fullScreen?: boolean;
  children: JSX.Element | JSX.Element[];
  footer: PageProps<Name>['footer'];
} & ButtonActions;

type MenuItem =
  | {
      type: 'item';
      category?: undefined;
      name: keyof CustomRoutes;
      title: string;
      path: string;
      selected: boolean;
      svgName: SVGName;
    }
  | {
      type: 'category';
      category: string;
      name?: undefined;
      title?: undefined;
      path?: undefined;
      selected?: undefined;
      svgName?: undefined;
    };

function Menu<Name extends keyof CustomRoutes>({
  name,
  overwriteTitle,
  loading,
  fullScreen,
  children,
  footer,
  actions,
}: MenuProps<Name>) {
  const { title } = useRoute(name);

  const { menuItems, setMenuItems } = useMenuItems();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const menuTranslations = useTranslations('menu');
  const buttonTranslations = useTranslations('button');

  const location = useLocation();

  const { currentToast, removeToast } = useToast();

  const theme = useTheme();

  const { logout } = useCognitoActions();
  const { user } = useCognitoUser();

  const { mode } = useDarkMode();
  const { hasMobileView } = useHasMobileView();

  interface CheckSelectedProps {
    path: string;
    pathname: string;
  }

  function checkSelected({ path, pathname }: CheckSelectedProps) {
    const currentPath = pathname.split('/').filter((item) => !!item)[0] ?? '';

    return (path.split('/').filter((item) => !!item)[0] ?? '') === currentPath;
  }

  React.useEffect(() => {
    const categories: Record<string, MenuItem[]> = {};

    Object.keys(customRoutes).forEach((route) => {
      const { title, path, svgName, category, showInMenu } =
        customRoutes[route as keyof CustomRoutes] ?? {};

      if (!showInMenu) {
        return;
      }

      if (!categories[category]) {
        categories[category] = [];
      }

      categories[category].push({
        type: 'item',
        name: route as keyof CustomRoutes,
        title,
        path,
        selected: checkSelected({ path, pathname: location.pathname }),
        svgName,
      });
    });

    const newMenuItems: MenuItem[] = [];

    Object.keys(categories).forEach((category) => {
      newMenuItems.push({ type: 'category', category });
      newMenuItems.push(
        ...categories[category].sort((a, b) => (a.name ?? '')?.localeCompare(b.name ?? '')),
      );
    });

    setMenuItems(newMenuItems);
  }, [location, customRoutes]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = React.useMemo(
    () => (
      <Box
        sx={{
          backgroundColor: secondaryMain,
          height: '1000%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Toolbar />
          <List>
            {menuItems.map(({ type, title, name, svgName, selected, category }, index) => {
              if (type === 'item') {
                return (
                  <ListItem key={title} disablePadding>
                    <LocalMenuItem
                      title={title}
                      selected={selected}
                      name={name}
                      svgName={svgName}
                    />
                  </ListItem>
                );
              }

              return (
                <Box sx={{ paddingLeft: 2 }} key={index}>
                  <Text.Bold color="common.white">{category}</Text.Bold>
                </Box>
              );
            })}
          </List>
        </Box>
        <Box>
          <List>
            <LocalMenuItem
              name={undefined}
              title={menuTranslations.logout}
              svgName="logout"
              selected={false}
              onClick={() => {
                logout();
              }}
            />
          </List>
        </Box>
      </Box>
    ),
    [menuItems, mode],
  );

  interface GetToastMessageProps {
    title: string | undefined;
    error: Error | unknown | undefined;
  }

  function getToastMessage({ title, error }: GetToastMessageProps): string {
    if (title) {
      return title;
    }

    if (error instanceof AxiosError) {
      return error?.response?.data?.message;
    }

    if (error instanceof Error) {
      return error?.message;
    }

    if (error) {
      return '!(error instanceof AxiosError || error instanceof Error)';
    }

    return 'Oops, something went wrong!';
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'background.paper',
        }}
      >
        <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <SVG.Menu color={mode === 'dark' ? 'primary.main' : 'common.black'} size="medium" />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flex: 1 }} />
            <Box
              sx={{
                height: '100%',
                width: 200,
                paddingTop: 1,
                paddingRight: 2,
              }}
            >
              <SVG.Logo color={mode === 'dark' ? 'common.white' : 'common.black'} size="flex" />
            </Box>
            <Box sx={{ paddingLeft: 2 }}>
              <Text>{user?.attributes?.email ?? ''}</Text>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: secondaryMain,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
          PaperProps={{
            sx: {
              backgroundColor: secondaryMain,
              outline: 'none',
              height: '100vh',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: hasMobileView ? 2 : 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          height: loading || fullScreen ? `calc(100vh - ${120}px)` : undefined,
          paddingBottom: !!footer && footer.length > 0 ? 10 : undefined,
          backgroundColor: 'background.default',
        }}
      >
        <>
          <Toolbar />
          {!!loading && (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={100} />
            </Box>
          )}

          {!!currentToast && (
            <Snackbar
              sx={{ padding: hasMobileView ? 2 : undefined }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={!!currentToast}
              autoHideDuration={currentToast?.duraction ?? 5000}
              onClose={() => {
                removeToast(currentToast?.hash ?? '');
              }}
            >
              <Alert
                onClose={() => {
                  removeToast(currentToast?.hash ?? '');
                }}
                severity={currentToast?.severity ?? 'success'}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {getToastMessage({ title: currentToast?.title, error: currentToast?.error })}
              </Alert>
            </Snackbar>
          )}
          {!!footer && footer.length > 0 && !mobileOpen && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={true}
              onClose={() => {
                removeToast(currentToast?.hash ?? '');
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <>
                  {footer.map((props, index) => (
                    <Box key={props.label + index} sx={{ marginLeft: index !== 0 ? 2 : 0 }}>
                      <Button {...props} />
                    </Box>
                  ))}
                </>
              </Box>
            </Snackbar>
          )}
          {!loading && (
            <Box
              sx={{
                height: '100%',
                maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
              }}
            >
              {actions && (
                <Box sx={{ position: 'absolute', right: theme.spacing(3) }}>
                  <Button size="medium" label={buttonTranslations.actions} actions={actions} />
                </Box>
              )}
              <Box sx={{ paddingBottom: 2 }}>
                <Text.LargeBold noWrap>{overwriteTitle ?? title}</Text.LargeBold>
              </Box>
              {children}
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
}

export { Menu, type MenuItem };
