import { cloneElement } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface GetVerticalStackSxProps {
  theme: Theme;
  verticalPosition: VerticalPosition['verticalPosition'];
}

function getVerticalStackSx({ theme, verticalPosition }: GetVerticalStackSxProps): SxProps<Theme> {
  return {
    border: 1,
    borderColor: 'divider',
    borderTopLeftRadius:
      verticalPosition === 'top' || verticalPosition === undefined
        ? theme.shape.borderRadius * 1
        : 0,
    borderTopRightRadius:
      verticalPosition === 'top' || verticalPosition === undefined
        ? theme.shape.borderRadius * 1
        : 0,
    borderBottomLeftRadius:
      verticalPosition === 'bottom' || verticalPosition === undefined
        ? theme.shape.borderRadius * 1
        : 0,
    borderBottomRightRadius:
      verticalPosition === 'bottom' || verticalPosition === undefined
        ? theme.shape.borderRadius * 1
        : 0,
    borderBottom: verticalPosition === 'top' || verticalPosition === 'middle' ? 0 : undefined,
  };
}

interface VerticalPosition {
  verticalPosition?: 'top' | 'middle' | 'bottom' | undefined;
}

interface VerticalStackProps {
  children: JSX.Element | JSX.Element[];
}

function VerticalStack({ children }: VerticalStackProps): JSX.Element {
  if (Array.isArray(children)) {
    return (
      <Box>
        {children.map((child, index) =>
          cloneElement(child, {
            key: index,
            verticalPosition:
              index === 0 && children.length > 1
                ? 'top'
                : index === children.length - 1 && children.length > 1
                ? 'bottom'
                : 'middle',
          }),
        )}
      </Box>
    );
  }

  return <Box>{children}</Box>;
}

export { getVerticalStackSx, type VerticalPosition, VerticalStack };
