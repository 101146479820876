// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-imports */
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

import { useTranslations } from 'hooks/useTranslations';

import { SVG } from './SVG';
import { Text } from './Text';
import { getVerticalStackSx } from './VerticalStack';

interface Row {
  id: string;
  [key: string]: string;
}

interface CollapsibleRowProps {
  row: Row;
  detail?: Record<string, string>;
}

function CollapsibleRow({ row, detail }: CollapsibleRowProps) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const hasDetails: boolean =
    (detail && Object.keys(detail ?? {}).filter((key) => key !== 'id').length > 0) ?? false;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '50px' }}>
          {hasDetails ? (
            <IconButton sx={{ width: 20, height: 20 }} onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : undefined}
        </TableCell>
        {Object.values(row)
          .filter((value) => value !== row['id'])
          .map((value, key) => (
            <TableCell
              sx={{ ':hover': { cursor: hasDetails ? 'pointer' : undefined }, padding: 2 }}
              onClick={() => (hasDetails ? setOpen(!open) : undefined)}
              key={key}
            >
              {value}
            </TableCell>
          ))}
      </TableRow>
      {detail && Object.keys(detail ?? {}).filter((key) => key !== 'id').length > 0 ? (
        <TableRow>
          <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <TableContainer
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: 5,
                    borderColor: theme.palette.grey[100],
                    paddingBottom: 2,
                    backgroundColor: theme.palette.background.default,
                    ...getVerticalStackSx({ theme, verticalPosition: undefined }),
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        {Object.keys(detail)
                          .filter((name) => name !== 'id')
                          .map((value, key) => (
                            <TableCell key={key}>{value}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {Object.keys(detail)
                          .filter((name) => name !== 'id')
                          .map((value, key) => (
                            <TableCell key={key}>{detail[value]}</TableCell>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : undefined}
    </>
  );
}

interface CollapsibleTableProps {
  columns: string[];
  rows: Row[];
  details?: Record<string, string>[];
  loading: boolean;
}

export function CollapsibleTable({ rows, details, columns, loading }: CollapsibleTableProps) {
  const theme = useTheme();
  const translations = useTranslations('general');

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 50,
          border: 5,
          borderColor: theme.palette.grey[100],
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: 'background.paper',
          ...getVerticalStackSx({ theme, verticalPosition: undefined }),
        }}
      >
        <Box sx={{ display: 'flex', width: 150, justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
            }}
          >
            <CircularProgress />
          </Box>
          <SVG.Search size="large" color="background.paper" />
        </Box>
        <Text.ExtraLarge>{translations.loading}</Text.ExtraLarge>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 50,
        border: 5,
        borderColor: theme.palette.grey[100],
        paddingBottom: 2,
        backgroundColor: 'background.paper',
        ...getVerticalStackSx({ theme, verticalPosition: undefined }),
      }}
    >
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((header, key) => (
                <TableCell key={key}>
                  <Text.Bold>{header}</Text.Bold>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => (
              <CollapsibleRow
                key={key}
                row={row}
                detail={details?.find((detail) => detail.id === row.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
