// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable i18next/no-literal-string */
import { Box, useTheme } from '@mui/material';

import { Page, Text } from 'components';

function PrivacyPage() {
  const theme = useTheme();

  const temp =
    'FIGO bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: (Categorie) persoonsgegevens > Bewaartermijn > Reden Personalia > Bewaartermijn > Reden Adres > Bewaartermijn > Reden Enzovoort > Bewaartermijn > Reden';
  const temp2 =
    "FIGO neemt [wel / niet] op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van FIGO) tussen zit. FIGO gebruikt de volgende computerprogramma's of -systemen: aanvullen met naam van het systeem, waarom het gebruikt wordt, onderliggende logica, belang en verwachte gevolgen voor betrokkene";

  return (
    <Page name="privacy">
      <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
        <Text.ExtraLargeBold>Privacy Policy</Text.ExtraLargeBold>
        <Box sx={{ height: theme.spacing(2) }} />
        <Text>
          FIGO, gevestigd aan Kerkstraat 188 1017GT Amsterdam, is verantwoordelijk voor de
          verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
        </Text>
        <Box sx={{ height: theme.spacing(2) }} />

        <Text>www.figo.app</Text>
        <Text>Kerkstraat 188 1017GT Amsterdam</Text>
        <Text>+31650946085</Text>
        <Text>
          Joost Pompe is de Functionaris Gegevensbescherming van FIGO Hij/zij is te bereiken via
          j@figo.app
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Persoonsgegevens die wij verwerken</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          FIGO verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u
          deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die
          wij verwerken:
        </Text>

        <Box sx={{ height: theme.spacing(1) }} />
        <Text>- Voornaam</Text>
        <Text>- Achternaam</Text>
        <Text>- Emailadres</Text>
        <Text>- Wachtwoord</Text>
        <Text>- Geboortedatum</Text>
        <Text>- Geslacht</Text>
        <Text>- Straatnaam</Text>
        <Text>- Huisnummer</Text>
        <Text>- Postcode</Text>
        <Text> -Woonplaats</Text>
        <Text>- Telefoonnummer (met verificatie)</Text>
        <Text>- Rijbewijs voor/achterkant (foto of check)</Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>
          Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
        </Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over
          websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of
          voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders
          dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te
          voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
          Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben
          verzameld over een minderjarige, neem dan contact met ons op via j@figo.app, dan
          verwijderen wij deze informatie.
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>
          Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
        </Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>FIGO verwerkt uw persoonsgegevens voor de volgende doelen:</Text>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>- Het afhandelen van uw betaling</Text>
        <Text>
          - U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen
          voeren
        </Text>
        <Text>- U te informeren over wijzigingen van onze diensten en producten</Text>
        <Text>- U de mogelijkheid te bieden een account aan te maken</Text>
        <Text>- Om goederen en diensten bij u af te leveren</Text>
        <Text>
          - FIGO verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals
          gegevens die wij nodig hebben voor onze belastingaangifte.
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Geautomatiseerde besluitvorming</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>{temp2}</Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Hoe lang we persoonsgegevens bewaren</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>{temp}</Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Delen van persoonsgegevens met derden</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          FIGO verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig
          is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke
          verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een
          bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en
          vertrouwelijkheid van uw gegevens. FIGO blijft verantwoordelijk voor deze verwerkingen.
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Cookies, of vergelijkbare technieken, die wij gebruiken</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          FIGO gebruikt alleen technische en functionele cookies. En analytische cookies die geen
          inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste
          bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies
          die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw
          gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden
          bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren.
          U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen
          cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
          instellingen van uw browser verwijderen.
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Gegevens inzien, aanpassen of verwijderen</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen.
          Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te
          trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door FIGO en heeft
          u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt
          indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of
          een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage,
          correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot
          intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen
          naar j@figo.app. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan,
          vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in
          deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het
          paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw
          privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. FIGO wil u
          er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de
          nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:
          https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Hoe wij persoonsgegevens beveiligen</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          FIGO neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om
          misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde
          wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of
          er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via
          j@figo.app
        </Text>

        <Box sx={{ height: theme.spacing(2) }} />
        <Text.LargeBold>Wijzigingen in dit document</Text.LargeBold>
        <Box sx={{ height: theme.spacing(1) }} />
        <Text>
          Ons privacy statement kan van tijd tot tijd wijzigen. Als er nieuwe gegevensverwerkingen
          zijn, dan passen wij het privacy statement daarop aan. En als deze wijzigingen ook voor
          jou van belang zijn, dan attenderen wij je daarop of maken wij de wijzigingen op een
          opvallende manier aan je kenbaar. De meest actuele versie van ons privacy statement kun je
          steeds terugvinden op https://figo.app
        </Text>
      </Box>
    </Page>
  );
}

export { PrivacyPage };
