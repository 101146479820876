// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';

import { Driver } from './useDrivers';

function useSuperAdmins() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Response {
    result: Null<Driver>[];
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: '/admin/super-admin',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['super-admins', {}], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

function useImpersonation() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface OrganisationProps {
    id: string;
  }

  async function Impersonate({ id }: OrganisationProps) {
    const { data } = await API.post<void, null>({
      path: `/admin/impersonate/${id}`,
      token,
      version: 1,
      body: null,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, OrganisationProps>((data: OrganisationProps) =>
    Impersonate(data),
  );
}

export { useImpersonation, useSuperAdmins };
