import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { Page, Popup, Text, TextField } from 'components';
import { useCognitoActions, useCognitoUser } from 'core/cognito';
import { authenticationErrorNoAccess } from 'core/cognito/services';
import { useToast } from 'core/toast/hooks';
import { useNavigate } from 'hooks/useNavigate';
import { replaceText, useTranslations } from 'hooks/useTranslations';

const emailInputRef = React.createRef<HTMLInputElement>();
const passwordInputRef = React.createRef<HTMLInputElement>();

function LoginPage() {
  const actions = useCognitoActions();
  const user = useCognitoUser();
  const { navigate } = useNavigate();

  const translationsGeneral = useTranslations('general');
  const translations = useTranslations('loginPage');

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [code, setCode] = React.useState('');

  const timerRef = React.useRef<NodeJS.Timer>();
  const [count, setCount] = React.useState(60);

  const { pathname } = useLocation();

  const { addToast } = useToast();

  React.useEffect(() => {
    if (user.authenticated === true && !user.loading) {
      setEmail('');
      setPassword('');

      if (pathname === '/login') {
        navigate({ name: 'statistics', id: undefined, searchParams: { tab: 1 }, metaKey: false });
      }
    }
  }, [user]);

  function onInterval() {
    setCount((prevState) => {
      if (prevState === 0) {
        timerRef.current && clearInterval(timerRef.current);
        return prevState;
      }

      return prevState - 1;
    });
  }

  React.useEffect(() => {
    if (count === 60 && user.action?.type === 'SMS_MFA') {
      timerRef.current && clearInterval(timerRef.current);
      timerRef.current = setInterval(onInterval, 1000);
    }
  }, [count, user.action?.type]);

  const hasIncorrectEmailOrPassword =
    user.error instanceof Error ? user.error.name === 'NotAuthorizedException' : false;
  const hasIncorrectCode =
    user.error instanceof Error ? user.error.name === 'CodeMismatchException' : false;
  const hasNoAccess =
    user.error instanceof Error ? user.error.message === authenticationErrorNoAccess : false;

  React.useEffect(() => {
    if (!(user.error instanceof Error)) {
      return;
    }

    if (
      user.error.name === 'NotAuthorizedException' ||
      user.error.name === 'CodeMismatchException' ||
      user.error.message === authenticationErrorNoAccess
    ) {
      return;
    }

    addToast({ title: translationsGeneral.oops, severity: 'error' });
  }, [user.error]);

  return (
    <Page name="login">
      <>
        {user.action?.type === undefined && (
          <Popup
            title="Login"
            right={{
              variant: 'contained',
              onClick: () => {
                actions.login({ email, password });
              },
              label: translations.logIn,
              loading: user.loading,
              disabled: !email || !password,
            }}
          >
            <TextField
              autoFocus={true}
              inputRef={emailInputRef}
              label={translations.email}
              type="email"
              value={email}
              onChange={setEmail}
              onKeyEnterDown={() => {
                if (passwordInputRef.current) {
                  passwordInputRef.current.focus();
                }
              }}
              variant="standard"
              fullWidth={true}
              helperText={
                hasIncorrectEmailOrPassword ? translations.incorrectEmailOrPassword : undefined
              }
              error={hasIncorrectEmailOrPassword}
            />
            <TextField
              inputRef={passwordInputRef}
              label={translations.password}
              type="password"
              value={password}
              onChange={setPassword}
              onKeyEnterDown={() => {
                if (emailInputRef.current) {
                  if (!password) {
                    emailInputRef.current.focus();
                  } else if (!!email && !!password) {
                    actions.login({ email, password });
                  }
                }
              }}
              variant="standard"
              fullWidth={true}
              helperText={
                hasIncorrectEmailOrPassword
                  ? translations.incorrectEmailOrPassword
                  : hasNoAccess
                  ? authenticationErrorNoAccess
                  : undefined
              }
              error={hasIncorrectEmailOrPassword || hasNoAccess}
            />
          </Popup>
        )}
        {user.action?.type === 'SMS_MFA' && (
          <Popup
            title={translations.MFA}
            left={{
              variant: 'contained',
              onClick: () => {
                actions.login({ email, password });
                setCount(60);
              },
              label: translations.requestNewCode,
              disabled: count > 0,
            }}
            right={{
              variant: 'contained',
              onClick: () => {
                actions.verifySmsCode({ code });
              },
              label: translations.confirm,
              disabled: !code,
              loading: !!code && user.loading,
            }}
          >
            <>
              <Text>
                {replaceText({
                  text: translations.verificationCodeMessage,
                  replaceValues: [
                    user.action.data.user.challengeParam.CODE_DELIVERY_DESTINATION ?? '',
                  ],
                })}
              </Text>
              <Box sx={{ height: 2 }} />
              <Text>{translations.requestNewCodeProblems}</Text>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <TextField
                  autoFocus={true}
                  type="text"
                  label={translations.verificationCode}
                  value={code}
                  onChange={setCode}
                  onKeyEnterDown={() => {
                    if (code) {
                      actions.verifySmsCode({ code });
                    }
                  }}
                  variant="standard"
                  helperText={
                    hasIncorrectCode
                      ? translations.incorrectCode
                      : hasNoAccess
                      ? authenticationErrorNoAccess
                      : undefined
                  }
                  error={hasIncorrectCode || hasNoAccess}
                />
                <Box sx={{ paddingLeft: 2 }}>{count.toString()}</Box>
              </Box>
            </>
          </Popup>
        )}
      </>
    </Page>
  );
}

export { LoginPage };
