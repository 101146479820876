import React from 'react';
import { Box } from '@mui/material';

import { Button, CollapsibleTable, DatePicker } from 'components';
import { capitalizeFirstLetter, downloadBlob, formatPrice } from 'core/shared';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

export function OrganisationStatistics() {
  const translations = useTranslations('statisticsPage');

  const [month, setMonth] = React.useState(
    `${`${new Date().getMonth() + 1}`.padStart(2, '0')}-${new Date().getFullYear()}`,
  );

  const { data: organisationsStatisticsData, isFetching: loadingOrganisationsStatistics } =
    UseQuery.organisationStatistics({ month });

  const {
    mutateAsync: mutateAsyncExportOrganisationStatistics,
    isLoading: isLoadingExportOrganisationStatistics,
  } = UseMutation.exportOrganisationStatistics();

  interface ConvertedDetails {
    id: string;
    [key: string]: string;
  }

  function convertDetails(): ConvertedDetails[] {
    const results = Object.keys(organisationsStatisticsData?.result ?? {}).map((organisation) => {
      const company = organisationsStatisticsData?.result[organisation];
      const result: ConvertedDetails = {
        id: `id+${organisation}`,
      };

      if (Object.keys(company?.providers ?? {}).length > 0) {
        for (const provider in company?.providers) {
          if (Object.hasOwnProperty.call(company.providers, provider)) {
            const formattedKey = capitalizeFirstLetter(provider.toLowerCase());
            result[formattedKey] = formatPrice({
              price: company.providers[provider],
              symbol: true,
            });
          }
        }
      }

      return result;
    });

    return results;
  }

  return (
    <Box sx={{ padding: 1, width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <DatePicker
          views={['year', 'month']}
          disabledEmpty
          value={month}
          label={translations.month}
          onChange={setMonth}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginLeft: 8,
          }}
        >
          <Button
            loading={loadingOrganisationsStatistics || isLoadingExportOrganisationStatistics}
            disabled={loadingOrganisationsStatistics || isLoadingExportOrganisationStatistics}
            size="medium"
            label={translations.exportCSV}
            onClick={async () => {
              const { blob, fileName } = await mutateAsyncExportOrganisationStatistics({ month });

              downloadBlob({ blob, fileName });
            }}
          />
        </Box>
      </Box>

      <CollapsibleTable
        rows={Object.keys(organisationsStatisticsData?.result ?? {}).map((organisation) => ({
          id: `id+${organisation}`,
          organisation: capitalizeFirstLetter(organisation.toLowerCase()),
          activeUsers:
            organisationsStatisticsData?.result[organisation]?.active_users?.toString() ?? '0',
          totalUsers:
            organisationsStatisticsData?.result[organisation]?.total_users?.toString() ?? '0',
          revenue: formatPrice({
            price: organisationsStatisticsData?.result[organisation].revenue,
            symbol: true,
          }),
          totalSpend: formatPrice({
            price: organisationsStatisticsData?.result[organisation].total_spend,
            symbol: true,
          }),
        }))}
        loading={!!loadingOrganisationsStatistics}
        columns={[
          translations.organisation,
          translations.activeUsers,
          translations.totalUsers,
          translations.monthlyRecurringRevenue,
          translations.totalSpend,
        ]}
        details={convertDetails()}
      />
    </Box>
  );
}
