import { Box, Link as LinkMUI } from '@mui/material';

import { getSVG, SVGName, SVGProps } from './SVG';

type LinkUnderlineProps = 'none' | 'hover' | 'always';

interface LinkSVGProps {
  svgName: SVGName;
  color: SVGProps['color'];
  size: SVGProps['size'];
}

interface LinkProps {
  onClick: () => void;
  children: JSX.Element;
  svg: LinkSVGProps;
  underline?: LinkUnderlineProps;
}

function Link({ onClick, children, svg, underline = 'none' }: LinkProps) {
  const SVG = getSVG(svg.svgName as SVGName);

  return (
    <Box sx={{ width: 'fit-content' }}>
      <LinkMUI sx={{ cursor: 'pointer' }} underline={underline} onClick={onClick}>
        <Box sx={{ display: 'flex' }}>
          {children}
          <Box sx={{ marginLeft: 1 }}>
            <SVG color={svg.color} size={svg.size} />
          </Box>
        </Box>
      </LinkMUI>
    </Box>
  );
}

export { Link };
