import { Box, useTheme } from '@mui/material';

import { useHasMobileView } from 'hooks/useHasMobileView';

import { AdyenProps, AdyenProvider } from 'pages/components/AdyenProvider';
import { NSProps, NSProvider } from 'pages/components/NSProvider';

interface OrganisationProvidersProps {
  organisationId: string;
  providersData: { [key: string]: object };
}

function OrganisationProviders({ organisationId, providersData }: OrganisationProvidersProps) {
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  return (
    <>
      <Box
        sx={{
          padding: 2,
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
        }}
      >
        <NSProvider nsData={providersData['ns'] as NSProps} organisationId={organisationId} />
        <AdyenProvider
          organisationId={organisationId}
          adyenData={providersData['adyen'] as AdyenProps}
        />
      </Box>
    </>
  );
}

export { OrganisationProviders };
