// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';
import validator from 'validator';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface UseCommunitiesProps {
  page: number;
  query?: string;
  limit?: number;
}

function useCommunities({ page, query, limit }: UseCommunitiesProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  const { hasMobileView } = useHasMobileView();

  interface Community {
    id: string;
    name: string;
    description: string;
    size: number;
    is_editable: boolean;
    created_on: string;
    updated_on: string;
  }

  interface Response {
    result: Null<Community>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    q: string;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/communities',
      token,
      params: { page, q: query ?? '', limit: hasMobileView && limit === undefined ? 15 : limit },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['communities', { page, query, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && page >= 1,
      retry: 0,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

interface UseCommunityProps {
  id: string;
}

function useCommunity({ id }: UseCommunityProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Community {
    id: string;
    name: string;
    created_on: string;
    is_editable: boolean;
    size: number;
    updated_on: string;
    description: string;
    fleets: { id: string; name: string }[];
    users: { id: string; first_name: string; last_name: string; email: string }[];
  }

  interface Response {
    result: Null<Community>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/communities/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['community', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token && validator.isUUID(id),
    retry: 0,
    select: ({ data }) => data,
    keepPreviousData: true,
  });
}

function useEditCommunity() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface EditCommunityProps {
    id: string;
    name: string;
    description: string;
    fleets: string[];
    users: string[];
  }

  interface Body {
    name: string;
    description: string;
    fleets: string[];
    users: string[];
  }

  async function editCommunity({ id, name, description, fleets, users }: EditCommunityProps) {
    const { data } = await API.put<void, Body>({
      path: `/communities/${id}`,
      token,
      version: 1,
      body: {
        name,
        description,
        fleets,
        users,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, EditCommunityProps>(
    (data: EditCommunityProps) => editCommunity(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['communities']);
        await queryClient.invalidateQueries(['community', { id }]);
      },
    },
  );
}

function useDeleteCommunity() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface DeleteCommunityProps {
    id: string;
  }

  async function deleteCommunity({ id }: DeleteCommunityProps) {
    const { data } = await API.delete({
      path: `/communities/${id}`,
      token,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, DeleteCommunityProps>(
    (data: DeleteCommunityProps) => deleteCommunity(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['communities']);
        await queryClient.invalidateQueries(['community', { id }]);
      },
    },
  );
}

function useAddCommunity() {
  const { token } = useCognitoUser();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  interface AddCommunityProps {
    name: string;
    description: string;
    fleets: string[];
    users: string[];
  }

  interface Body {
    name: string;
    description: string;
    fleets: string[];
    users: string[];
  }

  async function addCommunity({ name, description, fleets, users }: AddCommunityProps) {
    const { data } = await API.post<void, Body>({
      path: `/communities`,
      token,
      version: 1,
      body: {
        name,
        description,
        fleets,
        users,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, AddCommunityProps>(
    (data: AddCommunityProps) => addCommunity(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['communities']);
      },
    },
  );
}

export { useAddCommunity, useCommunities, useCommunity, useDeleteCommunity, useEditCommunity };
