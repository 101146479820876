import React from 'react';

import { Languages } from 'config/translations';

const initialLanguage: Languages = 'english';

interface TranslationsContextProps {
  language: Languages;
  setLanguage: React.Dispatch<React.SetStateAction<Languages>>;
}

const TranslationsContext = React.createContext<TranslationsContextProps>({
  language: initialLanguage,
  setLanguage: () => {},
});

interface TranslationsProviderProps {
  children: JSX.Element;
}

function TranslationsProvider({ children }: TranslationsProviderProps) {
  const [language, setLanguage] = React.useState(initialLanguage);

  return (
    <TranslationsContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationsContext.Provider>
  );
}

export { TranslationsContext, TranslationsProvider };
