import { Box, Unstable_Grid2 as Grid } from '@mui/material';

import { Container, StackedBarChart, Table } from 'components';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { customColors } from 'config/customColors';
import { UseQuery } from 'useQuery';

export function BusinessStatistics() {
  const { data: reservationStatisticsData } = UseQuery.reservationStatistics();
  const { data: revenueStatisticsData } = UseQuery.revenueStatistics();
  const translations = useTranslations('statisticsPage');

  const { hasMobileView } = useHasMobileView();

  return (
    <Grid container spacing={2} sx={{ paddingTop: 1 }}>
      <Container
        title={translations.spendByProvider}
        width="flex"
        loading={reservationStatisticsData === undefined}
      >
        <>
          <StackedBarChart
            labels={[
              ...(reservationStatisticsData?.spend.by_month ?? []).map(
                (item) => Object.keys(item)[0],
              ),
              translations.forecasted,
            ]}
            data={[
              {
                label: translations.vecore,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['VECORE'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.check,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['CHECK'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.felyx,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['FELYX'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.mywheels,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['MYWHEELS'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.greenwheels,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['GREENWHEELS'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.adyen,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['ADYEN'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.ns,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['NS'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.mrr,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((item) => {
                    const key = Object.keys(item)[0];

                    return item[key] ? (item[key]['REVENUE'] ?? 0) / 100 : 0;
                  }),
                  0,
                ],
              },
              {
                label: translations.forecasted,
                values: [
                  ...(reservationStatisticsData?.spend.by_month ?? []).map((_) => 0),
                  Math.max((reservationStatisticsData?.spend.forecasted ?? 0) / 100, 0),
                ],
              },
            ]}
            colors={[
              customColors.vecore,
              customColors.check,
              customColors.felyx,
              customColors.mywheels,
              customColors.greenwheels,
              customColors.adyen,
              customColors.ns,
              customColors.mrr,
              'info.main',
            ]}
          />
          <Box sx={{ width: '100%' }}>
            {hasMobileView ? (
              <Table
                columns={{
                  name: {
                    name: translations.name,
                    type: 'text',
                    flex: 1,
                  },
                  value: {
                    name: translations.value,
                    type: 'text',
                    width: 100,
                  },
                }}
                rows={[
                  {
                    id: 'annual',
                    name: translations.annualGrowthRate,
                    value: ((reservationStatisticsData?.spend?.compound_growth_rate ?? 0) / 100)
                      .toFixed(2)
                      .toString(),
                  },
                  {
                    id: 'monthly',
                    name: translations.monthlyGrowthRate,
                    value: ((reservationStatisticsData?.spend?.monthly_growth ?? 0) / 100)
                      .toFixed(2)
                      .toString(),
                  },
                  {
                    id: 'total',
                    name: translations.total,
                    value: ((reservationStatisticsData?.spend?.total ?? 0) / 100)
                      .toFixed(2)
                      .toString(),
                  },
                ]}
                loading={!reservationStatisticsData?.spend}
                useMobileView={false}
              />
            ) : (
              <Table
                columns={{
                  annualGrowthRate: {
                    name: translations.annualGrowthRate,
                    type: 'text',
                    flex: 1,
                  },
                  monthlyGrowthRate: {
                    name: translations.monthlyGrowthRate,
                    type: 'text',
                    flex: 1,
                  },
                  total: { name: translations.total, type: 'text', width: 100 },
                }}
                rows={[
                  {
                    id: 'cancelled',
                    annualGrowthRate: (
                      (reservationStatisticsData?.spend?.compound_growth_rate ?? 0) / 100
                    )
                      .toFixed(2)
                      .toString(),
                    monthlyGrowthRate: (
                      (reservationStatisticsData?.spend?.monthly_growth ?? 0) / 100
                    )
                      .toFixed(2)
                      .toString(),
                    total: ((reservationStatisticsData?.spend?.total ?? 0) / 100)
                      .toFixed(2)
                      .toString(),
                  },
                ]}
                loading={!reservationStatisticsData?.spend}
                useMobileView={false}
              />
            )}
          </Box>
        </>
      </Container>
      <Container
        title={translations.monthlyRecurringRevenue}
        width="half"
        loading={revenueStatisticsData === undefined}
      >
        <Box sx={{ width: '100%' }}>
          {hasMobileView ? (
            <Table
              columns={{
                name: {
                  name: translations.name,
                  type: 'text',
                  flex: 1,
                },
                value: {
                  name: translations.value,
                  type: 'text',
                  width: 100,
                },
              }}
              rows={[
                {
                  id: 'net',
                  name: translations.netRevenue,
                  value: ((revenueStatisticsData?.result.net_price ?? 0) / 100)
                    .toFixed(2)
                    .toString(),
                },
                {
                  id: 'gross',
                  name: translations.grossRevenue,
                  value: ((revenueStatisticsData?.result.gross_price ?? 0) / 100)
                    .toFixed(2)
                    .toString(),
                },
              ]}
              loading={!reservationStatisticsData?.spend}
              useMobileView={false}
            />
          ) : (
            <Table
              columns={{
                netRevenue: {
                  name: translations.netRevenue,
                  type: 'text',
                  flex: 1,
                },
                grossRevenue: {
                  name: translations.grossRevenue,
                  type: 'text',
                  flex: 1,
                },
              }}
              rows={[
                {
                  id: '1',
                  netRevenue: ((revenueStatisticsData?.result.net_price ?? 0) / 100)
                    .toFixed(2)
                    .toString(),
                  grossRevenue: ((revenueStatisticsData?.result.gross_price ?? 0) / 100)
                    .toFixed(2)
                    .toString(),
                },
              ]}
              loading={!reservationStatisticsData?.spend}
              useMobileView={false}
            />
          )}
        </Box>
      </Container>
    </Grid>
  );
}
