// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface PublicTransportation {
  checkin: {
    location: string;
    time: Date;
  };
  checkout: {
    location: string;
    time: Date;
  };
  provider: string;
  price: number;
  type: string;
}

interface Transaction {
  id: string;
  start: Date;
  end?: Date;
  product_description: string;
  category: string;
  description: string;
  amount: string;
  currency: string;
  price: number;
  price_net: number;
  vat_amount?: number;
  operator?: string;
  entity: string;
  entity_email: string;
  note: string;
  status: 'PENDING' | 'COMPLETED' | 'SKIPPED' | 'PUBLIC_TRANSPORT';
  skipped_invoice: boolean;
  public_transportation: undefined | Record<string, PublicTransportation>;
  public_meta: {
    description: string | null;
  };
  organisation_name: string;
}

interface UseTransactionProps {
  id: string;
}

function useTransaction({ id }: UseTransactionProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<Transaction>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/admin/transactions/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['transaction', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 0,
    select: ({ data }) => data,
  });
}

interface UseTransactionsProps {
  page: number;
  query?: string;
  limit?: number;
}

function useTransactions({ page, query, limit }: UseTransactionsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Transaction>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    q: string;
    limit: number | undefined;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/admin/transactions',
      token,
      params: { page, q: query ?? '', limit: hasMobileView && limit === undefined ? 15 : limit },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['transactions', { page, query, hasMobileView, limit }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && page >= 1,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useEditTransaction() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface UseEditTransactionProps {
    id: string;
    metaDescription: string;
  }

  async function editTransaction({ id, metaDescription }: UseEditTransactionProps) {
    interface Body {
      public_meta: {
        description: string;
      };
    }

    const { data } = await API.patch<void, Body>({
      path: `/admin/transactions/${id}`,
      token,
      version: 1,
      body: {
        public_meta: {
          description: metaDescription,
        },
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UseEditTransactionProps>(
    (data: UseEditTransactionProps) => editTransaction(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['transaction', { id }]);
        await queryClient.invalidateQueries('transactions');
      },
    },
  );
}

function useResetTransaction() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface UseResetTransactionProps {
    id: string;
  }

  async function editTransaction({ id }: UseResetTransactionProps) {
    const { data } = await API.put<void, undefined>({
      path: `/admin/transactions/${id}/reset`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UseResetTransactionProps>(
    (data: UseResetTransactionProps) => editTransaction(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['transaction', { id }]);
        await queryClient.invalidateQueries('transactions');
      },
    },
  );
}

function useDownloadInvoice() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface UseDownloadInvoiceProps {
    id: string;
  }

  async function downloadInvoice({ id }: UseDownloadInvoiceProps) {
    const { data } = await API.get<Blob, undefined, true>({
      path: `/admin/transactions/${id}/download-invoice`,
      token,
      version: 1,
      params: undefined,
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown, UseDownloadInvoiceProps>((data: UseDownloadInvoiceProps) =>
    downloadInvoice(data),
  );
}

type PublicTransportationExport = Null<PublicTransportation>;
type TransactionExport = Null<Transaction>;

export {
  type PublicTransportationExport as PublicTransportation,
  type TransactionExport as Transaction,
  useDownloadInvoice,
  useEditTransaction,
  useResetTransaction,
  useTransaction,
  useTransactions,
};
