import { Page } from 'components';

function NotFoundPage() {
  return (
    <Page name="notFound">
      <></>
    </Page>
  );
}

export { NotFoundPage };
